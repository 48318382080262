export function extractDomain(url: string | undefined) {
	if (!url) {
		return '';
	}
	// remove protocol
	return ((url.indexOf('//') > -1)
		? url.split('/')[2]
		: url.split('/')[0])
		// remove port
		.split(':')[0]
		// remove query
		.split('?')[0]
		// remove tags
		.split('#')[0];
}

export function parseQuery(queryString: string): Record<string, unknown> {
	const query = {};
	const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
	for (let i = 0; i < pairs.length; i += 1) {
		const pair = pairs[i].split('=');
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
	}
	return query;
}

export default { extractHostname: extractDomain, parseQuery };
