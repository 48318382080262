// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateEmailFullName } from '../../../redux/userSettings/actions';
import AreaCodes from 'areacodes';

// Material ui
import { Box } from '@material-ui/core';
import SecondaryButton from '../_actions/SecondaryButton';
import { makeStyles, useTheme } from '@material-ui/core';

// Components
import SelectInput from '../_inputs/SelectInput';
import { postNotification } from '../../../redux/notifications/actions';

const useStyles = makeStyles(theme => ({
	text: {
		marginBottom: '20px',
	},
}));

function ChangeTwilioNumber(props) {
	const theme = useTheme();
	const classes = useStyles(theme);
	const dispatch = useDispatch();
	const twilioNumbers = useSelector(state => state.metaData.twilioNumbers);
	const data = useSelector(state => state);
	const userTwilioNumber = data?.auth?.user?.twilioNumberId;

	const [parsedTwilioNumbers, setParsedTwilioNumbers] = useState([]);
	const [myTwilioNumber, setMyTwilioNumber] = useState('');

	const formik = useFormik({
		initialValues: { twilioNumbers: myTwilioNumber?.id || '' },
		enableReinitialize: true,
		onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
			try {
				dispatch(updateEmailFullName({ twilioNumberId: values.twilioNumbers }, 'Failed to change twilio number.'));
				setSubmitting(false);
				resetForm(formik);
				dispatch(postNotification('Your twilio number has been changed.', 'success'));
			} catch (error) {
				dispatch(postNotification('Twilio number change failed.', 'error'));
				setSubmitting(false);
				setStatus(
					intl.formatMessage({ id: 'AUTH.GENERAL_ERROR' })
				);
			}
		},
	});

	useEffect(() => {
		const areaCodes = new AreaCodes();
		const parseTwilioNumbersForSelectField = () => {
			const numbersArray = [];
			const userActiveTwilioNumber = twilioNumbers.filter(number => number.id === userTwilioNumber)[0];
			const shuffledArray = twilioNumbers.sort((a, b) => 0.5 - Math.random());
			if (userActiveTwilioNumber) {
				shuffledArray.unshift(userActiveTwilioNumber);
				areaCodes.get(userActiveTwilioNumber?.number, (err, data) => {
					const extractCode = userActiveTwilioNumber?.number.substring(2, 5);
					const parsedNumber = `${data?.stateCode} / ${data?.state} : (${extractCode})`;
					setMyTwilioNumber({
						label: parsedNumber,
						value: userActiveTwilioNumber?.id,
						id: userActiveTwilioNumber?.id,
						name: parsedNumber,
						key: userActiveTwilioNumber?.id,
					});
					numbersArray.push({
						label: parsedNumber,
						value: userActiveTwilioNumber?.id,
						id: userActiveTwilioNumber?.id,
						name: parsedNumber,
						key: userActiveTwilioNumber?.id,
					});
				});
			}

			if (shuffledArray.length > 0) {
				for (let i = 0; i < shuffledArray.length; i += 1) {
					areaCodes.get(shuffledArray[i]?.number, (err, data) => {
						const extractCode = shuffledArray[i]?.number.substring(2, 5);
						const parsedNumber = `${data?.stateCode} / ${data?.state} : (${extractCode})`;
						if (data?.stateCode && data?.state) {
							if (!numbersArray.some(phoneNumber => phoneNumber.label === parsedNumber)) {
								numbersArray.push({
									label: parsedNumber, value: shuffledArray[i].id, id: shuffledArray[i].id, name: parsedNumber, key: shuffledArray[i].id,
								});
							}
						}
					});
				}
			}
			setParsedTwilioNumbers(numbersArray);
		};
		parseTwilioNumbersForSelectField();
	}, [twilioNumbers, userTwilioNumber]);

	return (
		<form>
			<Box mt={3}>
				<SelectInput
					noAllOption
					fullWidth
					id="twilioNumbers"
					name="twilioNumbers"
					label="Twilio Numbers"
					className={classes.text}
					options={parsedTwilioNumbers}
					selectOnFocus
					onChange={(_, value) => { formik.setFieldValue('twilioNumbers', value); }}
					onBlur={() => formik.setTouched({ ...formik.touched, twilioNumbers: true })}
					{...formik.getFieldProps('twilioNumbers')}
				/>
			</Box>
			<div className="form-group d-flex flex-wrap flex-start">
				<SecondaryButton
					variantColor="orange"
					onClick={() => formik.handleSubmit()}
					disabled={
						formik.isSubmitting ||
						!formik.isValid ||
						formik.values.twilioNumbers === '' ||
						(formik.values.twilioNumbers === myTwilioNumber?.id)
					}
				>
					<span>Save</span>
					{formik.isSubmitting && <span className="ml-3 spinner spinner-white" />}
				</SecondaryButton>
			</div>
		</form>
	);
}

export default injectIntl(ChangeTwilioNumber);
