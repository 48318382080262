export const settingsValues = {
	CHANGE_PASSWORD: {
		key: 'changePassword component',
		title: 'Change Password',
	},
	CHANGE_FULL_NAME_AND_TIMEZONE: {
		key: 'changeFullNameAndTimezone component',
		title: 'Account Details',
	},
	CHANGE_EMAIL: {
		key: 'changeEmail component',
		title: 'Change Email',
		subheader: 'Your can change your email address here.',
	},
	CHANGE_TWILIO_NUMBER: {
		key: 'changeTwilioNumber component',
		title: 'Change Twilio Number',
		subheader: 'You can change your twilio number here.',
	},
	CUSTOM_CALLER_ID: {
		key: 'customCallerId component',
		title: 'Custom caller ID',
		subheader: 'You can set a custom caller ID here.',
	},
};

export const settingsBoxWidths = {
	WIDTHS_ARRAY: ['100%', '60%', '60%', '40%', '40%'],
};
