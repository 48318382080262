import React from 'react';

// Components
import {
	Box,
	CardContent, Grid, makeStyles, useTheme,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import SecondaryButton from '../../../../components/_actions/SecondaryButton';
import Code from '../../../../components/Code/Code';

const useStyles = makeStyles(theme => ({
	contentWrapper: {
		padding: '0px !important',
	},
}));

function CodeStep({ website, showCode }) {
	const theme = useTheme();
	const classes = useStyles(theme);

	return (
		<CardContent className={classes.contentWrapper}>
			<Grid container direction="column" justify="center" spacing={2}>
				<Grid item justify="center" container>
					{showCode
						? (
							<>
								<Box my={5}>
									Copy and paste this code into the body of the code on your website.
									Please note that you only need to inset the code
									{' '}
									<b>ONCE</b>
									{' '}
									per unique website URL.
									You
									{' '}
									<b>DO NOT</b>
									{' '}
									need to reenter the code if you are creating your 2nd or 3rd widget with the
									{' '}
									<b>SAME URL</b>
								</Box>
								<Code websiteId={website.id} />
							</>
						)
						: (
							<Box
								mt={5}
								flexDirection="column"
								alignContent="center"
								p={5}
								py={5}
								bgcolor="#fafafa"
								fontSize="24px"
								maxWidth="800px"
							>
								Success! The code you have at
								<b>{` ${website.domain} `}</b>
								was automatically refreshed with the new widget and your changes are live!
								If you need to access your code, you can do so at the websites tab.
							</Box>
						)}
				</Grid>
				<Grid item justify="center" container>
					<NavLink
						exact
						to={{
							pathname: '/widgets',
						}}
					>
						<SecondaryButton
							variantColor="orange"
						>
							Back to Widgets
						</SecondaryButton>
					</NavLink>
				</Grid>
			</Grid>
		</CardContent>
	);
}

export default CodeStep;
