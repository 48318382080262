// @ts-nocheck
import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

interface Paper {
	textAlign?: string,
	minWidth?: string,
	maxWidth?: string,
	padding?: number,
	backgroundColor?: string,
	borderRadius?: number,
	color?: string,
}

declare module '@material-ui/core/styles/createTypography' {
	interface Typography {
		customSpacing: {
			primary: string,
			secondary: string,
		},
		buttons: {
			primary: {
				lg: {
					width: string,
					height: string,
					fontSize: string,
					fontWeight: number,
					textTransform: string,
				},
				md: {
					width: string,
					height: string,
					fontSize: string,
					fontWeight: number,
					textTransform: string,
				},
				sm: {
					width: string,
					height: string,
					fontSize: string,
					fontWeight: number,
					textTransform: string,
				},
			},
			secondary: {
				md: {
					width: string,
					height: string,
					fontSize: string,
					fontWeight: number,
					textTransform: string,
				},
				sm: {
					width: string,
					height: string,
					fontSize: string,
					fontWeight: number,
					textTransform: string,
				},
			},
			tertiary: {
				width: string,
				height: string,
				fontSize: string,
				fontWeight: number,
				textTransform: string,
				borderRadius: number,
			},
			quaternary: {
				maxWidth: string,
				height: string,
				fontSize: string,
				fontWeight: number,
				textTransform: string,
				borderRadius: number,
			},
		},
	}

	interface TypographyOptions {
		customSpacing?: {
			primary?: string,
			secondary?: string,
		},
		buttons?: {
			primary?: {
				lg?: {
					width?: string,
					height?: string,
					fontSize?: string,
					fontWeight?: number,
					textTransform?: string,
				},
				md?: {
					width?: string,
					height?: string,
					fontSize?: string,
					fontWeight?: number,
					textTransform?: string,
				},
				sm?: {
					width?: string,
					height?: string,
					fontSize?: string,
					fontWeight?: number,
					textTransform?: string,
				},
			},
			secondary?: {
				md?: {
					width?: string,
					height?: string,
					fontSize: string,
					fontWeight?: number,
					textTransform?: string,
				},
				sm?: {
					width?: string,
					height?: string,
					fontSize?: string,
					fontWeight?: number,
					textTransform?: string,
				},
			},
			tertiary?: {
				width?: string,
				height?: string,
				fontSize?: string,
				fontWeight?: number,
				textTransform?: string,
				borderRadius?: number,
			},
			quaternary?: {
				maxWidth?: string,
				height?: string,
				fontSize?: string,
				fontWeight?: number,
				textTransform?: string,
				borderRadius?: number,
			},
		},
	}
}

declare module '@material-ui/core/styles/createPalette' {
	interface Palette {
		convertmoreOrange: {
			white: string,
			main: string,
			hover: string,
			outlineHover?: string,
		},
		titleSubheader: {
			main: string,
		},
		tableCells: {
			border: string,
		},
		breadcrumbs: {
			grey: string,
			black: string,
		},
		convertmoreBlue: {
			main: string,
			white: string,
			hover: string,
			secondaryHover: string,
		},
		convertmoreBlack: {
			main: string,
			black: string,
			hover: string,
			borderColor: string,
		},
		credits: {
			main: string,
		},
		chart: {
			pageVisits: {
				main: string
			},
			widgetViews: {
				main: string,
			},
			calls: {
				main: string,
			},
			conversionRate: {
				main: string,
			},
		}
	}

	interface PaletteOptions {
		convertmoreOrange?: {
			white?: string,
			main?: string,
			hover?: string,
			outlineHover?: string,
		},
		titleSubheader?: {
			main?: string,
		},
		tableOddCell?: {
			main?: string,
		},
		tableCells?: {
			border?: string,
		},
		breadcrumbs?: {
			grey?: string,
			black?: string,
		},
		convertmoreBlue?: {
			main?: string,
			white?: string,
			hover?: string,
			secondaryHover?: string,
		},
		convertmoreBlack?: {
			main?: string,
			black?: string,
			hover?: string,
			borderColor?: string,
		},
		credits?: {
			main?: string,
		},
		chart?: {
			pageVisits?: {
				main?: string
			},
			widgetViews?: {
				main?: string,
			},
			calls?: {
				main?: string,
			},
			conversionRate?: {
				main?: string,
			},
		},
	}
}

declare module '@material-ui/core/styles/createTheme' {
	interface Theme {
		customSpacing: {
			primary: string,
			secondary: string,
		},
		completeStyles: {
			inputDesign: {
				'& label.Mui-focused': {
					color:string,
				},
				'& .MuiOutlinedInput-root': {
					'&:hover fieldset': {
						borderColor: string,
					},
					'&.Mui-focused fieldset': {
						borderColor: string,
					},
				},
				backgroundColor: string,
				borderRadius: number,
				'&:hover': {
					borderRadius: number,
				},
			},
		},
		papers?: {
			exitPopup: Paper,
			timedPopup: Paper,
			floatingIcon: Paper,
			successfulPaper: Paper,
			missedPaper: Paper,
			busyPaper: Paper,
		},
		styledTableDesign: {
			cells: {
				head: {
					backgroundColor: string,
					color: string,
					'&:last-child': {
						borderTopRightRadius: number,
					},
					'&:first-child': {
						borderTopLeftRadius: number,
					},
					fontSize: string,
				},
				body: {
					fontSize: string,
					fontWeight: number,
				},
				root: {
					borderBottomColor: string,
				},
			},
			rows: {
				fontSize: string,
				borderTop: string,
				borderColor: string,
				borderTopColor: string,
				'&:nth-of-type(odd)': {
					backgroundColor: string,
					borderColor: string,
				},
			},
		},
	}
	interface ThemeOptions {
		customSpacing?: {
			primary?: string,
			secondary?: string,
		},
		completeStyles?: {
			inputDesign?: {
				'& label.Mui-focused'?: {
					color?: string,
				},
				'& .MuiOutlinedInput-root'?: {
					'&:hover fieldset'?: {
						borderColor?: string,
					},
					'&.Mui-focused fieldset'?: {
						borderColor?: string,
					},
				},
				backgroundColor?: string,
				borderRadius?: number,
				'&:hover'?: {
					borderRadius?: number,
				},
			},
		},
		papers?: {
			exitPopup?: Paper,
			timedPopup?: Paper,
			floatingIcon?: Paper,
			successfulPaper?: Paper,
			missedPaper?: Paper,
			busyPaper?: Paper,
			noAnswerPaper?: Paper,
		},
		styledTableDesign?: {
			cells?: {
				head?: {
					backgroundColor?: string,
					color?: string,
					'&:last-child'?: {
						borderTopRightRadius?: number,
					},
					'&:first-child'?: {
						borderTopLeftRadius?: number,
					},
					fontSize?: string,
				},
				body?: {
					fontSize?: string,
					fontWeight?: number,
				},
				root?: {
					borderBottomColor?: string,
				},
			},
			rows?: {
				fontSize?: string,
				borderTop?: string,
				borderColor?: string,
				borderTopColor?: string,
				'&:nth-of-type(odd)'?: {
					backgroundColor?: string,
					borderColor?: string,
				},
			},
		},
	}
}

const theme = createMuiTheme(
	/**
	 * @see https://material-ui.com/customization/themes/#theme-configuration-variables
	 */
	{
		// direction: "rtl",
		typography: {
			fontFamily: ['Nunito'].join(','),
			body1: {
				fontSize: 14,
			},
			body2: {
				fontSize: 14,
			},
			h4: {
				marginTop: '0.85rem',
				fontSize: '2.286rem',
				fontWeight: 700,
				color: '#000000',
			},
			h6: {
				fontSize: '1.286rem',
				marginBottom: '1.286rem',
				color: '#66727B',
			},
			buttons: {
				primary: {
					lg: {
						width: '21.43rem',
						height: '4.286rem',
						fontSize: '1.286rem',
						fontWeight: 700,
						textTransform: 'none',
					},
					md: {
						width: '17.14rem',
						height: '3.571rem',
						fontSize: '1.143rem',
						fontWeight: 600,
						textTransform: 'none',
					},
					sm: {
						width: '12.86rem',
						height: '3.571rem',
						fontSize: '1.043rem',
						fontWeight: 600,
						textTransform: 'none',
					},
				},
				secondary: {
					md: {
						width: '14.29rem',
						height: '3.571rem',
						fontSize: '1.143rem',
						fontWeight: 600,
						textTransform: 'none',
					},
					sm: {
						width: '12.29rem',
						height: '3.571rem',
						fontSize: '1.043rem',
						fontWeight: 600,
						textTransform: 'none',
					},
				},
				tertiary: {
					width: '5.714rem',
					height: '2.543rem',
					fontSize: '1.043rem',
					fontWeight: 600,
					textTransform: 'none',
					borderRadius: 3,
				},
				quaternary: {
					maxWidth: '100%',
					height: '2.543rem',
					fontSize: '1.043rem',
					fontWeight: 600,
					textTransform: 'none',
					borderRadius: 3,
				},
			},
		},
		shape: {
			borderRadius: 5,
		},
		palette: {
			primary: {
				// light: will be calculated from palette.primary.main,
				main: '#83D1FC',
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			secondary: {
				// light: will be calculated from palette.primary.main,
				main: '#3783e7',
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			error: {
				// light: will be calculated from palette.primary.main,
				main: '#f018a6',
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			tableCells: {
				border: '#E7E7E7',
			},
			breadcrumbs: {
				grey: '#66727B',
				black: '#000000',
			},
			chart: {
				pageVisits: {
					main: '#83D1FC',
				},
				widgetViews: {
					main: '#1BC5BD',
				},
				calls: {
					main: '#8A51FC',
				},
				conversionRate: {
					main: '#ED5221',
				},
			},
			convertmoreOrange: {
				main: '#EA5220',
				white: '#FFFFFF',
				hover: '#dc4615',
				// outlineHover: "#fef5f2"
			},
			convertmoreBlue: {
				main: '#83D1FC',
				white: '#FFFFFF',
				hover: '#63b2e0',
				secondaryHover: '#83d1fc',
			},
			convertmoreBlack: {
				main: '#FFFFFF',
				black: '#000000',
				hover: '#E5E5E5',
				borderColor: '#66727B',
			},
			titleSubheader: {
				main: '#66727B',
			},
			credits: {
				main: '#0654a8',
			},
		},
		customSpacing: {
			primary: '4.571rem',
			secondary: '2.857rem',
		},
		completeStyles: {
			inputDesign: {
				'& label.Mui-focused': {
					color: '#83D1FC',
				},
				'& .MuiOutlinedInput-root': {
					'&:hover fieldset': {
						borderColor: '#E7E7E7',
					},
					'&.Mui-focused fieldset': {
						borderColor: '#83D1FC',
					},
				},
				backgroundColor: '#FFFFFF',
				borderRadius: 3,
				'&:hover': {
					borderRadius: 3,
				},
			},
		},
		/**
		 * @see https://material-ui.com/customization/globals/#default-props
		 */
		// Name of the component ⚛️
		papers: {
			exitPopup: {
				textAlign: 'center',
				minWidth: '9.286rem',
				maxWidth: '10.286rem',
				padding: 8,
				backgroundColor: '#E0F8F7',
				borderRadius: 3,
				color: '#1BC5BD',
			},
			timedPopup: {
				textAlign: 'center',
				minWidth: '9.286rem',
				maxWidth: '10.286rem',
				padding: 8,
				backgroundColor: '#EDF8FE',
				borderRadius: 3,
				color: '#83D1FC',
			},
			floatingIcon: {
				textAlign: 'center',
				minWidth: '9.286rem',
				maxWidth: '10.286rem',
				padding: 8,
				backgroundColor: '#F5F0FE',
				borderRadius: 3,
				color: '#8A51FC',
			},
			successfulPaper: {
				textAlign: 'center',
				minWidth: '9.286rem',
				padding: 8,
				backgroundColor: '#E0F8F7',
				borderRadius: 3,
				color: '#3EC5BD',
			},
			missedPaper: {
				textAlign: 'center',
				minWidth: '9.286rem',
				padding: 8,
				backgroundColor: '#FDE2E5',
				borderRadius: 3,
				color: '#F54E60',
			},
			busyPaper: {
				textAlign: 'center',
				minWidth: '9.286rem',
				padding: 8,
				backgroundColor: '#FEF4DD',
				borderRadius: 3,
				color: '#F8A801',
			},
			noAnswerPaper: {
				textAlign: 'center',
				minWidth: '9.286rem',
				padding: 8,
				backgroundColor: '#DCDCDC',
				borderRadius: 3,
				color: '#696969',
			},
		},
		styledTableDesign: {
			cells: {
				head: {
					backgroundColor: '#83D1FC',
					color: '#ffffff',
					'&:last-child': {
						borderTopRightRadius: 5,
					},
					'&:first-child': {
						borderTopLeftRadius: 5,
					},
					fontSize: '1.286rem',
				},
				body: {
					fontSize: '1rem',
					fontWeight: 700,
				},
				root: {
					borderBottomColor: '#83D1FC',
				},
			},
			rows: {
				fontSize: '1rem',
				borderTop: '1px solid',
				borderColor: '#ffffff',
				borderTopColor: '#E7E7E7',
				'&:nth-of-type(odd)': {
					backgroundColor: '#F9F9F9',
					borderColor: '#F9F9F9',
				},
			},
		},

		/**
		 * @see https://material-ui.com/customization/globals/#default-props
		 */
		props: {
			// Name of the component ⚛️
			MuiButtonBase: {
				// The properties to apply
				disableRipple: false, // No more ripple, on the whole application 💣!
			},

			// Set default elevation to 1 for popovers.
			MuiPopover: {
				elevation: 1,
			},
		},
		// overrides: {
		// 	MuiTypography: {
		// 		h6: {
		// 			color: 'black',
		// 			fontSize: '1.822rem',
		// 			fontWeight: 800,
		// 			marginBottom: 0,
		// 		},
		// 	},
		// 	MuiPickersToolbar: {
		// 		toolbar: {
		// 			backgroundColor: '#83D1FC',
		// 		},
		// 	},
		// 	MuiPickersCalendarHeader: {
		// 		switchHeader: {
		// 			textColor: '#ffffff',
		// 		},
		// 	},
		// 	MuiPickersDay: {
		// 		day: {
		// 			color: '#83D1FC',
		// 		},
		// 		daySelected: {
		// 			backgroundColor: '#83D1FC',
		// 		},
		// 		dayDisabled: {
		// 			color: '#83D1FC',
		// 		},
		// 		current: {
		// 			color: '#83D1FC',
		// 		},
		// 	},
		// },
	}
);

export function MaterialThemeProvider(props) {
	const { children } = props;
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
