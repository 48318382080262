/* eslint-disable */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	select: {
		width: '150px',
	},
	subTopics: {
		padding: '9px 25px',
		color: '#0553A8',
		fontWeight: 700,
		fontSize: 12,
		textTransform: 'uppercase',
	},
});

export function AsideMenuList({ layoutProps }) {
	const classes = useStyles();
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
			: '';
	};

	return (
		<>
			<button style={{ display: 'none' }} id="kt_aside_close_btn" />
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<span className={`menu-sub-topic ${classes.subTopics}`}>Dashboard</span>
				{/* begin::1 Level */}
				<li
					className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
					aria-haspopup="true"
				>
					<NavLink
						className="menu-link"
						to="/dashboard"
						onClick={() => {
							const closeMenuHiddenButton = document.getElementById('kt_aside_close_btn');
							closeMenuHiddenButton.click();
						}}
					>
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
						</span>
						<span className="menu-text">Analytics</span>
					</NavLink>
				</li>
			</ul>

			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<span className={`menu-sub-topic ${classes.subTopics}`}>Calls</span>
				{/* begin::1 Level */}
				<li
					className={`menu-item ${getMenuItemActive('/calls', false)}`}
					aria-haspopup="true"
				>
					<NavLink
						className="menu-link"
						to="/calls"
						onClick={() => {
							const closeMenuHiddenButton = document.getElementById('kt_aside_close_btn');
							closeMenuHiddenButton.click();
						}}
					>
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Call.svg')} />
						</span>
						<span className="menu-text">Calls History</span>
					</NavLink>
				</li>
				<li
					// /calls
					className={`menu-item ${getMenuItemActive('/upcoming-calls', false)}`}
					aria-haspopup="true"
				>
					{/* /calls */}
					<NavLink
						className="menu-link"
						to="/upcoming-calls"
						onClick={() => {
							const closeMenuHiddenButton = document.getElementById('kt_aside_close_btn');
							closeMenuHiddenButton.click();
						}}
					>
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
						</span>
						<span className="menu-text">Upcoming Calls</span>
					</NavLink>
				</li>
			</ul>

			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<span className={`menu-sub-topic ${classes.subTopics}`}>Conversion Toolbox</span>
				{/* begin::1 Level */}
				<li
					className={`menu-item ${getMenuItemActive('/widgets', false)}`}
					aria-haspopup="true"
				>
					<NavLink
						className="menu-link"
						to="/widgets"
						onClick={() => {
							const closeMenuHiddenButton = document.getElementById('kt_aside_close_btn');
							closeMenuHiddenButton.click();
						}}
					>
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Design/Component.svg')} />
						</span>
						<span className="menu-text">Widgets</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/websites', false)}`}
					aria-haspopup="true"
				>
					<NavLink
						className="menu-link"
						to="/websites"
						onClick={() => {
							const closeMenuHiddenButton = document.getElementById('kt_aside_close_btn');
							closeMenuHiddenButton.click();
						}}
					>
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
						</span>
						<span className="menu-text">Websites</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/agents', false)}`}
					aria-haspopup="true"
				>
					<NavLink
						className="menu-link"
						to="/agents"
						onClick={() => {
							const closeMenuHiddenButton = document.getElementById('kt_aside_close_btn');
							closeMenuHiddenButton.click();
						}}
					>
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
						</span>
						<span className="menu-text">Agents</span>
					</NavLink>
				</li>
			</ul>

			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<span className={`menu-sub-topic ${classes.subTopics}`}>Call Credits</span>
				{/* begin::1 Level */}
				<li
					className={`menu-item ${getMenuItemActive('/billing', false)}`}
					aria-haspopup="true"
				>
					<NavLink
						className="menu-link"
						to="/billing"
						onClick={() => {
							const closeMenuHiddenButton = document.getElementById('kt_aside_close_btn');
							closeMenuHiddenButton.click();
						}}
					>
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Dollar.svg')} />
						</span>
						<span className="menu-text">Payments</span>
						{/* <span className="menu-text">Billing</span> */}
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/payments', false)}`}
					aria-haspopup="true"
				>
					<NavLink
						className="menu-link"
						to="/payments"
						onClick={() => {
							const closeMenuHiddenButton = document.getElementById('kt_aside_close_btn');
							closeMenuHiddenButton.click();
						}}
					>
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')} />
						</span>
						<span className="menu-text">Payments History</span>
					</NavLink>
				</li>
			</ul>

			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/* begin::1 Level */}
				<span className={`menu-sub-topic ${classes.subTopics}`}>Settings</span>
				<li
					className={`menu-item ${getMenuItemActive('/settings', false)}`}
					aria-haspopup="true"
				>
					<NavLink
						className="menu-link"
						to="/settings"
						onClick={() => {
							const closeMenuHiddenButton = document.getElementById('kt_aside_close_btn');
							closeMenuHiddenButton.click();
						}}
					>
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')} />
						</span>
						<span className="menu-text">Account Settings</span>
					</NavLink>
				</li>
				{/* end::1 Level */}
			</ul>
			{/* end::Menu Nav */}
		</>
	);
}
