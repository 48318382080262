import React from 'react';

// Components
import { makeStyles } from '@material-ui/core';

import PhoneInput from 'material-ui-phone-number';

const useStyles = makeStyles(theme => ({
	input: {
		width: '300px !important',
		marginBottom: '8px',
		[theme.breakpoints.down('xs')]: {
			minWidth: '100%',
			maxWidth: '100%',
		},
	},
	fullWidth: {
		width: '100% !important',
		marginBottom: '8px',
		[theme.breakpoints.down('xs')]: {
			minWidth: '100%',
			maxWidth: '100%',
		},
	},
}));

const PhoneNumberInput = React.memo((props: any) => {
	const {
		disabled,
		error,
		onDataChange,
		onFieldBlur,
		fullWidth,
	} = props;

	const classes = useStyles();

	const onChange = (value:string) => {
		onDataChange(props.name, value.replace(/[()-\s]/g, ''));
	};

	return (
		<PhoneInput
			variant="outlined"
			error={error}
			disableDropdown={disabled}
			defaultCountry="us"
			countryCodeEditable={false}
			onChange={onChange}
			onBlur={() => onFieldBlur(props.name, true, true)}
			className={fullWidth ? classes.fullWidth : classes.input}
			disableAreaCodes
			{...props}
		/>
	);
});

export default PhoneNumberInput;
