import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import ReactPlayer from 'react-player';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		iconButtons: {
			padding: '2px 6px 2px 6px',
		},
		noRecordText: {
			padding: '12.5px 30px',
		},
		popover: {
			'& .MuiPaper-root': {
				borderRadius: '30px',
				border: '1px solid #C3C2C2',
				overflow: 'hidden',
				'& audio': {
					marginTop: '-2px',
				},
			},
		},
	}),);

export default function AudioPopup(props) {
	const { url } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<IconButton
				onClick={handleClick}
				className={classes.iconButtons}
				size="medium"
				color="primary"
				disabled={!url}
			>
				<PlayArrowRoundedIcon />
			</IconButton>
			<Popover
				className={classes.popover}
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
			>
				<ReactPlayer
					url={url}
					width="400px"
					height="50px"
					playing
					controls
					config={{ file: { forceAudio: true } }}
				/>
			</Popover>
		</div>
	);
}
