/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-bind */
import React, {
	useCallback, useRef, useState, useEffect,
} from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import {
	Box, Step, StepLabel, Stepper, Typography, useTheme,
} from '@material-ui/core';

import { ContentRoute } from '../../../../_metronic/layout';

// Types
import { RootStateType } from '../../../../redux/rootReducer';
import { ValidationValuesRef } from '../../../../typecheckings';

// Helpers
import { extractDomain } from '../../../lib/url';

// Constants
import {
	ALL_WIDGETS_DATA, designSchema, generalSchemaObject, STEPS,
} from '../../../constants/widgetBuilder';
import { getMessage } from '../../../constants/messages';

// Redux
import { actions } from '../../../../redux/website/websiteRedux';
import { saveAgentSuccess } from '../../../../redux/agents/agentActions';
import { actions as widgetActions } from '../../../../redux/widget/widgetRedux';
import { actions as authActions } from '../../../modules/Auth/_redux/authRedux';
import { postNotification } from '../../../../redux/notifications/actions';

// Style connector
import { WidgetBuilderConnector } from './WidgetBuilder.styles';

// Components
import GeneralStep from './Steps/GeneralStep';
import DesignStep from './Steps/DesignStep';
import CodeStep from './Steps/CodeStep';
import StepIcon from '../../../components/StepIcon/StepIcon';
import {
	createAgent, createWebsite, createWidget, fetchWidgets, updateWidget,
} from '../../../../api';

export function WidgetBuilder(props) {
	const {
		widget, agents, websites, fakeAgents, widgets,
	} = props;

	// to know if it is edit or create
	const isNewWidget = typeof widget.id === 'undefined';

	const metaData = useSelector((state: RootStateType) => state.metaData);
	const [finished, setFinished] = useState(false);
	const [showCode, setShowCode] = useState(false);
	const [website, setWebsite] = useState();

	// Objects for data
	const [activeHours, setActiveHours] = useState(widget.settings.settings.activeHours);

	// Refs for data
	const activeHoursRef = useRef<ValidationValuesRef>();

	let {
		widgetType,
		callingRule,
		websiteUrl,
	} = widget;

	if (widget.typeId) {
		widgetType = metaData.widgetTypes.find(item => item.id === widget.typeId).name;
	}

	if (widget.callingRuleId) {
		callingRule = metaData.callingRules.find(item => item.id === widget.callingRuleId).name;
	}

	if (widget.websiteId) {
		websiteUrl = websites.find(website => website.id === widget?.websiteId)?.domain;
	}

	// General from data
	// ----------------------------------------------------------------------------------------------------
	const baseOptionsFormik = useFormik({
		validateOnChange: false,
		validateOnMount: false,
		initialValues: {
			name: widget.name,
			widgetType,
			showsAfter: widget.settings.settings.showsAfter,
			repeatAfter: widget.settings.settings.repeatAfter,
			displaysPerVisit: widget.settings.settings.displaysPerVisit,
		},
		validationSchema: generalSchemaObject.baseOptions,
		onSubmit: () => {
		},
	});

	const websiteUrlFormik = useFormik({
		validateOnChange: false,
		validateOnMount: false,
		initialValues: {
			websiteUrl,
		},
		validationSchema: generalSchemaObject.websiteUrl,
		onSubmit: () => {
		},
	});

	const agentOptionsFormik = useFormik({
		validateOnChange: false,
		validateOnMount: false,
		initialValues: {
			agentNumber: widget.settings.settings.agentNumber,
			agents: widget.agents.filter(agent => agent.name !== null).map(agent => agent.name),
			callingRule,
			useAgents: widget.settings.settings.useAgents,
		},
		validationSchema: generalSchemaObject.agentOptions,
		onSubmit: () => {
		},
	});

	const customCallerIdFormik = useFormik({
		validateOnChange: false,
		validateOnMount: false,
		initialValues: {
			customCallerId: widget.settings.settings.customCallerId,
		},
		validationSchema: generalSchemaObject.customCallerIdOptions,
		onSubmit: () => {
		},
	});

	const retryOptionsFormik = useFormik({
		validateOnChange: false,
		validateOnMount: false,
		initialValues: {
			numberOfTries: widget.settings.settings.numberOfTries,
			minutesBetweenTries: widget.settings.settings.minutesBetweenTries,
		},
		validationSchema: generalSchemaObject.retryOptions,
		onSubmit: () => {
		},
	});

	const showScheduleOptionsFormik = useFormik({
		validateOnChange: false,
		validateOnMount: false,
		initialValues: {
			// For determining default values are used old fields in order to not change behaviour of existing widgets
			duringWorkingHours: !!(
				(widget.settings.settings.showScheduleForm?.duringWorkingHours !== undefined)
					? widget.settings.settings.showScheduleForm.duringWorkingHours
					: widget.settings.settings.useSchedule
			),
			outsideWorkingHours: !!(
				(widget.settings.settings.showScheduleForm?.outsideWorkingHours !== undefined)
					? widget.settings.settings.showScheduleForm.outsideWorkingHours
					: widget.settings.settings.useSchedule && !widget.settings.settings.duringWorkingHoursOnly
			),
		},
		validationSchema: generalSchemaObject.showScheduleOptions,
		onSubmit: () => {
		},
	});

	const notificationOptionsFormik = useFormik({
		validateOnChange: false,
		validateOnMount: false,
		initialValues: {
			email: {
				value: widget.notifications.email.value,
				isActive: widget.notifications.email.isActive,
			},
			phoneNumber: {
				value: widget.notifications.phoneNumber.value,
				isActive: widget.notifications.phoneNumber.isActive,
			},
		},
		validationSchema: generalSchemaObject.notifications,
		onSubmit: () => {
		},
	});

	const redirectionOptionsFormik = useFormik({
		validateOnChange: false,
		validateOnMount: false,
		initialValues: {
			callNow: {
				value: widget.settings.settings.redirections?.callNow.value,
				isActive: widget.settings.settings.redirections?.callNow.isActive,
			},
			scheduleCall: {
				value: widget.settings.settings.redirections?.scheduleCall.value,
				isActive: widget.settings.settings.redirections?.scheduleCall.isActive,
			},
		},
		validationSchema: generalSchemaObject.redirections,
		onSubmit: () => {
		},
	});

	const waitingAudioOptionsFormik = useFormik({
		validateOnChange: false,
		validateOnMount: false,
		initialValues: {
			isActive: !!widget.settings.settings.waitingAudio?.isActive,
			url: widget.settings.settings.waitingAudio?.url ? widget.settings.settings.waitingAudio.url : '',
		},
		validationSchema: generalSchemaObject.waitingAudioOptions,
		onSubmit: () => {
		},
	});

	const deviceDisplayOptionsFormik = useFormik({
		validateOnChange: false,
		validateOnMount: false,
		initialValues: {
			// For determining default values are used old fields in order to not change behaviour of existing widgets
			onDesktop: !!(
				(widget.settings.settings.deviceDisplay?.onDesktop !== undefined)
					? widget.settings.settings.deviceDisplay.onDesktop
					: true
			),
			onMobile: !!(
				(widget.settings.settings.deviceDisplay?.onMobile !== undefined)
					? widget.settings.settings.deviceDisplay.onMobile
					: true
			),
		},
		validationSchema: generalSchemaObject.waitingAudioOptions,
		onSubmit: () => {
		},
	});
	// ----------------------------------------------------------------------------------------------------

	// Design form data
	// ----------------------------------------------------------------------------------------------------
	const globalSetupFormikData = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: {
			...widget.settings.globalSetup,
			floatingIcon: {
				...widget.settings.globalSetup.floatingIcon,
				position: widget.settings.globalSetup?.floatingIcon?.position || 'right',
			},
		},
		validationSchema: designSchema.globalSetup,
		onSubmit: () => {
		},
	});

	const callSetupFormikData = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: widget.settings.callSetup,
		validationSchema: designSchema.callSetup,
		onSubmit: () => {
		},
	});

	const scheduleSetupFormikData = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		initialValues: widget.settings.scheduleSetup,
		validationSchema: designSchema.scheduleSetup,
		onSubmit: () => {
		},
	});
	// ----------------------------------------------------------------------------------------------------

	const theme = useTheme();

	// Redux
	const dispatch = useDispatch();
	const widgetTypes = useSelector((state: RootStateType) => state.metaData.widgetTypes);
	const callingRules = useSelector((state: RootStateType) => state.metaData.callingRules);

	useEffect(() => {
		dispatch(authActions.requestUser());
	}, [dispatch]);

	async function validateForms(step) {
		if (step === 0) {
			const fields = await Promise.all([
				await websiteUrlFormik.validateForm(),
				await customCallerIdFormik.validateForm(),
				await agentOptionsFormik.validateForm(),
				await activeHoursRef.current.validate(),
				await notificationOptionsFormik.validateForm(),
				await redirectionOptionsFormik.validateForm(),
				await baseOptionsFormik.validateForm(),
				await retryOptionsFormik.validateForm(),
				await waitingAudioOptionsFormik.validateForm(),
				await deviceDisplayOptionsFormik.validateForm(),
			]);

			if (fields.filter(field => Object.keys(field).length > 0).length > 0) {
				scrollTop();
				return false;
			}

			// set Active Hours
			setActiveHours(activeHoursRef.current.values());

			return true;
		}

		if (step === 1) {
			const fields = await Promise.all([
				await globalSetupFormikData.validateForm(),
				await scheduleSetupFormikData.validateForm(),
				await callSetupFormikData.validateForm(),
			]);

			if (fields.filter(field => Object.keys(field).length > 0).length > 0) {
				scrollTop();
				return false;
			}

			return true;
		}
		return true;
	}

	const handleSubmit = useCallback(async () => {
		let updateError = false;

		const validate = await validateForms(1);

		if (validate) {
			const generalFormikData = {
				...baseOptionsFormik.values,
				...agentOptionsFormik.values,
				...customCallerIdFormik.values,
				...retryOptionsFormik.values,
				activeHours,
				websiteUrl: websiteUrlFormik.values.websiteUrl,
				notifications: {
					...notificationOptionsFormik.values,
				},
				redirections: {
					...redirectionOptionsFormik.values,
				},
				showScheduleForm: {
					...showScheduleOptionsFormik.values,
				},
				waitingAudio: {
					...waitingAudioOptionsFormik.values,
				},
				deviceDisplay: {
					...deviceDisplayOptionsFormik.values,
				},
			};

			const designFormikData = {
				globalSetup: {
					...globalSetupFormikData.values,
				},
				callSetup: {
					...callSetupFormikData.values,
				},
				scheduleSetup: {
					...scheduleSetupFormikData.values,
				},
			};

			try {
				const {
					websiteUrl, widgetType, name, notifications, ...generalData
				} = generalFormikData;
				const {
					agentNumber, agents: formAgents, callingRule, useAgents,
				} = generalFormikData;

				// Websites
				const websiteDomain = extractDomain(websiteUrl);
				let widgetWebsite = websites.find(website => website.domain === websiteDomain);

				// Create new website if it does not exist
				if (!widgetWebsite) {
					widgetWebsite = await createWebsite(websiteDomain);
					dispatch(actions.addWebsite(widgetWebsite));
				}

				setWebsite(widgetWebsite);

				// Determine agent ids
				let agentIds = null;

				// If formAgents array exist
				if (formAgents.length > 0 && useAgents) {
					// Probably should search per id
					agentIds = formAgents.map(agent => agents.find(item => agent === item.name).id);
				} else if (agentNumber) {
					const regularAgent = agents.find(agent => agent.phone === agentNumber);
					const irregularAgent = fakeAgents.find(agent => agent.phone === agentNumber);

					if (regularAgent) {
						agentIds = [regularAgent.id];
						generalData.useAgents = true;
					} else if (irregularAgent) {
						agentIds = [irregularAgent.id];
					} else {
						// Phone number isn't associated to any agent
						const newAgent = await createAgent({
							// @ts-ignore
							availability: {},
							isVerified: false,
							isActive: true,
							phone: agentNumber,
							isRegular: false,
						});

						agentIds = [newAgent.id];

						dispatch(saveAgentSuccess(newAgent));
					}
				}

				const widgetData: Record<string, any> = {
					agentIds,
					notifications,
					name,
					callingRuleId: callingRules.find(item => item.name === callingRule)?.id,
					websiteId: widgetWebsite.id,
					settings: {
						...designFormikData,
						settings: {
							...generalData,
						},
					},
					isActive: true,
				};

				// Update widget if widget id exist
				if (widget.id) {
					// Is update process
					updateError = true;
					// set active value
					widgetData.isActive = widget.isActive;
					widgetData.typeId = widgetTypes.find(type => type.name === widgetType).id;

					await updateWidget(widget.id, widgetData);
				} else if (widgetType === ALL_WIDGETS_DATA.name) {
					// Create widget for each widget type
					await Promise.all(widgetTypes.map((type) => {
						widgetData.typeId = type.id;
						widgetData.name = `${name} - ${type.name}`;
						return createWidget(widgetData);
					}));

					setShowCode(widgets.filter(widget => widget.websiteId === widgetWebsite.id).length === 0);
				} else {
					// Create only one widget
					widgetData.typeId = widgetTypes.find(type => type.name === widgetType).id;
					await createWidget(widgetData);

					setShowCode(widgets.filter(widget => widget.websiteId === widgetWebsite.id).length === 0);
				}

				dispatch(widgetActions.updateWidgets(await fetchWidgets()));

				setFinished(true);
				dispatch(postNotification(getMessage('WIDGET_CREATE_SUCCESS'), 'success'));
			} catch (error) {
				dispatch(postNotification(updateError ? getMessage('WIDGET_EDIT_ERROR') : getMessage('WIDGET_CREATE_ERROR'), 'error'));
			}
		}
	}, [
		agentOptionsFormik.values,
		customCallerIdFormik.values,
		showScheduleOptionsFormik.values,
		baseOptionsFormik.values,
		callSetupFormikData.values,
		globalSetupFormikData.values,
		notificationOptionsFormik.values,
		redirectionOptionsFormik.values,
		retryOptionsFormik.values,
		scheduleSetupFormikData.values,
		websiteUrlFormik.values.websiteUrl,
		activeHours,
	]);

	// Scroll to top
	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const pageTitle = widget.id ? 'Edit Widget' : 'Create New Widget';

	return (
		<>
			<Box display="flex" p={0} my={2}>
				<Box width="100%">
					<Typography variant="h4">{pageTitle}</Typography>
				</Box>
			</Box>
			{finished
				? (
					<>
						<Box style={{ backgroundColor: theme.palette.grey[100] }} borderRadius={theme.shape.borderRadius}>
							<Stepper
								style={{ backgroundColor: 'transparent' }}
								activeStep={2}
								alternativeLabel
								connector={<WidgetBuilderConnector />}
							>
								{STEPS.map(item => (
									<Step key={item}>
										<StepLabel StepIconComponent={StepIcon}>{item}</StepLabel>
									</Step>
								))}
							</Stepper>
						</Box>
						<CodeStep website={website} showCode={showCode} />
					</>
				)
				: (
					<Switch>
						<ContentRoute path="/widgets/manage/general/:id?">
							<Box
								style={{ backgroundColor: theme.palette.grey[100] }}
								borderRadius={theme.shape.borderRadius}
							>
								<Stepper
									style={{ backgroundColor: 'transparent' }}
									activeStep={0}
									alternativeLabel
									connector={<WidgetBuilderConnector />}
								>
									{STEPS.map(item => (
										<Step key={item}>
											<StepLabel StepIconComponent={StepIcon}>{item}</StepLabel>
										</Step>
									))}
								</Stepper>
							</Box>
							<GeneralStep
								baseOptionsFormik={baseOptionsFormik}
								agentOptionsFormik={agentOptionsFormik}
								customCallerIdFormik={customCallerIdFormik}
								notificationOptionsFormik={notificationOptionsFormik}
								redirectionOptionsFormik={redirectionOptionsFormik}
								websiteUrlFormik={websiteUrlFormik}
								retryOptionsFormik={retryOptionsFormik}
								showScheduleOptionsFormik={showScheduleOptionsFormik}
								waitingAudioOptionsFormik={waitingAudioOptionsFormik}
								deviceDisplayOptionsFormik={deviceDisplayOptionsFormik}
								validateForms={validateForms}
								widget={widget}
								activeHours={activeHours}
								activeHoursRef={activeHoursRef}
								isNewWidget={isNewWidget}
							/>
						</ContentRoute>
						<ContentRoute path="/widgets/manage/design/:id?">
							<Box
								style={{ backgroundColor: theme.palette.grey[100] }}
								borderRadius={theme.shape.borderRadius}
							>
								<Stepper
									style={{ backgroundColor: 'transparent' }}
									activeStep={1}
									alternativeLabel
									connector={<WidgetBuilderConnector />}
								>
									{STEPS.map(item => (
										<Step key={item}>
											<StepLabel StepIconComponent={StepIcon}>{item}</StepLabel>
										</Step>
									))}
								</Stepper>
							</Box>
							<DesignStep
								globalSetupFormikData={globalSetupFormikData}
								callSetupFormikData={callSetupFormikData}
								scheduleSetupFormikData={scheduleSetupFormikData}
								widgetType={baseOptionsFormik.values.widgetType}
								handleSubmit={handleSubmit}
								validateForms={validateForms}
							/>
						</ContentRoute>
						<ContentRoute path="/widgets/manage/code/:id?">
							<Box
								style={{ backgroundColor: theme.palette.grey[100] }}
								borderRadius={theme.shape.borderRadius}
							>
								<Stepper
									style={{ backgroundColor: 'transparent' }}
									activeStep={2}
									alternativeLabel
									connector={<WidgetBuilderConnector />}
								>
									{STEPS.map(item => (
										<Step key={item}>
											<StepLabel StepIconComponent={StepIcon}>{item}</StepLabel>
										</Step>
									))}
								</Stepper>
							</Box>
							<CodeStep website={website} showCode={showCode} />
						</ContentRoute>
					</Switch>
				)}
		</>
	);
}
