import { makeStyles } from '@material-ui/core';

export const billingStyles = makeStyles(theme => ({
	container: {
		marginBottom: '30px',
		boxShadow: '0px 0px 0px 0px rgba(0,0,0,0) !important',
	},
	contentWrapper: {
		fontSize: '18px',
		padding: '0px !important',
	},
	cardHeader: {
		maxWidth: '100%',
		padding: '0px !important',
	},
	cardHeaderAction: {
		margin: '0',
	},
	noDataParagraph: {
		textAlign: 'center',
	},
	h4: {
		...theme.typography.h4,
	},
	h6: {
		...theme.typography.h6,
	},
}));

export const autoRechargeOptionsButtonStyles = makeStyles(theme => ({
	button: {
		padding: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			...theme.typography.buttons.primary.sm,
		},
		[theme.breakpoints.up('md')]: {
			...theme.typography.buttons.primary.md,
		},
		[theme.breakpoints.up('lg')]: {
			...theme.typography.buttons.primary.lg,
		},
	},
	cancelButton: {
		color: theme.palette.convertmoreBlack.black,
		backgroundColor: theme.palette.convertmoreBlack.main,
		border: `1px solid ${theme.palette.convertmoreBlack.borderColor}`,
		'&:hover': {
			backgroundColor: theme.palette.convertmoreBlack.hover,
		},
	},
	changeButton: {
		color: theme.palette.convertmoreBlue.white,
		backgroundColor: theme.palette.convertmoreBlue.main,
		'&:hover': {
			backgroundColor: theme.palette.convertmoreBlue.hover,
		},
	},
}));
