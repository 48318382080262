import {
	AGENTS_CREATE_AGENT,
	AGENTS_CREATE_AGENT_SUCCESS,
	AGENTS_GET_AGENTS,
	AGENTS_UPDATE_AGENT,
	AGENTS_UPDATE_AGENT_ACTIVITY,
	AGENTS_UPDATE_AGENT_SUCCESS,
	AgentsActionsType,
	AgentType,
} from './agentTypes';

export const getAgents = (): AgentsActionsType => {
	return { type: AGENTS_GET_AGENTS };
};

export const updateAgentActivity = (agent: AgentType): AgentsActionsType => {
	return {
		type: AGENTS_UPDATE_AGENT_ACTIVITY,
		payload: agent,
	};
};
export const saveAgent = (agent: AgentType, errorMsg: string): AgentsActionsType => {
	return {
		type: AGENTS_CREATE_AGENT,
		payload: agent,
		meta: {
			errorMsg,
		},
	};
};

export const updateAgentSuccess = (agent: AgentType): AgentsActionsType => {
	return {
		type: AGENTS_UPDATE_AGENT_SUCCESS,
		payload: agent,
	};
};

export const updateAgent = (agent: AgentType, errorMsg: string): AgentsActionsType => {
	return {
		type: AGENTS_UPDATE_AGENT,
		payload: agent,
	};
};

export const saveAgentSuccess = (agent: AgentType) : AgentsActionsType => {
	return {
		type: AGENTS_CREATE_AGENT_SUCCESS,
		payload: agent,
	};
};
