// @ts-nocheck
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';

// constants
import { getMessage } from '../../constants/messages';

// Material ui
import { Box } from '@material-ui/core';
import SecondaryButton from '../_actions/SecondaryButton';

// Module
import { changePassword } from '../../modules/Auth/_redux/authCrud';

// Components
import TextFieldInput from '../_inputs/TextFieldInput';
import { postNotification } from '../../../redux/notifications/actions';
import { useDispatch } from 'react-redux';

const initialValues = {
	currentPassword: '',
	password: '',
	confirmPassword: '',
};

function ChangePassword(props) {
	const { intl } = props;
	const dispatch = useDispatch();
	// const [isRequested, setIsRequested] = useState(false);
	const ChangePasswordSchema = Yup.object().shape({
		currentPassword: Yup.string()
			.min(5, getMessage('MIN_FIELD_LENGTH', { length: 5 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
		password: Yup.string()
			.min(5, getMessage('MIN_FIELD_LENGTH', { length: 5 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
		confirmPassword: Yup.string()
			.required(getMessage('REQUIRED_FIELD'))
			.when('password', {
				is: val => (!!(val && val.length > 0)),
				then: Yup.string().oneOf(
					[Yup.ref('password')],
					getMessage('ACCOUNT_SETTINGS_PASSWORD_NOT_MATCHING')
				),
			}),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: ChangePasswordSchema,
		onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
			try {
				await changePassword(values.currentPassword, values.password, true);
				// setIsRequested(true);
				resetForm(initialValues);
				dispatch(postNotification(getMessage('ACCOUNT_SETTINGS_PASSWORD_CHANGE_SUCCESS'), 'success'));
			} catch (error) {
				dispatch(postNotification(getMessage('ACCOUNT_SETTINGS_PASSWORD_CHANGE_ERROR'), 'error'));
				setSubmitting(false);
				setStatus(
					intl.formatMessage(
						{ id: 'AUTH.VALIDATION.NOT_FOUND' },
						{ name: 'Placeholder' } // soon to implement properly formatted server error messages
					)
				);
			}
		},
	});

	return (
		<form>
			<Box mt={3}>
				<TextFieldInput
					fullWidth
					label="Current Password"
					name="currentPassword"
					type="password"
					{...formik.getFieldProps('currentPassword')}
					error={formik.touched.currentPassword && !!formik.errors.currentPassword}
					helperText={(formik.touched.currentPassword && formik.errors.currentPassword) || ' '}
				/>
				<br />
				<TextFieldInput
					fullWidth
					label="New Password"
					name="password"
					type="password"
					{...formik.getFieldProps('password')}
					error={formik.touched.password && !!formik.errors.password}
					helperText={(formik.touched.password && formik.errors.password) || ' '}
				/>
				<br />
				<TextFieldInput
					fullWidth
					label="Confirm Password"
					name="confirmPassword"
					type="password"
					{...formik.getFieldProps('confirmPassword')}
					error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
					helperText={(formik.touched.confirmPassword && formik.errors.confirmPassword) || ' '}
				/>
			</Box>
			<div className="form-group d-flex flex-wrap flex-start">
				<SecondaryButton
					variantColor="orange"
					onClick={() => formik.handleSubmit()}
					disabled={
						formik.isSubmitting ||
						!formik.isValid ||
						formik.values.password === '' ||
						formik.values.confirmPassword === ''
					}
				>
					<span>Save</span>
					{formik.isSubmitting && <span className="ml-3 spinner spinner-white" />}
				</SecondaryButton>
			</div>
			{/* {isRequested &&
				<Alert severity="success">Password changed successfully!</Alert>
			} */}
		</form>
	);
}

export default injectIntl(ChangePassword);
