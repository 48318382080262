import * as Yup from 'yup';
import inputErrors from './inputErrors';
import { getMessage } from '../constants/messages';

export const availabilityValidation = Yup.object().shape({
	useSchedule: Yup.bool(),
	activeHours: Yup.array(
		Yup.object().shape({
			day: Yup.string().required(),
			isActive: Yup.bool(),
			times: Yup.array().when('isActive', {
				is: true,
				then: Yup.array().required(getMessage('INPUT_ACTIVE_DAY_TIMEFRAME')).of(Yup.object().shape({
					from: Yup.string().required(inputErrors.requiredField),
					to: Yup.string().required(inputErrors.requiredField),
				})),
			}),
		})
	).test('isActiveAndSelected', undefined, (items) => {
		return items.some((item) => {
			if (item.isActive) {
				return item.times.length > 0;
			}
			return false;
		});
	}),
});
