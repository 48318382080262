// @ts-nocheck
import React, { useState } from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';
import 'antd/lib/time-picker/style/index.css';
import { Box } from '@material-ui/core';
import check from './check.svg';
import { ActiveHoursTimesType, DaysType } from '../../../typecheckings';

const { RangePicker } = TimePicker;

type PickerProps = {
	isActive: boolean
	day: DaysType
	times: ActiveHoursTimesType | null
	onSuccess: (time: ActiveHoursTimesType[], day: DaysType) => void
}

const Picker: React.FC<PickerProps> = React.memo((props) => {
	const {
		isActive,
		day,
		onSuccess,
		times,
	} = props;

	return (
		<Box>
			<RangePicker
				disabled={!isActive}
				hideDisabledOptions
				use12Hours
				inputReadOnly
				format="h:mm a"
				minuteStep={5}
				allowClear={false}
				onChange={(e, p) => {
					// setValue(e);
					onSuccess([
						{
							from: e[0].toISOString(),
							to: e[1].toISOString(),
						},
					], day);
				}}
				value={[moment(times.from), moment(times.to)]}
			/>
			{isActive && (
				<div
					style={{
						display: 'inline-block',
						marginLeft: '10px',
					}}
				>
					<img
						src={check}
						style={{
							maxHeight: '20px',
						}}
					/>
				</div>
			)}
		</Box>
	);
});

export default Picker;
