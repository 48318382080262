// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { capitalize, IconButton } from '@material-ui/core';
import { makeStyles, Box } from '@material-ui/core';
import { Pagination } from '../components/Pagination/Pagination';
import defaultValues from '../constants/defaultValues';
import ColoredPaper from '../components/ColoredPaper/ColoredPaper';
import AudioPopup from '../components/AudioPopup/AudioPopup';

// constants
import { getMessage } from '../constants/messages';

// Components
import TableGenerator from '../components/TableBuilder/TableGenerator';
import { TableFilter } from '../components/TableBuilder/TableFilter';
import { checkFilterState } from '../lib/checkFilterState';
import { convertUTCToTimezone, returnFilterDateTime } from '../lib/timezoneHelpers';
import PrimaryButton from '../components/_actions/PrimaryButton';
import downloadFile from '../lib/downloadFile';
import { postNotification } from '../../redux/notifications/actions';
import { TABLE_STATE } from '../constants/general';
import { TWILIO_CALL } from '../constants/api';

const formatGeolocation = (callGeolocation) => {
	if (callGeolocation) {
		const { country, region, city } = callGeolocation;
		return `${country}, ${region}, ${city}`;
	}
	return '/';
};

const useStyles = makeStyles(theme => ({
	container: {
		fontSize: '18px',
		marginBottom: '30px',
	},
	contentWrapper: {
		overflowX: 'auto',
		marginTop: theme.customSpacing.secondary,
	},
	cardHeaderAction: {
		margin: '0',
	},
	noDataParagraph: {
		textAlign: 'center',
	},
	columnHeaders: {
		padding: '12px',
		whiteSpace: 'nowrap',
	},
	rowCells: {
		padding: '12px',
		whiteSpace: 'nowrap',
	},
	callId: {
		// maxWidth: "200px", // dots with a smaller link that displays a tip tool showing the whole call ID ?
		padding: '12px',
		whiteSpace: 'nowrap',
		overflowX: 'auto',
	},
	iconButtons: {
		padding: '2px 6px 2px 6px',
	},
	h4: {
		...theme.typography.h4,
	},
	h6: {
		...theme.typography.h6,
	},
	ellipsis: {
		maxWidth: '200px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));
const INITIAL_FILTER_STATE = {
	Status: undefined,
	Websites: undefined,
	callType: undefined,
	dateFrom: defaultValues.DATE_FROM,
	dateTo: new Date(),
	widgetType: undefined,
};
const Calls = () => {
	const classes = useStyles();

	// Move calls data to redux?
	const [callsData, setCallsData] = useState(null);
	const [dataToRender, setDataToRender] = useState(null);
	const [tableData, setTableData] = useState([]);

	const dispatch = useDispatch();
	const websites = useSelector(state => state.website.websites);
	const widgets = useSelector(state => state.widget.widgets);
	const widgetTypes = useSelector(state => state.metaData.widgetTypes);
	const agents = useSelector(state => state.agents.data);
	const user = useSelector(state => state.auth.user);

	const [filterState, setFilterState] = useState(INITIAL_FILTER_STATE);
	const [page, setPage] = useState(1);
	const [loadingState, setLoadingState] = useState(TABLE_STATE.loading);

	const handlePageChange = (event, value) => {
		setLoadingState(TABLE_STATE.loading);
		setPage(value);
	};

	const handleDownloadCSV = useCallback(async () => {
		try {
			const dateFrom = filterState.dateFrom ? dayjs(filterState.dateFrom).set('hour', 0).set('minute', 0).set('second', 0)
				.set('millisecond', 0)
				.utc(true)
				.format() : undefined;
			const dateTo = filterState.dateTo ? dayjs(filterState.dateTo).set('hour', 23).set('minute', 59).set('second', 59)
				.set('millisecond', 99)
				.utc(true)
				.format() : undefined;
			const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}/client/calls/csv`, {
				params: {
					widgetTypeId: widgetTypes?.find(widgetType => widgetType?.name === filterState?.widgetType)?.id,
					websiteId: websites?.find(website => website?.domain === filterState?.Websites)?.id,
					dateFrom: dateFrom ? new Date(dateFrom) : undefined,
					dateTo: dateTo ? new Date(dateTo) : undefined,
					status: filterState?.Status === '' ? undefined : filterState?.Status?.toLowerCase(),
					type: filterState?.callType === '' ? undefined : filterState?.callType?.toLowerCase(),
				},
			});

			downloadFile(data, `calls_history-${format(convertUTCToTimezone(new Date().toISOString(), user.timezone), 'MM-dd-yyyy-HH.mm.s')}.csv`);
		} catch (e) {
			dispatch(postNotification(getMessage('CALLS_HISTORY_EXPORT_CSV_ERROR'), 'error'));
		}
	}, [filterState, websites, widgetTypes, dispatch, user.timezone]);

	useEffect(() => {
		setLoadingState(TABLE_STATE.loading);

		async function getCallsData() {
			try {
				if (new Date(filterState.dateFrom).valueOf() > new Date(filterState.dateTo).valueOf()) {
					setLoadingState(TABLE_STATE.dateFilterError);
				} else {
					const { dateFrom, dateTo } = filterState;
					const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}/client/calls`, {
						params: {
							page,
							perPage: defaultValues.CALLS_PER_PAGE,
							widgetTypeId: widgetTypes?.find(widgetType => widgetType?.name === filterState?.widgetType)?.id,
							websiteId: websites?.find(website => website?.domain === filterState?.Websites)?.id,
							dateFrom: new Date(dateFrom),
							dateTo: new Date(dateTo),
							status: (filterState?.Status && filterState?.Status === 'All') ? undefined : filterState?.Status?.toLowerCase(),
							type: (filterState?.callType && filterState?.callType === 'All') ? undefined : filterState?.callType?.toLowerCase(),
						},
					});
					setCallsData(data);

					if (!checkFilterState(filterState) && data.rows.length < 1) {
						setLoadingState(TABLE_STATE.noDataWithActiveFilters);
					} else if (checkFilterState(filterState) && data.rows.length < 1) {
						setLoadingState(TABLE_STATE.noData);
					} else {
						setLoadingState(TABLE_STATE.dataLoaded); // not really used
					}
				}
			} catch (error) {
				dispatch(postNotification(getMessage('CALLS_HISTORY_GET_CALLS_ERROR'), 'error'));
			}
		}

		getCallsData();
	}, [page, filterState, websites, widgetTypes, dispatch]);

	useEffect(() => {
		if (callsData && callsData.rows) {
			setTableData(callsData?.rows);
		}

		if (callsData?.count <= defaultValues.CALLS_PER_PAGE) setPage(1);
	}, [callsData]);

	useEffect(() => {
		const handleDownloadRecording = (url) => {
			Axios.get(url, { responseType: 'blob' }).then((response) => {
				const responseUrl = new URL(response.config.url);
				const filename = `${responseUrl.pathname}.wav`;
				downloadFile(response.data, filename);
			}).catch((error) => {
				console.log(error);
			});
		};

		const findAgentNumbers = call => call.TwilioCalls
			?.filter(tc => (
				call.agentId
					? tc.target === TWILIO_CALL.TARGETS.AGENT && tc.agentId === call.agentId
					: tc.target === TWILIO_CALL.TARGETS.AGENT
			))
			.map(tc => ({
				id: tc.id,
				phone: tc.metadata.to,
			}));

		const newTableData = {
			isLoading: loadingState,
			columnHeaderClasses: classes.columnHeaders,
			columnHeader: [
				'Call ID',
				'Recording',
				'Duration',
				'Website',
				'Call request URL',
				'Type',
				'Widget Type',
				'Widget Name',
				'Date/Time',
				'Customer Number',
				'Called from',
				'Agent Number',
				'Status',
				'Custom Field #1',
				'Custom Field #2',
				'Custom Field #3',
			],
			rows: tableData?.map((call) => {
				const foundWidgetTypeId = widgets?.find(widget => widget?.id === call?.widgetId)?.typeId;
				const foundWidgetName = widgets?.find(widget => widget?.id === call?.widgetId)?.name;
				const foundWidgetTypeName = widgetTypes?.find(widgetType => widgetType?.id === foundWidgetTypeId)?.name;
				const foundAgentNumbers = findAgentNumbers(call);
				return ({
					id: call.id,
					// rowCellsClasses: classes.rowCells,
					cells: [
						{
							id: call.convertId,
							value: call.convertId,
							itemToRender: call.convertId,
							classes: classes.callId,
						},
						{
							id: `recording ${call.id}`,
							value: '/',
							itemToRender: (
								<Box display="flex">
									<AudioPopup url={call?.CallRecording?.url} />
									<IconButton
										onClick={() => handleDownloadRecording(call?.CallRecording?.url)}
										className={classes.iconButtons}
										size="medium"
										color="primary"
										disabled={!call?.CallRecording?.url}
									>
										<GetAppRoundedIcon />
									</IconButton>
								</Box>
							),
							classes: classes.rowCells,
						},
						{
							id: `duration ${call.id}`,
							value: call.duration,
							itemToRender: new Date(call.duration * 1000).toISOString().substr(14, 5),
							classes: classes.rowCells,
						},
						{
							id: call.Website.domain,
							value: call.Website.domain,
							itemToRender: call.Website.domain,
							classes: classes.rowCells,
						},
						{
							id: call.url,
							value: call.url,
							itemToRender: call.url ? <a href={call.url} target="_blank" rel="noreferrer" title={call.url}>{call.url}</a> : '',
							classes: [classes.rowCells, classes.ellipsis],
						},
						{
							id: call.type,
							value: call.type,
							itemToRender: capitalize(call.type),
							classes: classes.rowCells,
						},
						{
							id: call.widgetId,
							value: call.widgetId,
							itemToRender: <ColoredPaper value={foundWidgetTypeName} />,
							classes: classes.rowCells,
						},
						{
							id: `widgetName ${call.id} ${foundWidgetName}`,
							value: foundWidgetName,
							itemToRender: foundWidgetName,
							classes: classes.rowCells,
						},
						{
							id: call.createdAt,
							value: call.createdAt,
							itemToRender: format(convertUTCToTimezone(call.createdAt, user.timezone), 'MM/dd/yyyy - hh:mm a'),
							classes: classes.rowCells,
						},
						{
							id: call.customerNumber,
							value: call.customerNumber,
							itemToRender: call.customerNumber,
							classes: classes.rowCells,
						},
						{
							id: call.geolocation,
							value: formatGeolocation(call.geolocation),
							itemToRender: formatGeolocation(call.geolocation),
							classes: classes.rowCells,
						},
						{
							id: `callId ${call.id} agentId ${call?.agentId}`,
							value: foundAgentNumbers,
							itemToRender: foundAgentNumbers?.map(({ id, phone }) => (<div key={`${id}`}>{phone}</div>)),
							classes: classes.rowCells,
						},
						{
							id: `status ${call.id}`,
							value: call.status,
							itemToRender: <ColoredPaper callsPage value={call.status} />,
							classes: classes.rowCells,
						},
						{
							id: `customField #1 ${call.id} ${foundWidgetName}`,
							value: call.ScheduledCall ? (call.ScheduledCall.customFields.customField1 || '/') : '/',
							itemToRender: call.ScheduledCall ? (call.ScheduledCall.customFields.customField1 || '/') : '/',
							classes: classes.rowCells,
						},
						{
							id: `customField #2 ${call.id} ${foundWidgetName}`,
							value: call.ScheduledCall ? (call.ScheduledCall.customFields.customField2 || '/') : '/',
							itemToRender: call.ScheduledCall ? (call.ScheduledCall.customFields.customField2 || '/') : '/',
							classes: classes.rowCells,
						},
						{
							id: `customField #3 ${call.id} ${foundWidgetName}`,
							value: call.ScheduledCall ? (call.ScheduledCall.customFields.customField3 || '/') : '/',
							itemToRender: call.ScheduledCall ? (call.ScheduledCall.customFields.customField3 || '/') : '/',
							classes: classes.rowCells,
						},
					],
				});
			}),
		};

		setDataToRender(newTableData);
	}, [callsData, tableData, widgets, widgetTypes, classes, agents, loadingState, user.timezone, dispatch]);

	return (
		<>
			<Box display="flex" p={0} my={2}>
				<Box width="100%">
					<p className={classes.h4}>Calls History</p>
					<p className={classes.h6}>Inbounds calls from clients.</p>
				</Box>
			</Box>
			<TableFilter
				setFilterState={setFilterState}
				filterItems={[
					{
						id: 'dateFrom',
						type: 'date',
						label: 'Date from',
						disableFuture: true,
						defaultValue: returnFilterDateTime(defaultValues.DATE_FROM, 'from'),
					},
					{
						id: 'dateTo',
						type: 'date',
						label: 'Date to',
						disableFuture: true,
						defaultValue: returnFilterDateTime(new Date(), 'to'),
					},
					{
						id: 'widgetType',
						type: 'select',
						label: 'Widget type',
						options: widgetTypes.map(type => type.name),
						emptyValue: 'All',
					},
					{
						id: 'Websites',
						type: 'select',
						label: 'Websites',
						emptyValue: 'All',
						options: websites.map(website => website.domain),
					},
					{
						id: 'callType',
						type: 'select',
						label: 'Call Type',
						emptyValue: 'All',
						options: defaultValues.CALL_TYPES.map(type => capitalize(type)),
					},
					{
						id: 'Status',
						type: 'select',
						label: 'Status',
						emptyValue: 'All',
						options: defaultValues.CALL_STATUSES.map(status => capitalize(status)),
					},
				]}
			/>
			<Box className={classes.contentWrapper}>
				<TableGenerator tableData={dataToRender} />
			</Box>
			{tableData.length > 0 && (dataToRender?.isLoading === 'data-loaded') && (
				<Box className={classes.contentWrapper}>
					<PrimaryButton onClick={handleDownloadCSV}>Export as CSV</PrimaryButton>
				</Box>
			)}
			{tableData.length > 0 && (
				<Box className={classes.contentWrapper}>
					<Box display="flex" justifyContent="center">
						<Pagination
							defaultPage={1}
							page={page}
							onChange={handlePageChange}
							shape="rounded"
							count={Math.ceil(callsData?.count / defaultValues.CALLS_PER_PAGE)}
						/>
					</Box>
				</Box>
			)}
		</>
	);
};

export default Calls;
