export const GET_METADATA = 'GET_METADATA';
export const UPDATE_META_DATA = 'UPDATE_META_DATA';
export const UPDATE_CREDITS = 'UPDATE_CREDITS';
export const UPDATE_AUTO_RECHARGE_INFORMATION = 'UPDATE_AUTO_RECHARGE_INFORMATION';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const RESET_META_DATA = 'RESET_META_DATA';

export type MetaDataAutoRechargeSettings = {
	packId: string
	criticalCredits: number
	paymentMethodId: string
}

export type MetaDataCredit = Partial<{
	id: string
	numericId: string
	credits: number
	autoRecharge: MetaDataAutoRechargeSettings
	autoRechargeLock: boolean
	overdraft: number
	hasNotifiedOverdraftReached: boolean
	hasNotifiedNegativeBalance: boolean
	minMonthlySpendingRequirement: number
	billingCycleDate: Date
	createdAt: string
	updatedAt: string
	deletedAt: null
}>

export type MetaDataCreditPack = {
	id: string
	numericId: string
	name: string
	description: null | string
	credits: number
	price: number
	isGlobal: boolean
	isActive: boolean
	createdAt: string
	updatedAt: string
	deletedAt: null
}

export type MetaDataWidgetLayout = {
	id: string
	name: string
}

export type MetaDataWidgetType = {
	id: string
	name: string
	description: string
}

export type MetaDataCallingRule = {
	id: string
	name: string
}

export type MetaDataSettings = {
	id: string
	numericId: string
	userId: string
	type: 'billing'
	settings: {
		criticalCredits: string
		paymentMethodId: string
		packId: string
	}
	createdAt: string
	updatedAt: string
	deletedAt: null
}

export type MetaDataCallStatus = 'busy' | 'missed' | 'successful' | 'no-answer'

export type MetaDataPaymentStatus = 'successful' | 'unsuccessful' | 'canceled' | 'failed' | 'on-hold'

export type MetaDataTypes = Partial<{
    twilioNumbers: [];
	websites: [],
	credits: MetaDataCredit[],
	settings: MetaDataSettings[],
	creditPacks: MetaDataCreditPack[],
	widgetLayouts: MetaDataWidgetLayout[],
	widgetTypes: MetaDataWidgetType[],
	callingRules: MetaDataCallingRule[],
	callStatuses: MetaDataCallStatus[],
	paymentStatuses: MetaDataPaymentStatus[],
}>

// Action types
export interface MetaDataUpdateCreditsAmountAction {
	type: typeof UPDATE_CREDITS
	payload: MetaDataCredit
}

export interface MetaDataUpdateMetaDataAction {
	type: typeof UPDATE_META_DATA
	payload: MetaDataTypes
	meta: {
		errorMsg: string
	}
}

export interface MetaDataUpdateSettingsAction {
	type: typeof UPDATE_SETTINGS
	payload: MetaDataSettings
	meta: {
		errorMsg: string
	}
}

export interface MetaDataUpdateAutoRechargeSettingsActon {
	type: typeof UPDATE_AUTO_RECHARGE_INFORMATION
	payload: MetaDataAutoRechargeSettings
}

export type MetaDataActionTypes = MetaDataUpdateMetaDataAction | MetaDataUpdateCreditsAmountAction | MetaDataUpdateSettingsAction | MetaDataUpdateAutoRechargeSettingsActon;
