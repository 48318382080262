/* eslint-disable react/jsx-no-bind */
import React, { useRef, useState } from 'react';

// Components
import { TextField } from '@material-ui/core';

// Utilities
import { generateSnippet } from '../../lib/code';

const Code: React.FC<{ websiteId: string }> = (props) => {
	const {
		websiteId,
	} = props;

	const [copySuccess, setCopySuccess] = useState(' ');
	const codeText = useRef(null);

	function copyToClipboard(event) {
		codeText.current.select();
		document.execCommand('copy');
		event.target.focus();
		setCopySuccess('Copied!');
	}

	return (
		<TextField
			id="copyCode"
			label="Copy this code"
			multiline
			defaultValue={generateSnippet(websiteId)}
			variant="outlined"
			helperText={copySuccess}
			onClick={copyToClipboard}
			inputProps={{
				ref: codeText,
				readOnly: true,
			}}
			style={{
				width: '100%',
				cursor: 'pointer',
			}}
		/>
	);
};

export default Code;
