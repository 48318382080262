import React, { useState } from 'react';
import {
	DialogContent, DialogContentText, DialogTitle, Grid, Dialog,
} from '@material-ui/core';
import SecondaryButton from '../_actions/SecondaryButton';

type DialogProps = {
	isOpen: boolean,
	handleClose: () => void,
	handleAccept: () => void,
	title: string,
	description: string,
}

const PromptDialog: React.FC<DialogProps> = React.memo((props) => {
	const {
		isOpen,
		handleClose,
		handleAccept,
		title,
		description,
	} = props;
	const [isLoading, setIsLoading] = useState(false);

	return (
		<Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle style={{ textAlign: 'center' }} id="form-dialog-title">
				<b>
					{title}
				</b>
			</DialogTitle>
			<DialogContent>
				<DialogContentText
					style={{ fontSize: '16px', textAlign: 'center' }}
				>
					{description}
				</DialogContentText>
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
				>
					<br />
					<Grid item>
						<SecondaryButton
							variantColor="orange"
							onClick={async () => {
								setIsLoading(true);
								if (!isLoading) {
									await handleAccept();
									setIsLoading(false);
									handleClose();
								}
							}}
							disabled={isLoading}
						>
							Yes, I am sure.
						</SecondaryButton>
					</Grid>
					<br />
					<Grid item>
						<SecondaryButton
							variantColor="black"
							onClick={handleClose}
						>
							Cancel
						</SecondaryButton>
					</Grid>
					<br />
				</Grid>
			</DialogContent>
		</Dialog>
	);
});

export default PromptDialog;
