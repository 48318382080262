// @ts-nocheck
import axios from 'axios';
import moment from 'moment-timezone';

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/oauth2/token`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/user/register`;
export const GET_USER_URL = `${process.env.REACT_APP_API_URL}/user`;
export const PASSWORD_RELATED_URL = `${process.env.REACT_APP_API_URL}/user/password`;

export function login(formData) {
	return axios.post(LOGIN_URL, formData);
}

export function logout() {
	return axios.delete(LOGIN_URL);
}

export function register(fullname, email, phone, password, token, metadata = undefined) {
	// Using moment here because it has better support for browsers that don't support Intl.DateTimeFormat().resolvedOptions().timeZone
	// Dayjs is similar: dayjs.tz.guess(); (must be included from lib/dayjs.js)
	return axios.post(REGISTER_URL, {
		name: fullname,
		email,
		phone,
		password,
		timezone: moment.tz.guess(),
		token,
		metadata,
	});
}

export function forgotPassword(email) {
	return axios.put(PASSWORD_RELATED_URL, { email });
}

export function createNewPassword(verificationCode, password, isForgotPassword) {
	return axios.post(`${PASSWORD_RELATED_URL}/${verificationCode}`, { password, isForgotPassword });
}

export function getUser() {
	return axios.get(GET_USER_URL);
}

export function changePassword(currentPassword, newPassword, invalidateSessions) {
	return axios.patch(PASSWORD_RELATED_URL, { currentPassword, newPassword, invalidateSessions });
}
