// @ts-nocheck
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { login as reduxLogin } from '../_redux/authCrud';
import TextFieldInput from '../../../components/_inputs/TextFieldInput';
import {
	Box,
} from '@material-ui/core';
import SecondaryButton from '../../../components/_actions/SecondaryButton';
import { flushNotification } from '../../../../redux/notifications/actions';
import { getMessage } from '../../../constants/messages';
// import { stubTrue } from "lodash";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
	email: '',
	password: '',
};

function Login(props) {
	const { intl, login } = props;
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email(getMessage('WRONG_EMAIL_FORMAT'))
			.min(3, getMessage('MIN_FIELD_LENGTH', { length: 3 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
		password: Yup.string()
			.min(5, getMessage('MIN_FIELD_LENGTH', { length: 5 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
	});

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const formik = useFormik({
		initialValues,
		validationSchema: LoginSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			enableLoading();
			setTimeout(async () => {
				const params = new URLSearchParams();
				params.append('username', values.email.toLowerCase());
				params.append('password', values.password);
				params.append('grant_type', 'password');
				params.append('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID);
				try {
					dispatch(flushNotification());
					const { data } = await reduxLogin(params);
					disableLoading();
					login(data.accessToken, data.refreshToken);
					setStatus(null);
				} catch (error) {
					disableLoading();
					setSubmitting(false);
					if (error.response && error.response.status === 401) {
						const { description } = error.response.data.error;
						switch (description) {
							case 'inactive_account':
								setStatus(
									intl.formatMessage({
										id: 'AUTH.VALIDATION.INACTIVE_ACCOUNT',
									})
								);
								break;
							default:
								setStatus(
									intl.formatMessage({
										id: 'AUTH.VALIDATION.INVALID_LOGIN',
									})
								);
						}
					} else {
						setStatus(
							intl.formatMessage({
								id: 'AUTH.GENERAL.ERROR',
							})
						);
					}
				}
			}, 1000);
		},
	});

	return (
		<div className="login-form login-signin" id="kt_login_signin_form">
			{/* begin::Head */}
			<div className="text-center mb-10 mb-lg-20">
				<h3 className="font-size-h1">
					<FormattedMessage id="AUTH.LOGIN.TITLE" />
				</h3>
				<p className="text-muted font-weight-bold">
					Enter your username and password
				</p>
			</div>
			{/* end::Head */}

			{/* begin::Form */}
			<form
				onSubmit={formik.handleSubmit}
				className="form fv-plugins-bootstrap fv-plugins-framework"
			>
				{/* begin: Alert */}
				{formik.status && (
					<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
						<div className="alert-text font-weight-bold">{formik.status}</div>
					</div>
				)}
				{/* end: Alert */}
				<Box>
					<TextFieldInput
						fullWidth
						label="Email"
						name="email"
						{...formik.getFieldProps('email')}
						error={(formik.touched.email && !!formik.errors.email) || !!formik.status}
						helperText={(formik.touched.email && formik.errors.email) || ' '}
					/>
					<TextFieldInput
						fullWidth
						label="Password"
						name="password"
						type="password"
						{...formik.getFieldProps('password')}
						error={(formik.touched.password && !!formik.errors.password) || !!formik.status}
						helperText={(formik.touched.password && formik.errors.password) || ' '}
					/>
				</Box>
				<div className="form-group d-flex flex-wrap justify-content-between align-items-center">
					<Link
						to="/auth/forgot-password"
						href="/auth/forgot-password"
						className="text-dark-50 text-hover-primary my-3 mr-2"
						id="kt_login_forgot"
					>
						<FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
					</Link>
					<SecondaryButton
						variantColor="orange"
						type="submit"
						disabled={formik.isSubmitting}
					// className="font-weight-bold px-9 py-4 my-3" // btn btn-primary font-weight-bold
					>
						<span>Sign In</span>
						{loading && <span className="ml-3 spinner spinner-white" />}
					</SecondaryButton>
				</div>
			</form>
		</div>
	);
}

export default injectIntl(connect(null, auth.actions)(Login));
