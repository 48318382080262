import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './ThankYou.styles';

const ThankYou = () => {
	const classes = useStyles();
	return (
		<Typography
			className={classes.thankYouText}
		>
			Thank you for Signing up, please follow the link in your email to confirm registration.
		</Typography>
	);
};

export default ThankYou;
