import axios from 'axios';
import { MetaDataAutoRechargeSettings, MetaDataCredit } from './redux/metaData/metaDataTypes';
import { WidgetType } from './redux/widget/widgetTypes';
import { WebsiteType } from './redux/website/websiteTypes';
import { AgentType } from './redux/agents/agentTypes';
import URLS from './app/constants/urls';
import { EMAIL_VERIFICATION_TYPES } from './app/constants/verification';

const API = process.env.REACT_APP_API_URL;

// PAYMENT

/**
 * Get logged in active payment Method. Currently user can only have one active payment method.
 */
export async function getPaymentMethod(): Promise<null | Record<string, any>> {
	const {
		data,
	} = await axios.get(`${API}/client/payment-methods`);

	if (data.data && data.data.length > 0) {
		return data.data[0];
	}

	return null;
}

/**
 * Assign a stripe payment method id to the convertmore client
 * @param paymentMethodId
 */
export async function assignStripePaymentMethodIdToClient(paymentMethodId: string): Promise<Record<string, any>> {
	const {
		data,
	} = await axios.post(`${API}/client/payment-methods`, { paymentMethodId });

	return {
		id: data.paymentMethodId,
	};
}

export async function deletePaymentMethod(paymentMethodId: string): Promise<Record<'paymentMethodId', string>> {
	return axios.delete(`${API}/client/payment-methods`, {
		data: {
			paymentMethodId,
		},
	});
}

/**
 * Create payment intent for the specific package id using already defined payment method
 * @param packId
 */
export async function createPaymentIntent(packId: string): Promise<Record<string, any>> {
	const { data } = await axios.post(`${API}/client/payments`, {
		packId,
	});

	return data;
}

// Get credits information
/**
 * Get client credits information
 */
export async function getCredits(): Promise<MetaDataCredit> {
	const {
		data,
	} = await axios.get(`${API}/client/credits`);
	return data;
}

/**
 * Get client spent credits information
 */
export async function getSpentCredits(): Promise<number> {
	const {
		data,
	} = await axios.get(`${API}/client/credits/spent`);

	return data;
}

// AUTO RECHARGE

/**
 * Save client auto recharge status
 * @param autoRecharge
 */
export async function saveAutoRecharge(autoRecharge: MetaDataAutoRechargeSettings): Promise<MetaDataAutoRechargeSettings> {
	const {
		data,
	} = await axios.post(`${API}/client/auto-recharge`, autoRecharge);

	return data;
}

/**
 * Disable client auto recharge functionality
 */
export function disableAutoRecharge(): Promise<Record<'message', string>> {
	return axios.delete(`${API}/client/auto-recharge`);
}

/// WIDGETS

/**
 * Fetch all widgets
 */
export async function fetchWidgets(): Promise<WidgetType[]> {
	const {
		data,
	} = await axios.get(`${API}/client/widgets`);

	return data;
}

/**
 * Update widget by widget Id
 * @param id
 * @param widget
 */
export async function updateWidget(id: string, widget: Partial<WidgetType>): Promise<WidgetType> {
	const { data } = await axios.put(`${API}/client/widgets/${id}`, widget);
	return data;
}

/**
 * Create widget with widget Id
 * @param widget
 */
export async function createWidget(widget: Partial<WidgetType>): Promise<WidgetType> {
	const { data } = await axios.post(`${API}/client/widgets`, widget);
	return data;
}

/**
 * Update image on widget with id
 * @param id
 * @param imageUrl
 */
export async function updateWidgetImage(id: string, imageUrl: string): Promise<WidgetType> {
	const { data } = await axios.patch(`${API}/client/widgets/${id}/images`, { imageUrl });
	return data;
}

/**
 * Return script javascript
 */
export async function fetchWidgetScript() {
	const data = await fetch(process.env.REACT_APP_WIDGET_SCRIPT_URL, {
		method: 'GET', // *GET, POST, PUT, DELETE, etc.
		// mode: 'no-cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer-when-downgrade',
	});

	const exported = await data.text();

	return {
		data: exported,
	};
}

// Userfiles

/**
 * Get all user files
 */
export async function getUserFiles(): Promise<[]> {
	const { data } = await axios.get(`${API}/user/files`);
	return data;
}

// Websites

/**
 * Create a website in the database
 * @param domain
 */
export async function createWebsite(domain: string): Promise<WebsiteType> {
	const {
		data,
	} = await axios.post(`${API}/client/websites`, {
		domain,
	});

	return data;
}

// AGENTS

/**
 * Create new agent resource
 *
 * @param agent
 */
export async function createAgent(agent: AgentType): Promise<AgentType> {
	const {
		data,
	} = await axios.post(`${API}/client/agents`, agent);

	return data;
}

/**
 * Delete agent resource with id
 *
 * @param id
 */
export async function deleteAgent(id: string) {
	const {
		data,
	} = await axios.delete(`${API}/client/agents/${id}`);

	return data;
}

/**
 * Update activity for agent
 * @param id
 * @param agentData
 */
export async function updateAgent(id: string, agentData: AgentType): Promise<AgentType> {
	// Not sure if needed.
	if (agentData.id) {
		delete agentData.id;
	}
	const {
		data,
	} = await axios.put(`${API}/client/agents/${id}`, agentData);

	return data;
}

/**
 *
 * @param dateFrom
 * @param dateTo
 * @param typeId
 * @param websiteId
 */
export async function fetchAnalyticsData(dateFrom: Date, dateTo: Date, typeId: string, websiteId: string | undefined) : Promise<any> {
	const { data } = await axios.get(`${API}${URLS.ANALYTICS}`, {
		params: {
			dateFrom,
			dateTo,
			typeId,
			websiteId,
		},
	});

	if (!websiteId) {
		const mergedWebsites = [];

		data.forEach((chunk) => {
			if (chunk.date in mergedWebsites) {
				mergedWebsites[chunk.date].usage.pageViews += chunk.usage.pageViews;
				mergedWebsites[chunk.date].usage.widgets = mergedWebsites[chunk.date].usage.widgets.concat(chunk.usage.widgets);
			} else {
				mergedWebsites[chunk.date] = chunk;
			}
		});

		return Object.values(mergedWebsites);
	}

	return data;
}

/**
 *
 * @param verificationCode
 * @param verificationType
 */
export async function verifyEmail(verificationCode, verificationType) {
	const url = verificationType === EMAIL_VERIFICATION_TYPES.CHANGE_EMAIL
		? `${API}${URLS.VERIFY_CHANGE_EMAIL}/${verificationCode}`
		: `${API}${URLS.VERIFY_EMAIL_AFTER_REGISTER}/${verificationCode}`;

	return axios.post(url, { client_id: process.env.REACT_APP_OAUTH_CLIENT_ID });
}
