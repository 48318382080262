import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ErrorPage1 } from './ErrorPage1';

export default function ErrorsPage() {
	return (
		<Switch>
			<Route path="/error" component={ErrorPage1} />
		</Switch>
	);
}
