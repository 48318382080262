// @ts-nocheck
import React, { useState } from 'react';
import { Checkbox, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

// Components
import TextFieldInput from './TextFieldInput';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
	text: {
		marginBottom: '8px',
		minWidth: '180px',
		width: '300px',
		maxWidth: '100%',
	},
});

export default function AutocompleteMultiple(props) {
	const {
		options, initialValue, label, textInputProps, setFormikValue,
	} = props;
	const classes = useStyles();
	const [value, setValue] = useState(initialValue || []);
	const [inputValue, setInputValue] = useState('');

	return (
		<Autocomplete
			multiple
			id={label}
			className={classes.text}
			options={options}
			value={value}
			disableCloseOnSelect
			onChange={(_, newValue) => {
				setValue(newValue);
				setFormikValue(newValue);
			}}
			inputValue={inputValue}
			onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
			disableClearable
			renderOption={(option, { selected }) => (
				<>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{option}
				</>
			)}
			renderInput={params => <TextFieldInput {...params} {...textInputProps} />}
		/>
	);
}
