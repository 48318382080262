import React from 'react';
import {
	CircularProgress,
	makeStyles, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { TABLE_STATE } from '../../constants/general';

const StyledTableCell = withStyles(theme => ({
	...theme.styledTableDesign.cells,
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
	root: {
		...theme.styledTableDesign.rows,
	},
}))(TableRow);

const useStyles = makeStyles({
	noDataParagraph: {
		textAlign: 'center',
	},
});

function TableGenerator({ tableData }) {
	const classes = useStyles();

	if (tableData?.isLoading === TABLE_STATE.loading) {
		return (
			<div className="d-flex justify-content-center">
				<CircularProgress size="3rem" color="primary" role="status" />
			</div>
		);
	}
	// TODO: This can be done better
	if (tableData?.isLoading === TABLE_STATE.noData) {
		return (
			<div className="d-flex justify-content-center">
				<h4 className={classes.noDataParagraph}>No data to show.</h4>
			</div>
		);
	}

	if (tableData?.isLoading === TABLE_STATE.noDataWithActiveFilters) {
		return (
			<div className="d-flex justify-content-center">
				<h4 className={classes.noDataParagraph}>No data to show for selected filters.</h4>
			</div>
		);
	}

	if (tableData?.isLoading === TABLE_STATE.dateFilterError) {
		return (
			<div className="d-flex justify-content-center">
				<h4 className={classes.noDataParagraph}>Date from cannot be bigger than date to.</h4>
			</div>
		);
	}

	if (tableData?.rows?.length > 0) {
		return (
			<Table>
				<TableHead>
					<TableRow>
						{tableData?.columnHeader?.map(row => (
							<StyledTableCell className={tableData?.columnHeaderClasses} key={row}>{row}</StyledTableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{tableData?.rows?.map(row => (
						<StyledTableRow key={row.id}>
							{row.cells.map(cell =>
								<TableCell className={cell?.classes} key={cell.id}>{cell.itemToRender}</TableCell>)}
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		);
	}

	return null;
}

export default TableGenerator;
