import React, {
	useImperativeHandle,
} from 'react';
import { useFormik } from 'formik';
import { Box, Grid, Switch } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// types
import { ActiveHoursType } from '../../../typecheckings';

// Constants
import { DEFAULT_TIMES } from '../../constants/weekObject';
import { availabilityValidation } from '../../constants/validations';

import Picker from './Picker';

type WeeklyPickersType = {
	activeHours: ActiveHoursType[]
}

const WeeklyPickers = React.memo(React.forwardRef<any, WeeklyPickersType>((props, ref) => {
	const {
		activeHours,
	} = props;

	const formik = useFormik({
		validateOnChange: true,
		validateOnMount: false,
		initialValues: {
			activeHours,
		},
		validationSchema: availabilityValidation,
		onSubmit: () => { // eslint-disable-line
		},
	});

	useImperativeHandle(
		ref,
		() => ({
			validate() {
				return formik.validateForm();
			},
			values() {
				const data = formik.getFieldProps('activeHours').value;

				// Clear inactive rows
				return data.reduce((accumulator, value) => {
					// Restore balance in the world
					if (value.times.length === 0) {
						value.isActive = false;
						value.times = DEFAULT_TIMES;
					}
					accumulator.push(value);
					return accumulator;
				}, []);
			},
		})
	);

	return (
		<div>
			{Object.keys(formik.errors).length > 0 && (
				<Box mb={2}>
					<Alert severity="error">At least one day must be selected and every selected day must have valid start/end time</Alert>
				</Box>
			)}
			<Box>
				{formik.getFieldProps('activeHours').value.map((value, index) => {
					return (
						<Box pt={1} key={value.day}>
							<Grid container item key={`activeHours${value.day}`} xs={12}>
								<Grid item xs={12} sm={2}>
									<Switch
										{...formik.getFieldProps(`activeHours[${index}].isActive`)}
										checked={value.isActive}
										color="primary"
										inputProps={{ 'aria-label': 'Enable call day' }}
									/>
									<span>{value.day.slice(0, 3)}</span>
								</Grid>
								<Grid item xs={12} sm>
									<Box>
										<Picker
											isActive={value.isActive}
											day={index}
											times={formik.getFieldProps(`activeHours[${index}].times[0]`).value}
											onSuccess={(times, fieldIndex) => {
												formik.setFieldValue(`activeHours[${fieldIndex}].times`, times);
											}}
										/>
									</Box>
								</Grid>
							</Grid>
						</Box>
					);
				})}
			</Box>
		</div>
	);
}));

export default WeeklyPickers;
