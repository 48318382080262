import React, { useEffect, useState } from 'react';
import { isFirefox } from 'react-device-detect';
import { Grid } from '@material-ui/core';
import { runWidgetScript, updateWidgetSettings } from '../../../../../lib/helpers';

type WidgetPreviewType = {
	globalSetup: Record<string, any>
	widgetScript: string
	title: string
	iframeId: string
	value: Record<string, any>
	valueType: 'callSetup' | 'scheduleSetup' | 'floatingIconSetup'
	isCall?: boolean
	isFloatingIcon?: boolean
}

const WidgetPreview: React.FC<WidgetPreviewType> = React.memo((props) => {
	const {
		title,
		iframeId,
		value,
		isCall = false,
		isFloatingIcon = false,
		valueType,
		globalSetup,
		widgetScript,
	} = props;

	const [isSet, setIsSet] = useState(false);
	const [isRendered, setIsRendered] = useState(false);

	const onIframeLoad = () => {
		if (isFirefox) {
			// Quick and dirty fix for firefox issues
			runWidgetScript(
				widgetScript,
				isCall,
				isFloatingIcon,
				iframeId,
				{
					[valueType]: value,
					globalSetup,
				}
			);
			setIsSet(true);
		}
	};

	// Run inject script
	useEffect(() => {
		runWidgetScript(
			widgetScript,
			isCall,
			isFloatingIcon,
			iframeId,
			{
				[valueType]: value,
				globalSetup,
			}
		);
		setIsSet(true);
	}, [widgetScript]);

	useEffect(() => {
		if (isSet && isRendered) {
			updateWidgetSettings(iframeId, {
				[valueType]: value,
				globalSetup,
			}, isCall, isFloatingIcon);
		} else {
			setIsRendered(true);
		}
	}, [value, globalSetup, isSet]);

	return (
		<Grid
			item
			style={{
				flexGrow: 1,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<iframe
				onLoad={onIframeLoad}
				style={{
					border: 'none',
					width: '100%',
					overflow: 'hidden',
				}}
				title={title}
				id={iframeId}
			/>
		</Grid>
	);
});

export default WidgetPreview;
