// @ts-nocheck
import React from 'react';

// Components
import { makeStyles, Button, useTheme } from '@material-ui/core';

function SecondaryButton(props) {
	const {
		variantColor, margin = 0, padding = 1, ...restOfProps
	} = props;
	const theme = useTheme();
	const useStyles = makeStyles({
		button: {
			padding: theme.spacing(padding),
			margin: theme.spacing(margin),
			[theme.breakpoints.down('sm')]: {
				...theme.typography.buttons.secondary.sm,
			},
			[theme.breakpoints.up('md')]: {
				...theme.typography.buttons.secondary.md,
			},
			color: variantColor === 'orange' ? theme.palette.convertmoreOrange.white : theme.palette.convertmoreBlack.black,
			backgroundColor: variantColor === 'orange' ? theme.palette.convertmoreOrange.main : theme.palette.convertmoreBlack.main,
			borderColor: variantColor === 'black' && theme.palette.convertmoreBlack.borderColor,
			'&:hover': {
				backgroundColor: variantColor === 'orange' ? theme.palette.convertmoreOrange.hover : theme.palette.convertmoreBlack.hover,
			},
		},
	});
	const classes = useStyles();

	return (
		<Button
			variant={variantColor === 'orange' ? 'contained' : 'outlined'}
			size="medium"
			disableElevation
			className={`${classes.button}`} // {`${classes.button} ${props.type === 'time' && classes.time} ${!props.fullWidth && classes.setWidth}
			{...restOfProps}
		/>
	);
}

export default SecondaryButton;
