/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Notification from '../../../../../app/components/_actions/Notification';

const useStyles = makeStyles(theme => ({
	homeLink: {
		...theme.typography.breadcrumbs,
		color: theme.palette.breadcrumbs.grey,
		'&:hover': {
			color: theme.palette.breadcrumbs.black,
		},
	},
	breadcrumbLinks: {
		...theme.typography.breadcrumbs,
		color: theme.palette.breadcrumbs.black,
	},
}));

export function BreadCrumbs({ items }) {
	const theme = useTheme();
	const classes = useStyles(theme);
	const [showNotification, setShowNotification] = useState(false);
	const notification = useSelector(state => state.notifications);

	useEffect(() => {
		if (notification.message !== '') setShowNotification(true);

		return () => {
			setShowNotification(false);
		};
	}, [notification.message]);

	if (!items || !items.length) {
		return '';
	}

	return (
		<Grid
			container
			direction="row"
			justify="flex-start"
			alignItems="flex-start"
		>
			<Grid item>
				<ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
					<li className="breadcrumb-item" style={{
						display: 'flex'
					}}>
						<Link to="/dashboard">
							{/* <i className="flaticon2-shelter text-muted icon-1x" /> */}
							<span className={classes.homeLink}>Home</span>
						</Link>
					</li>
					{items.map((item, index) => (
						<li key={`bc${index}`} className="breadcrumb-item" style={{
							display: 'flex'
						}}>
							<Link className={classes.breadcrumbLinks} to={{ pathname: item.pathname }}>
								{item.title}
							</Link>
						</li>
					))}
				</ul>
			</Grid>
			<Grid item xs={12} sm={false} md={false} />
			{ showNotification && <Notification open={showNotification} message={notification.message} type={notification?.type} duration={5000} /> }
		</Grid>
	);
}
