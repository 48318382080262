import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import defaultValues from '../../constants/defaultValues';

// API
import { fetchAnalyticsData } from '../../../api';

// Constants
import { getMessage } from '../../constants/messages';

// Material
import {
	Grid, Box, CircularProgress,
} from '@material-ui/core';

// Redux
import { RootStateType } from '../../../redux/rootReducer';
import { postNotification } from '../../../redux/notifications/actions';

// Components
import LineChart from '../../components/Analytics/LineChart';
import DataGrid from '../../components/Analytics/DataGrid';
import { TableFilter } from '../../components/TableBuilder/TableFilter';
import { Link } from 'react-router-dom';
import SecondaryButton from '../../components/_actions/SecondaryButton';
import CountsBox from '../../components/Analytics/CountsBox';

// Style
import useStyles from './DashboardPage.styles';
import { returnFilterDateTime } from '../../lib/timezoneHelpers';
import { TABLE_STATE } from '../../constants/general';

const DashboardPage = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const widgetTypes = useSelector((state: RootStateType) => state.metaData.widgetTypes);
	const websites = useSelector((state: RootStateType) => state.website);

	const [filterState, setFilterState] = useState({
		dateFrom: undefined,
		dateTo: undefined,
		website: undefined,
		widgetType: undefined,
	});

	const [analyticsData, setAnalyticsData] = useState(null);
	const [firstWebsiteState] = useState(null);

	const [cardStatistic, setCardStatistic] = useState(null);
	const [loadingState, setLoadingState] = useState(TABLE_STATE.loading);

	useEffect(() => {
		let cancel = false;
		const getAnalytics = async (from: Date, to: Date, websiteId: string, typeId: string) => {
			// checking for empty states, filtered empty states and setting up loaders
			if (new Date(filterState.dateFrom) > new Date(filterState.dateTo)) {
				setLoadingState(TABLE_STATE.dateFilterError);
			} else if (websites.websites.length > 0) {
				try {
					const data = await fetchAnalyticsData(from, to, typeId, websiteId);

					if (cancel) {
						return;
					}

					setAnalyticsData(data);
					setLoadingState((
						data.length < 1
							? TABLE_STATE.noDataWithActiveFilters
							: TABLE_STATE.dataLoaded
					));
				} catch (error) {
					dispatch(postNotification(getMessage('ANALYTICS_GET_DATA_ERROR'), 'error'));
				}
			} else {
				setLoadingState(TABLE_STATE.noData);
			}
		};

		if (!websites.loading) {
			const websiteId = websites.websites?.find((website: { domain: string; }) => website.domain === filterState?.website)?.id;
			const typeId = widgetTypes.find((widget: { name: string }) => widget.name === filterState.widgetType)?.id;
			getAnalytics(filterState.dateFrom, filterState.dateTo, websiteId, typeId);
		}

		return () => {
			cancel = !firstWebsiteState;
		};
	}, [filterState, firstWebsiteState, widgetTypes, websites.websites, websites.loading, dispatch]);

	return (
		<>
			<Box display="flex" p={0} my={2}>
				<Box width="100%">
					<p className={classes.h4}>Analytics</p>
					<p className={classes.h6}>Your Conversion Statistics</p>
				</Box>
			</Box>
			{loadingState === TABLE_STATE.loading && (
				<div className="d-flex justify-content-center mb-8">
					<CircularProgress size="3rem" color="primary" role="status" />
				</div>
			)}
			{loadingState === TABLE_STATE.noData && (
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
				>
					<Grid item>
						<h4 className={classes.noDataParagraph}>You don’t have any statistic data to present, yet.</h4>
					</Grid>
					<br />
					<Grid item>
						<Grid container justify="center" spacing={2}>
							<Grid item>
								<Link to="/widgets/manage/general" href="/widgets/manage/general">
									<SecondaryButton
										variantColor="orange"
									>
										Create New Widget
									</SecondaryButton>
								</Link>
							</Grid>
							<Grid item>
								<Link to="/agents/manage" href="/agents/manage">
									<SecondaryButton
										variantColor="black"
									>
										Create New Agent
									</SecondaryButton>
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			{websites.websites.length > 0 && (
				<>
					<TableFilter
						setFilterState={setFilterState}
						filterItems={[
							{
								id: 'dateFrom',
								type: 'date',
								label: 'Date from',
								disableFuture: true,
								defaultValue: returnFilterDateTime(defaultValues.DATE_FROM, 'from'),
							},
							{
								id: 'dateTo',
								type: 'date',
								label: 'Date to',
								disableFuture: true,
								defaultValue: returnFilterDateTime(new Date(), 'to'),
							},
							{
								id: 'website',
								type: 'select',
								label: 'Websites',
								options: websites.websites.map((website: { domain: string; }) => website.domain),
								emptyValue: 'All',
							},
							{
								id: 'widgetType',
								type: 'select',
								label: 'Widget type',
								emptyValue: 'All',
								options: widgetTypes.map(type => type.name),
							},
						]}
					/>
					{loadingState === TABLE_STATE.noDataWithActiveFilters && (
						<div className="d-flex justify-content-center mt-8">
							<h4 className={classes.noDataParagraph}>No data to show for selected filters</h4>
						</div>
					)}
					{loadingState === TABLE_STATE.dateFilterError && (
						<div className="d-flex justify-content-center mt-8">
							<h4 className={classes.noDataParagraph}>Date from cannot be bigger than date to.</h4>
						</div>
					)}
					{loadingState === TABLE_STATE.dataLoaded && (
						<>
							<CountsBox
								classes={classes}
								data={cardStatistic}
							/>
							<LineChart
								analyticsData={analyticsData}
								from={filterState.dateFrom}
								to={filterState.dateTo}
							/>
							<DataGrid
								setCardStatistic={setCardStatistic}
								analyticsData={analyticsData}
								widgetTypes={widgetTypes}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

export default DashboardPage;
