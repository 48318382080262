import * as Yup from 'yup';
import { AgentType } from '../../redux/agents/agentTypes';

import { PATTERNS } from './regex';
import inputErrors from './inputErrors';
import weekObjects from './weekObject';
import { getMessage } from './messages';

export const DEFAULT_AGENT_STATE: Partial<AgentType> = {
	name: '',
	phone: '',
	availability: {
		activeHours: weekObjects,
	},
};

export const addEditAgentSchema = Yup.object().shape({
	name: Yup.string()
		.min(5, getMessage('MIN_FIELD_LENGTH', { length: 5 }))
		.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
		.required(inputErrors.requiredField),
	phone: Yup.string().matches(PATTERNS.phoneNumber, 'Not a valid phone number format')
		.required(inputErrors.requiredField),
});
