// @ts-nocheck
import React from 'react';
import {
	Card, CardContent, CardHeader, Grid, makeStyles, useTheme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

// Components
import ChangePassword from '../components/ChangePassword/ChangePassword';
import ChangeEmail from '../components/ChangeEmail/ChangeEmail';
import ChangeFullNameAndTimezone from '../components/ChangeFullNameAndTimezone/ChangeFullNameAndTimezone';
import ChangeTwilioNumber from '../components/ChangeTwilioNumber/ChangeTwilioNumber';

// Constants
import { settingsValues } from '../constants/settingsValues';

const useStyles = makeStyles(theme => ({
	container: {
		marginBottom: '30px',
		boxShadow: '0px 0px 0px 0px rgba(0,0,0,0) !important',
	},
	contentWrapper: {
		fontSize: '18px',
		padding: '0px !important',
	},
	cardHeader: {
		maxWidth: '100%',
		padding: '0px !important',
	},
	noDataParagraph: {
		textAlign: 'center',
	},
	subheaderSpan: {
		maxWidth: '100%',
		overflowWrap: 'break-word',
		overflow: 'auto',
	},
	h4: {
		...theme.typography.h4,
	},
	h6: {
		...theme.typography.h6,
	},
}));

const Settings = () => {
	const userEmail = useSelector(state => state.auth.user.email);
	const userFullName = useSelector(state => state.auth.user.fullname);
	const userTimezone = useSelector(state => state.auth.user.timezone);

	const theme = useTheme();
	const classes = useStyles(theme);

	const componentsToRender = [
		{
			key: settingsValues.CHANGE_FULL_NAME_AND_TIMEZONE.key,
			title: <p className={classes.h4}>{settingsValues.CHANGE_FULL_NAME_AND_TIMEZONE.title}</p>,
			subheader: <p className={classes.h6}>{settingsValues.CHANGE_FULL_NAME_AND_TIMEZONE.subheader}</p>,
			subComponent: <ChangeFullNameAndTimezone data={{ userTimezone, userFullName }} />,
		},
		{
			key: settingsValues.CHANGE_EMAIL.key,
			title: (
				<p
					className={classes.h4}
				>
					{settingsValues.CHANGE_EMAIL.title}
				</p>
			),
			subComponent: <ChangeEmail data={{ userEmail }} />,
		},
		{
			key: settingsValues.CHANGE_PASSWORD.key,
			title: <p className={classes.h4}>{settingsValues.CHANGE_PASSWORD.title}</p>,
			subheader: <p className={classes.h6}>{settingsValues.CHANGE_PASSWORD.subheader}</p>,
			subComponent: <ChangePassword />,
		},
		{
			key: settingsValues.CHANGE_TWILIO_NUMBER.key,
			title: <p className={classes.h4}>{settingsValues.CHANGE_TWILIO_NUMBER.title}</p>,
			subheader: <p className={classes.h6}>{settingsValues.CHANGE_TWILIO_NUMBER.subheader}</p>,
			subComponent: <ChangeTwilioNumber />,
		},
	];

	const Wrapper = ({ title, subheader, subComponent }) => (
		// this is a good option for centered content, in billing there is a flex-start option
		<Card
			className={classes.container}
		>
			<CardHeader
				title=""
				subheader=""
				classes={{ root: classes.cardHeader }}
			/>
			<CardContent
				className={classes.contentWrapper}
			>
				<Grid container justify="center">
					<Grid item lg={6} sm={8} xs={12}>
						{title}
						{subheader}
						{subComponent}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);

	return (
		<>
			<br />
			{componentsToRender.map(component => (
				<Wrapper
					key={component.key}
					title={component.title}
					subheader={component.subheader}
					subComponent={component.subComponent}
				/>
			))}
		</>
	);
};

export default Settings;
