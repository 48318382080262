/* eslint-disable react/jsx-no-bind */
import React from 'react';

import {
	Box,
} from '@material-ui/core';

type BillingAutoRechargeCreditPack = {
	price: number
	credits: number
}

type BillingAutoRechargeNoteType = {
	criticalCredits: number
	creditPack: BillingAutoRechargeCreditPack | null,
	minMonthlySpendingRequirement: number,
	creditSpent: number
}

const BillingAutoRechargeNote: React.FC<BillingAutoRechargeNoteType> = (props) => {
	const {
		criticalCredits,
		creditPack,
		minMonthlySpendingRequirement,
		creditSpent,
	} = props;

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			height="100%"
			width="100%"
			flexDirection="column"
		>
			<Box
				p={8}
				bgcolor="#fafafa"
				display="flex"
				flexDirection="column"
				alignItems="flex-start"
				maxWidth="800px"
			>
				<Box pb={2.5} width="100%" fontSize="24px">
					You are all set! Credits will be automatically added based on the selected options
				</Box>
				<Box
					fontSize="20px"
					pb={2.5}
				>
					Recharge if balance falls below:
					<b>{` ${criticalCredits}`}</b>
				</Box>
				<Box
					fontSize="20px"
					pb={2.5}
				>
					Credit pack:
					<b>
						{` ${creditPack?.credits} for $ ${creditPack?.price}`}
					</b>
				</Box>
				{minMonthlySpendingRequirement > 0 && (
					<Box
						fontSize="17px"
						pb={2.5}
					>
						You spent
						<b>{` ${creditSpent} `}</b>
						amount of credits from expected
						<b>{` ${minMonthlySpendingRequirement}`}</b>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default BillingAutoRechargeNote;
