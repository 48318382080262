import { put, takeLatest } from 'redux-saga/effects';
import {
	POST_NOTIFICATION,
	REQUEST_NOTIFICATION,
} from './actions';

function* postNotification(action) {
	try {
		yield put({ type: REQUEST_NOTIFICATION, json: action.payload || [{ error: 'Error from yield put()' }] });
	} catch (error) {
		console.log(error); // TODO: Catch and yield an error through a saga.
	}
}

export function* postNotificationSaga() {
	yield takeLatest(POST_NOTIFICATION, postNotification);
}
