import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export default function (sentryUrl) {
	Sentry.init({
		dsn: sentryUrl,
		autoSessionTracking: true,
		environment: process.env.NODE_ENV,
		integrations: [
			new Integrations.BrowserTracing(),
		],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	});
}
