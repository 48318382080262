// @ts-nocheck
import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
	AGENTS_GET_AGENTS_SUCCESS,
	AGENTS_CREATE_AGENT_SUCCESS,
	AGENTS_UPDATE_AGENT_SUCCESS,
	AGENTS_UPDATE_AGENT,
	AGENTS_GET_AGENTS,
	AGENTS_CREATE_AGENT,
	AGENTS_UPDATE_AGENT_ACTIVITY,
	AgentType,
} from './agentTypes';
import axios from 'axios';
import URLS from '../../app/constants/urls';
import { postNotification } from '../notifications/actions';
import { getMessage } from '../../app/constants/messages';

const agentDefaultParams: AgentType = {
	availability: {},
	isVerified: false,
	isActive: true,
	isRegular: true,
};

function* updateAgent(action) {
	try {
		const json = yield axios.put(`${process.env.REACT_APP_API_URL}${URLS.AGENTS}${action.payload.id}`, {
			...agentDefaultParams,
			...action.payload,
		});
		yield put(
			{
				type: AGENTS_UPDATE_AGENT_SUCCESS,
				payload: json.data,
				meta: {
					errorMsg: 'Cannot update agent',
				},
			}
		);
	} catch (error) {
		yield put(postNotification(action.meta.errorMsg, 'error'));
	}
}

function* saveAgent(action) {
	try {
		const json = yield axios.post(`${process.env.REACT_APP_API_URL}${URLS.AGENTS}`, {
			...agentDefaultParams,
			...action.payload,
		});
		yield put(
			{
				type: AGENTS_CREATE_AGENT_SUCCESS,
				payload: json.data,
				meta: {
					errorMsg: getMessage('AGENT_CREATE_ERROR'),
				},
			}
		);
	} catch (error) {
		yield put(postNotification(action.meta.errorMsg, 'error'));
	}
}

function* updateAgentActivity(action) {
	try {
		const json = yield axios.put(`${process.env.REACT_APP_API_URL}${URLS.AGENTS}${action.payload.id}`, {
			...agentDefaultParams,
			...action.payload,
		});
		yield put(
			{
				type: AGENTS_UPDATE_AGENT_SUCCESS,
				payload: json.data,
				meta: {
					errorMsg: getMessage('AGENT_STATUS_CHANGE_ERROR'),
				},
			}
		);
	} catch (error) {
		yield put(postNotification(getMessage('AGENT_STATUS_CHANGE_ERROR'), 'error'));
	}
}

function* fetchAgents() {
	try {
		const json = yield axios.get(`${process.env.REACT_APP_API_URL}${URLS.AGENTS}`);
		yield put(
			{
				type: AGENTS_GET_AGENTS_SUCCESS,
				payload: json.data,
				meta: {
					errorMsg: getMessage('AGENT_FAIL_TO_GET_DATA'),
				},
			}
		);
	} catch (error) {
		yield put(postNotification(getMessage('AGENT_FAIL_TO_GET_DATA'), 'error'));
	}
}

export function* getAgentsSaga() {
	yield takeLatest(AGENTS_GET_AGENTS, fetchAgents);
}

export function* saveAgentSaga() {
	yield takeLatest(AGENTS_CREATE_AGENT, saveAgent);
}

export function* updateAgentSaga() {
	yield takeLatest(AGENTS_UPDATE_AGENT, updateAgent);
}

export function* updateAgentActivitySaga() {
	yield takeEvery(AGENTS_UPDATE_AGENT_ACTIVITY, updateAgentActivity);
}
