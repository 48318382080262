import React from 'react';

// Components
import { makeStyles, Switch, useTheme } from '@material-ui/core';
import TextFieldInput from './TextFieldInput';

const useStyles = makeStyles(theme => ({
	switch: {
		marginBottom: '25px',
	},
	wrapper: {
		display: 'grid',
		alignItems: 'center',
		gridTemplateColumns: '60px calc(100% - 60px)',
		maxWidth: '100%',
	},
}));

const InputWithSwitch = React.memo((props: any) => {
	const { textFieldProps, switchProps, element } = props;
	const theme = useTheme();
	const classes = useStyles(theme);

	return (
		<div className={classes.wrapper}>
			<Switch
				className={classes.switch}
				{...switchProps}
			/>
			{element || <TextFieldInput {...textFieldProps} />}
		</div>
	);
});

export default InputWithSwitch;
