// @ts-nocheck
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import {
	makeStyles, Box, useTheme, capitalize,
} from '@material-ui/core';
import { Pagination } from '../components/Pagination/Pagination';
import { format } from 'date-fns';
import defaultValues from '../constants/defaultValues';
import TableGenerator from '../components/TableBuilder/TableGenerator';
import { TableFilter } from '../components/TableBuilder/TableFilter';
import URLS from '../constants/urls';
import { checkFilterState } from '../lib/checkFilterState';
import ColoredPaper from '../components/ColoredPaper/ColoredPaper';
import { useDispatch, useSelector } from 'react-redux';
import { convertUTCToTimezone, returnFilterDateTime } from '../lib/timezoneHelpers';
import { postNotification } from '../../redux/notifications/actions';
import { TABLE_STATE } from '../constants/general';
import { getMessage } from '../constants/messages';

const useStyles = makeStyles(theme => ({
	container: {
		fontSize: '18px',
		marginBottom: '30px',
	},
	contentWrapper: {
		overflowX: 'auto',
		marginTop: theme.customSpacing.secondary,
	},
	cardHeaderAction: {
		margin: '0',
	},
	noDataParagraph: {
		textAlign: 'center',
	},
	columnHeaders: {
		padding: '12px',
		whiteSpace: 'nowrap',
	},
	rowCells: {
		padding: '12px',
		whiteSpace: 'nowrap',
	},
	paymentId: {
		// maxWidth: "200px", // dots with a smaller link that displays a tip tool showing the whole call ID ?
		padding: '12px',
		whiteSpace: 'nowrap',
		overflowX: 'auto',
	},
	h4: {
		...theme.typography.h4,
	},
	h6: {
		...theme.typography.h6,
	},
}));

const Payments = () => {
	const [paymentsData, setPaymentsData] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [dataToRender, setDataToRender] = useState(null);

	const dispatch = useDispatch();
	const user = useSelector(state => state.auth.user);

	const [page, setPage] = useState(1);
	const [filterState, setFilterState] = useState({});

	const theme = useTheme();
	const classes = useStyles(theme);

	const [loadingState, setLoadingState] = useState(TABLE_STATE.loading);

	const handlePageChange = (event, value) => {
		setLoadingState(TABLE_STATE.loading);
		setPage(value);
	};

	useEffect(() => {
		setLoadingState(TABLE_STATE.loading);
		async function getPaymentsData() {
			try {
				if (new Date(filterState.dateFrom) > new Date(filterState.dateTo)) {
					setLoadingState(TABLE_STATE.dateFilterError);
				} else {
					const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}${URLS.PAYMENTS}`, {
						params: {
							page,
							perPage: defaultValues.PAYMENTS_PER_PAGE,
							dateFrom: filterState?.dateFrom,
							dateTo: filterState?.dateTo,
							status: filterState?.Status === 'All' ? undefined : filterState?.Status?.toLowerCase(),
						},
					});

					setPaymentsData(data);
					if (!checkFilterState(filterState) && data.rows.length < 1) {
						setLoadingState(TABLE_STATE.noData);
					} else if (checkFilterState(filterState) && data.rows.length < 1) {
						setLoadingState(TABLE_STATE.noDataWithActiveFilters);
					} else {
						setLoadingState(TABLE_STATE.dataLoaded); // not really used
					}
				}
			} catch (error) {
				dispatch(postNotification(getMessage('PAYMENTS_HISTORY_GET_DATA_ERROR'), 'error'));
			}
		}
		getPaymentsData();
	}, [page, filterState, dispatch, user]);

	useEffect(() => {
		if (paymentsData && paymentsData.rows) {
			setTableData(paymentsData?.rows);
		}

		if (paymentsData?.count <= defaultValues.PAYMENTS_PER_PAGE) setPage(1);
	}, [paymentsData]);

	useEffect(() => {
		const newTableData = {
			isLoading: loadingState,
			columnHeaderClasses: classes.columnHeaders,
			columnHeader: ['Payment ID', 'Created At', 'Receipt', 'Automatic', 'Status', 'Credit Pack'], // 'Processed'
			rows: tableData?.map((payment) => {
				return ({
					id: payment.id,
					cells: [
						{
							id: payment.convertId,
							value: payment.convertId,
							itemToRender: payment.convertId,
							classes: classes.paymentId,
						},
						{
							id: payment.createdAt,
							value: payment.createdAt,
							itemToRender: format(convertUTCToTimezone(payment.createdAt, user.timezone), 'MM/dd/yyyy - hh:mm a'),
							classes: classes.rowCells,
						},
						{
							id: `${payment.id} invoiceLink`,
							value: `Invoice Link ${payment.webhookEvent?.data?.object?.charges?.data[0]?.receipt_url}`,
							itemToRender: payment.webhookEvent && payment.isProcessed && payment.isSuccessful ? (
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={`${payment.webhookEvent?.data?.object?.charges?.data[0]?.receipt_url}`}
								>
									View Receipt
								</a>
							) : '/',
							classes: classes.rowCells,
						},
						{
							id: `${payment.isAutomatic} isAutomatic`,
							value: payment.isAutomatic,
							itemToRender: payment.isAutomatic ? 'Yes' : 'No',
							classes: classes.rowCells,
						},
						{
							id: `status ${payment.id}`,
							value: payment.status,
							itemToRender: <ColoredPaper paymentsPage value={payment.status} />,
							classes: classes.rowCells,
						},
						{
							id: `${payment.CreditPack.credits}/${payment.amount}$ ${payment.id}`,
							value: `${payment.CreditPack.credits} credits / ${payment.amount}$`,
							itemToRender: `${payment.CreditPack.credits} credits / ${payment.amount}$`,
							classes: classes.rowCells,
						},
					],
				});
			}),
		};

		setDataToRender(newTableData);
	}, [paymentsData, tableData, classes, loadingState, user.timezone]);

	return (
		<>
			<Box display="flex" p={0} my={2}>
				<Box width="100%">
					<p className={classes.h4}>Payment History</p>
					<p className={classes.h6}>List of all payments on the account.</p>
				</Box>
			</Box>
			<TableFilter
				setFilterState={setFilterState}
				filterItems={[
					{
						id: 'dateFrom',
						type: 'date',
						label: 'Date from',
						disableFuture: true,
						defaultValue: returnFilterDateTime(defaultValues.DATE_FROM, 'from'),
					},
					{
						id: 'dateTo',
						type: 'date',
						label: 'Date to',
						disableFuture: true,
						defaultValue: returnFilterDateTime(new Date(), 'to'),
					},
					{
						id: 'Status',
						type: 'select',
						label: 'Status',
						emptyValue: 'All',
						options: defaultValues.PAYMENT_STATUSES.map(status => capitalize(status)),
					},
				]}
			/>
			<Box className={classes.contentWrapper}>
				<TableGenerator tableData={dataToRender} />
			</Box>
			{tableData.length > 0 && (
				<Box className={classes.contentWrapper}>
					<Box display="flex" justifyContent="center">
						<Pagination
							defaultPage={1}
							page={page}
							onChange={handlePageChange}
							shape="rounded"
							count={Math.ceil(paymentsData?.count / defaultValues.PAYMENTS_PER_PAGE)}
						/>
					</Box>
				</Box>
			)}
		</>
	);
};

export default Payments;
