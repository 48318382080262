// @ts-nocheck
const inputErrors = {
	requiredField: 'Required field',
	validUrl: 'Must be a valid url',
	isPositive: 'Must be positive',
	minCharacters: number => `Minimum ${number} characters`,
	lowerThenField: fieldName => `Lower then "${fieldName}"`,
	greaterThenField: fieldName => `Greater then "${fieldName}"`,
	invalidType: ({ originalValue, type }) => `Value "${originalValue}" is not a ${type}.`,
};

export default inputErrors;
