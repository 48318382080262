// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateEmailFullName } from '../../../redux/userSettings/actions';
import moment from 'moment-timezone';

// Material ui
import { Box } from '@material-ui/core';
import SecondaryButton from '../_actions/SecondaryButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, useTheme } from '@material-ui/core';

// lib
import { formatUTCOffset } from '../../lib/timezoneHelpers';

// Components
import TextFieldInput from '../_inputs/TextFieldInput';
import { postNotification } from '../../../redux/notifications/actions';

// Constants
import { getMessage } from '../../constants/messages';
import { SORTED_TIMEZONES } from '../../constants/timezones';

const useStyles = makeStyles(theme => ({
	text: {
		marginBottom: '8px',
	},
}));

function ChangeFullNameAndTimezone(props) {
	const [filteredTimezones, setFilteredTimezones] = useState(SORTED_TIMEZONES);
	const theme = useTheme();
	const classes = useStyles(theme);
	const dispatch = useDispatch();

	const { intl, data: { userTimezone, userFullName } } = props;
	const ChangeFullNameAndTimezoneSchema = Yup.object().shape({
		newTimezone: Yup.string()
			.required(getMessage('REQUIRED_FIELD')),
		newFullName: Yup.string()
			.min(3, getMessage('MIN_FIELD_LENGTH', { length: 3 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
	});

	const formik = useFormik({
		initialValues: { newFullName: userFullName, newTimezone: userTimezone },
		validationSchema: ChangeFullNameAndTimezoneSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
			try {
				if (values.newTimezone !== userTimezone || values.newFullName !== userFullName) {
					dispatch(updateEmailFullName({ timezone: values.newTimezone, name: values.newFullName }));
				}
				setSubmitting(false);
				resetForm(formik);
			} catch (error) {
				dispatch(postNotification(getMessage('ACCOUNT_SETTINGS_CHANGE_NAME_OR_TIMEZONE_ERROR'), 'error'));
				setSubmitting(false);
				setStatus(
					intl.formatMessage(
						{ id: 'AUTH.VALIDATION.NOT_FOUND' },
						{ name: 'Placeholder' }
					)
				);
			}
		},
	});

	return (
		<form>
			<Box mt={3}>
				<TextFieldInput
					fullWidth
					label="Your Name"
					name="newFullName"
					type="text"
					value={formik.values.newFullName || userFullName}
					{...formik.getFieldProps('newFullName')}
					error={formik.touched.newFullName && !!formik.errors.newFullName}
					helperText={(formik.touched.newFullName && formik.errors.newFullName) || ' '}
				/>
				<Autocomplete
					className={classes.text}
					options={filteredTimezones || moment.tz.names()}
					getOptionLabel={option => `${option}, ${formatUTCOffset(option)}`}
					renderOption={(option => (
						<>
							{option}
							,
							{' '}
							{formatUTCOffset(option)}
						</>
					))}
					selectOnFocus
					value={userTimezone || ''}
					getOptionSelected={option => option === userTimezone}
					disableClearable
					handleHomeEndKeys
					onChange={(_, value) => { formik.setFieldValue('newTimezone', value); }}
					onBlur={() => formik.setTouched({ ...formik.touched, newTimezone: true })}
					renderInput={params => (
						<TextFieldInput
							{...params}
							{...formik.getFieldProps('newTimezone')}
							label="Time-zone"
							variant="outlined"
							name="newTimezone"
						/>
					)}
				/>
			</Box>
			<div className="form-group d-flex flex-wrap flex-start">
				<SecondaryButton
					variantColor="orange"
					onClick={() => formik.handleSubmit()}
					disabled={
						formik.isSubmitting ||
						!formik.isValid ||
                        formik.values.newEmail === '' ||
						formik.values.newFullName === '' ||
						(formik.values.newFullName === userFullName && formik.values.newTimezone === userTimezone)
					}
				>
					<span>Save</span>
					{formik.isSubmitting && <span className="ml-3 spinner spinner-white" />}
				</SecondaryButton>
			</div>
		</form>
	);
}

export default injectIntl(ChangeFullNameAndTimezone);
