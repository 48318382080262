import { makeStyles } from '@material-ui/core';

export const agentsStyles = makeStyles(theme => ({
	noDataParagraph: {
		textAlign: 'center',
	},
	contentWrapper: {
		overflow: 'auto',
	},
	h4: {
		...theme.typography.h4,
	},
	h6: {
		...theme.typography.h6,
	},
	actionBox: {
		marginBottom: '1.429rem',
	},
	actionGridItem: {
		alignSelf: 'flex-end',
	},
}));
