// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { getMessage } from '../../../constants/messages';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions as authActions } from '../_redux/authRedux';
import { postNotification } from '../../../../redux/notifications/actions';
import { verifyEmail } from '../../../../api';
import { EMAIL_VERIFICATION_TYPES } from '../../../constants/verification';

const VerifyEmail = ({ match }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const routerHistory = useHistory();

	const [name, setName] = useState();

	const verificationType = new URLSearchParams(location.search).get('type');
	const { verificationCode } = match.params;

	useEffect(() => {
		verifyEmail(verificationCode, verificationType)
			.then((response) => {
				const user = response.data;
				setName(user.name);

				if (verificationType === EMAIL_VERIFICATION_TYPES.CHANGE_EMAIL) {
					dispatch(authActions.requestUser());
				}
			})
			.catch(() => {
				dispatch(postNotification(getMessage('ACCOUNT_SETTINGS_EMAIL_VERIFICATION_ERROR'), 'error'));
			})
			.finally(() => {
				setTimeout(() => {
					routerHistory.replace('/auth/login');
				}, 3000);
			});
	}, [match.params, verificationType, routerHistory, dispatch]);

	return (
		<div className="d-flex justify-content-center">
			<Box>
				{ name && (
					<>
						<h3>{`${name}, your email has been verified.`}</h3>
						<br />
					</>
				)}
				<div className="d-flex justify-content-center">
					<CircularProgress size="3rem" color="primary" role="status" />
				</div>
			</Box>
		</div>
	);
};

export default VerifyEmail;
