import Axios from 'axios';
import { postNotification } from '../notifications/actions';
import { WIDGET_DELETE_WIDGET_SUCCESS, WidgetActionTypes } from './widgetTypes';
import { getMessage } from '../../app/constants/messages';

const widgetDeleteSuccess = (widgetId): WidgetActionTypes => {
	return {
		type: WIDGET_DELETE_WIDGET_SUCCESS,
		payload: widgetId,
	};
};

export const deleteWidgetAction = async (widgetId: string, dispatch) => {
	try {
		await Axios.delete(`${process.env.REACT_APP_API_URL}/client/widgets/${widgetId}`);
		dispatch(widgetDeleteSuccess(widgetId));
		dispatch(postNotification(getMessage('WIDGET_DELETE_SUCCESS'), 'success'));
	} catch (e) {
		dispatch(postNotification(getMessage('WIDGET_DELETE_ERROR'), 'error'));
	}
};
