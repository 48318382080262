// @ts-nocheck
import { makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Chart from 'chart.js';
import { parseISO } from 'date-fns';

// const dateFormat = 'MM/do/yyyy';

const lineChartConfig = {
	type: 'line',
	data: {
		datasets: [],
	},
	options: {
		tooltips: {
			mode: 'index',
			intersect: false,
		},
		animation: {
			duration: 0.2, // general animation time
		},
		hover: {
			animationDuration: 0.2, // duration of animations when hovering an item
			mode: 'index',
			intersect: false,
		},
		responsiveAnimationDuration: 0.2, // animation duration after a resize
		// showLines: false,
		elements: {
			line: {
				tension: 0.1, // disables bezier curves
			},
		},
		responsive: true,
		maintainAspectRatio: false,
		legend: {
			display: true,
			align: 'center',
			// position: "bottom"
			labels: {
				boxWidth: 40,
				fontSize: 14,
				padding: 12,
			},
			onClick(e, legendItem) {
				if (legendItem.text !== 'Conversion Rate') {
					const index = legendItem.datasetIndex;
					const ci = this.chart;
					const meta = ci.getDatasetMeta(index);
					meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
					ci.update();
				}
			},
		},
		title: {
			display: true,
			// text: "Chart"
		},
		scales: {
			// yAxes: [{
			//     stacked: true
			// }],
			xAxes: [{
				type: 'time',
				time: {
					// parser: dateFormat,
					tooltipFormat: 'll',
					// tooltipFormat: dateFormat,
					// unit: 'month',
					// minUnit: 'week',
					unitStepSize: 3,
				},
				scaleLabel: {
					//   display: true,
					//   labelString: 'Legend'
				},
				ticks: {
					min: undefined,
					max: undefined,
				},
			}],
		},
	},
};

let lineChart;

const useStyles = makeStyles(theme => ({
	chartContainer: {
		position: 'relative',
		marginBottom: theme.customSpacing.secondary,
		height: '40vh',
	},
}));

const pageVisitsObject = {
	label: 'Page Visits',
	data: [],
	borderColor: null,
	borderWidth: 1.2,
	fill: false,
	// backgroundColor: '#2274A550'
};
const openedObject = {
	label: 'Widget Views',
	data: [],
	borderColor: null,
	borderWidth: 1.2,
	fill: false,
	// backgroundColor: '#BA1B1D50'
};
const callsObject = {
	label: 'Calls',
	data: [],
	borderColor: null,
	borderWidth: 1.2,
	fill: false,
	// backgroundColor: '#8F398550'
};

const LineChart = ({ analyticsData, from, to }) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	const [datasets, setDatasets] = useState([]);

	pageVisitsObject.borderColor = theme.palette.chart.pageVisits.main;
	openedObject.borderColor = theme.palette.chart.widgetViews.main;
	callsObject.borderColor = theme.palette.chart.calls.main;

	useEffect(() => {
		const pageVisits = () => {
			const visitsArray = [];
			if (analyticsData) {
				analyticsData.forEach((analytic) => {
					visitsArray.push({ x: parseISO(analytic.date), y: analytic.usage.pageViews });
				});
			}
			return visitsArray;
		};

		const calls = () => {
			const callsArray = [];
			if (analyticsData) {
				analyticsData.forEach((analytic) => {
					let allCalls = 0;
					analytic.usage.widgets.forEach((widget) => {
						// @TODO: Remove hardcoded values
						['no-answer', 'missed', 'successful', 'busy'].forEach((statusKey) => {
							allCalls += (widget.calls[statusKey] || 0);
							allCalls += (widget.scheduledCalls[statusKey] || 0);
						});
					});
					callsArray.push({ x: parseISO(analytic.date), y: allCalls });
				});
			}
			return callsArray;
		};

		const opened = () => {
			const openedArray = [];
			if (analyticsData) {
				analyticsData.forEach((analytic) => {
					let openedSum = 0;
					analytic.usage.widgets.forEach((widget) => {
						openedSum += widget.widgetViews;
					});
					openedArray.push({ x: parseISO(analytic.date), y: openedSum });
				});
			}
			return openedArray;
		};

		setDatasets([
			{ ...pageVisitsObject, data: pageVisits() },
			{ ...openedObject, data: opened() },
			{ ...callsObject, data: calls() },
		]);
	}, [analyticsData]);

	useEffect(() => {
		lineChart = new Chart(document.getElementById('lineChart'), lineChartConfig);
		lineChart.options.scales.xAxes[0].ticks.min = from;
		lineChart.options.scales.xAxes[0].ticks.max = to;
		lineChart.data.datasets = datasets;

		lineChart.update();

		return () => {
			lineChart.destroy();
		};
	}, [from, to, analyticsData, datasets]);

	return (
		<div className={classes.chartContainer}>
			<canvas height="500px" id="lineChart" />
		</div>
	);
};

export default LineChart;
