import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import * as metaData from './metaData/metaDataRedux';
import * as widget from './widget/widgetRedux';
import * as website from './website/websiteRedux';
import * as agent from './agents/agentReducer';

import userSettingsReducer from './userSettings/reducers';
import {
	getAgentsSaga, saveAgentSaga, updateAgentSaga, updateAgentActivitySaga,
} from './agents/agentSagas';
import { updateEmailFullNameSaga } from './userSettings/sagas';

// Saga
import * as initialFetchSaga from './saga/initialFetchSaga';
import notificationsReducer from './notifications/reducers';
import { postNotificationSaga } from './notifications/sagas';

export const rootReducer = combineReducers({
	auth: auth.reducer,
	metaData: metaData.reducer,
	widget: widget.reducer,
	website: website.reducer,
	agents: agent.reducer,
	userSettings: userSettingsReducer, // {} empty for now
	notifications: notificationsReducer,
});

export function* rootSaga() {
	yield all([
		postNotificationSaga(),
		updateEmailFullNameSaga(),
		updateAgentSaga(),
		updateAgentActivitySaga(),
		saveAgentSaga(),
		getAgentsSaga(),
		metaData.saga(),
		website.saga(),
		auth.saga(),
		widget.saga(),
		initialFetchSaga.saga(),
	]);
}

export type RootStateType = ReturnType<typeof rootReducer>
