// @ts-nocheck
import React from 'react';

// Components
import { makeStyles, Button, useTheme } from '@material-ui/core';

const QuaternaryButton = React.memo((props: any) => {
	const { variantColor, ...restOfProps } = props;
	const theme = useTheme();
	const useStyles = makeStyles({
		button: {
			padding: theme.spacing(1),
			color: variantColor === 'blue' ? theme.palette.convertmoreBlue.white : theme.palette.convertmoreBlack.black,
			backgroundColor: variantColor === 'blue' ? theme.palette.convertmoreBlue.main : theme.palette.convertmoreBlack.main,
			borderColor: variantColor === 'black' && theme.palette.convertmoreBlack.borderColor,
			'&:hover': {
				backgroundColor: variantColor === 'blue' ? theme.palette.convertmoreBlue.hover : theme.palette.convertmoreBlack.hover,
			},
			...theme.typography.buttons.quaternary,
		},
	});
	const classes = useStyles();

	return (
		<Button
			variant={variantColor === 'blue' ? 'contained' : 'outlined'}
			size="small"
			disableElevation
			className={`${classes.button}`} // {`${classes.button} ${props.type === 'time' && classes.time} ${!props.fullWidth && classes.setWidth}
			{...restOfProps}
		/>
	);
});

export default QuaternaryButton;
