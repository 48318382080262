export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const TIME_INTERVAL = 5;

export const CRITICAL_CREDITS = [5, 25, 50, 100, 150, 200];

export const TABLE_STATE = {
	noData: 'no-data',
	noDataWithActiveFilters: 'no-data-with-active-filters',
	dateFilterError: 'date-error',
	loading: 'loading',
	dataLoaded: 'data-loaded',
};
