import React, { FC, useState } from 'react';
import { Pagination as MuiPagination, PaginationProps } from '@material-ui/lab';

export const Pagination: FC<PaginationProps> = (props) => {
	const { defaultPage, onChange } = props;
	const [currentPage, setCurrentPage] = useState<number>(defaultPage);

	const onPageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
		if (onChange && page !== currentPage) {
			setCurrentPage(page);
			onChange(event, page);
		}
	};

	return (
		<MuiPagination
			{...props}
			onChange={onPageChange}
		/>
	);
};

export default Pagination;
