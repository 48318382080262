// @ts-nocheck
import React from 'react';

// Components
import { makeStyles, Button, useTheme } from '@material-ui/core';

function PrimaryButton(props) {
	const { extendedWidth, ...restOfProps } = props;
	const theme = useTheme();
	const useStyles = makeStyles({
		button: {
			padding: theme.spacing(1),
			[theme.breakpoints.down('sm')]: {
				...theme.typography.buttons.primary.sm,
			},
			[theme.breakpoints.up('md')]: {
				...theme.typography.buttons.primary.md,
			},
			[theme.breakpoints.up('lg')]: {
				...theme.typography.buttons.primary.lg,
			},
			color: theme.palette.convertmoreOrange.white,
			backgroundColor: theme.palette.convertmoreOrange.main,
			'&:hover': {
				backgroundColor: theme.palette.convertmoreOrange.hover,
			},
		},
		extendedWidth: {
			[theme.breakpoints.down('sm')]: {
				width: '17.14rem',
				height: '3.571rem',
			},
		},
	});
	const classes = useStyles();

	return (
		<Button
			variant="contained"
			size="large"
			disableElevation
			className={extendedWidth ? `${classes.button} ${classes.extendedWidth}` : `${classes.button}`}
			{...restOfProps}
		/>
	);
}

export default PrimaryButton;
