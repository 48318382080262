// @ts-nocheck
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment-timezone';
import dayjs from './dayjs';

/**
 * Function used to generate SORTED_TIMEZONES constant
 *
 * @return [string]
 */
const getSortedTimezones = () => {
	const tzNames = moment.tz.names();
	const americanTimezones = [];
	const notAmericanTimezones = [];
	const regExUTC = /^UTC-[5-9]|10{1}$/; // get the US timezones which are from UTC-5 to UTC-10
	const regExAmerica = /America/; // check timezones that contain America in name

	for (let i = 0; i < tzNames.length; i += 1) {
		if (
			regExUTC.test(formatUTCOffset(tzNames[i]))
			&& regExAmerica.test(tzNames[i])
		) {
			americanTimezones.push(tzNames[i]);
		} else {
			notAmericanTimezones.push(tzNames[i]);
		}
	}

	return americanTimezones.concat(notAmericanTimezones);
};

/**
 *
 * @param {string} timezone IANA Timezone name
 * @returns {string} For example: UTC+3
 */
export function formatUTCOffset(timezone) {
	// @TODO: maybe there is some easier way to do this?
	const offset = getUTCOffsetHours(timezone);
	const hours = Math.abs(offset > 0 ? Math.floor(offset) : Math.ceil(offset));
	const minutes = Math.round(Math.abs(Math.abs(offset) - hours) * 60);
	const sign = offset > 0 ? '+' : '-';

	return `UTC${offset !== 0 ? sign : ''}${hours !== 0 ? hours : ''}${minutes !== 0 ? `:${minutes}` : ''}`;
}

/**
 *
 * @param {string} date Date ISO string (UTC)
 * @param {string} timezone IANA Timezone name
 * @returns {Date} Date in new timezone
 */
export function convertUTCToTimezone(date, timezone) {
	return moment.tz(date, timezone).toDate();
}

/**
 *
 * @returns {string[]} A list of all IANA timezone names.
 */
export function getAllTimezones() {
	// dayjs currently doesn't have this functionality that's why I used moment here and only here
	// alternative would be: https://www.npmjs.com/package/timezone-support
	return moment().tz.names();
}

/**
 *
 * @param {string} timezone IANA timezone name
 * @returns {number}
 */
export function getUTCOffsetHours(timezone) {
	return moment().tz(timezone).utcOffset() / 60;
}

/**
 *
 * @param {Date} date
 * @param {string} timezone IANA timezone name
 * @param {boolean} expressInUTC Should we convert it to UTC? (default: true)
 * @returns {Date} A JS Date object with same time but different timezone (expressed in UTC time)
 */
export function setTimezone(date, timezone, expressInUTC = true) {
	if (expressInUTC) return dayjs(date).tz(timezone, true).utc().toDate();
	return dayjs(date).tz(timezone, true).toDate();
}

export function returnFilterDateTime(date: Date, type: 'from' | 'to'): Date {
	if (type === 'from') {
		return dayjs(date).set('hour', 0).set('minute', 0).set('second', 0)
			.set('millisecond', 0)
			.format();
	}
	return dayjs(date).set('hour', 23).set('minute', 59).set('second', 59)
		.set('millisecond', 0)
		.format();
}
