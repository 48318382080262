// @ts-nocheck
import React from 'react';

// Components
import { makeStyles, Button, useTheme } from '@material-ui/core';

function TertiaryButton(props) {
	const { variantColor, ...restOfProps } = props;
	const theme = useTheme();
	const useStyles = makeStyles({
		button: {
			padding: theme.spacing(1),
			color: theme.palette.convertmoreBlue.white,
			backgroundColor: variantColor === 'blue' ?
				theme.palette.convertmoreBlue.main :
				theme.palette.convertmoreOrange.main,
			'&:hover': {
				backgroundColor: variantColor === 'blue' ?
					theme.palette.convertmoreBlue.hover :
					theme.palette.convertmoreOrange.hover,
			},
			...theme.typography.buttons.tertiary,
		},
	});
	const classes = useStyles();

	return (
		<Button
			variant="contained"
			size="small"
			disableElevation
			className={`${classes.button}`} // {`${classes.button} ${props.type === 'time' && classes.time} ${!props.fullWidth && classes.setWidth}
			{...restOfProps}
		/>
	);
}

export default TertiaryButton;
