// @ts-nocheck
import { subMonths } from 'date-fns';
import React from 'react';

const defaultValues = {
	CALLS_PER_PAGE: 20,
	CALL_TYPES: ['instant', 'scheduled'],
	CALL_STATUSES: ['successful', 'missed', 'busy', 'no-answer'],
	GRID_LABELS: ['Calls:', 'Scheduled:', 'Times Clicked:'],
	WIDGET_TYPES: ['Exit popup', 'Timed popup', 'Floating icon'],
	PAYMENT_STATUSES: ['on-hold', 'failed', 'canceled', 'successful'],
	widgetPopoverText: widgetName => (
		<>
			Below are presented
			<b>all</b>
			{' '}
			calls, scheduled calls and times opened related
			to the
			{widgetName}
			{' '}
			widget, for the
			<b>selected</b>
			{' '}
			website.
		</>
	),
	DATE_FROM: subMonths(new Date(), 1),
	UPCOMING_CALLS_DATE_FROM: new Date(),
	UPCOMING_CALLS_DATE_TO: subMonths(new Date(), -1),
	PAYMENTS_PER_PAGE: 10,
};

export default defaultValues;
