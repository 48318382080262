// @ts-nocheck
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { forgotPassword } from '../_redux/authCrud';
import TextFieldInput from '../../../components/_inputs/TextFieldInput';
import { Box, Grid, makeStyles } from '@material-ui/core';
import SecondaryButton from '../../../components/_actions/SecondaryButton';
import { getMessage } from '../../../constants/messages';

const useStyles = makeStyles({
	actionBox: {
		marginBottom: '1.429rem',
	},
	actionGridItem: {
		alignSelf: 'flex-end',
	},
});

const initialValues = {
	email: '',
};

function ForgotPassword(props) {
	const classes = useStyles();
	const { intl } = props;
	const [isRequested, setIsRequested] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const ForgotPasswordSchema = Yup.object().shape({
		email: Yup.string()
			.email(getMessage('WRONG_EMAIL_FORMAT'))
		// .min(3, getMessage('MIN_FIELD_LENGTH', { length: 3 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: ForgotPasswordSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			forgotPassword(values.email.toLowerCase())
				.then(() => {
					setShowMessage(true);
					setTimeout(() => { setIsRequested(true); }, 3000);
				})
				.catch(() => {
					setIsRequested(false);
					setSubmitting(false);
					setStatus(
						intl.formatMessage(
							{ id: 'AUTH.VALIDATION.NOT_FOUND' },
							{ name: values.email }
						)
					);
				});
		},
	});

	return (
		<>
			{isRequested && <Redirect to="/auth" />}
			{!isRequested && (
				<div className="login-form login-forgot" style={{ display: 'block' }}>
					<div className="text-center mb-10 mb-lg-20">
						<h3 className="font-size-h1">Forgotten Password ?</h3>
						<div className="text-muted font-weight-bold">
							Enter your email to reset your password
						</div>
					</div>
					<form
						onSubmit={formik.handleSubmit}
						className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
					>
						{formik.status && (
							<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
								<div className="alert-text font-weight-bold">
									{formik.status}
								</div>
							</div>
						)}

						<TextFieldInput
							label="Email"
							name="email"
							{...formik.getFieldProps('email')}
							fullWidth
							error={formik.touched.email && !!formik.errors.email}
							helperText={(formik.touched.email && formik.errors.email) || ' '}
						/>
						<Box display="flex" p={0} my={2}>
							<Grid container justify="center" spacing={2}>
								<Grid item>
									<Box width="100%">
										<SecondaryButton
											type="submit"
											variantColor="orange"
											disabled={
												formik.isSubmitting ||
                        !formik.isValid ||
                        formik.values.email === ''
											}
											// className="font-weight-bold px-9 py-4 my-3 mx-4" // btn btn-primary
										>
											<span>Submit</span>
											{formik.isSubmitting && <span className="ml-3 spinner spinner-white" />}
										</SecondaryButton>
									</Box>
								</Grid>
								<Grid item className={classes.actionGridItem}>
									<Box className={classes.actionBox}>
										<Link
											to="/auth/login"
											href="/auth/login"
										>
											<SecondaryButton
												type="button"
												variantColor="black"
												// className="font-weight-bold px-9 py-4" // btn btn-primary
											>
												<span>Cancel</span>
											</SecondaryButton>
										</Link>
									</Box>
								</Grid>
							</Grid>
						</Box>
						{showMessage && (
							<Box>
								<br />
								<p>{'We\'re sending an email to this address for creating a new password.'}</p>
							</Box>
						)}
					</form>
				</div>
			)}
		</>
	);
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
