// @ts-nocheck
function setLayout(type) {
	if (type === 'Square') {
		return {
			padding: '60px',
			margin: 'auto',
			width: '500px',
			height: 'fit-content',
		};
	}

	if (type === 'Vertical') {
		return {
			padding: '60px 40px',
			margin: 'auto',
			width: '500px',
			height: 'fit-content',
		};
	}

	if (type === 'Horizontal') {
		return {
			padding: '40px 60px',
			margin: 'auto',
			width: '500px',
			height: 'fit-content',
		};
	}

	return {
		padding: '40px',
		margin: '60px',
		width: 'auto',
		height: 'fill-available',
	};
}

export function runWidgetScript(widgetScript, isCall, isFloatingIcon, elementId, widgetSettings) {
	// create habitat

	const layout = setLayout(widgetSettings.globalSetup.layout);
	const habitat = document.createElement('div');
	habitat.id = 'root';
	habitat.style.margin = layout.margin;
	habitat.style.padding = layout.padding;
	habitat.style.width = layout.width;
	habitat.style.height = layout.height;
	habitat.style.marginTop = '0';
	habitat.style.maxWidth = '100%';
	habitat.style.boxSizing = 'border-box';
	if (!isFloatingIcon) {
		habitat.style.background = widgetSettings.globalSetup.background;
		habitat.style.borderRadius = '20px';
		habitat.style.boxShadow = '0 2px 10px 2px rgba(70, 70, 70, 0.1)';
	}

	const iFrame = document.getElementById(elementId);
	const iFrameBody = iFrame.contentDocument.body;
	iFrameBody.appendChild(habitat);

	// Fonts
	const googleFonts = document.createElement('link');
	googleFonts.rel = 'preconnect';
	googleFonts.href = 'https://fonts.gstatic.com';
	const fontLnk = document.createElement('link');
	fontLnk.href = 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap';
	fontLnk.rel = 'stylesheet';
	const css = document.createElement('link');
	css.href = process.env.REACT_APP_WIDGET_CSS_URL;
	css.rel = 'stylesheet';

	iFrame.contentDocument.head.appendChild(googleFonts);
	iFrame.contentDocument.head.appendChild(fontLnk);
	iFrame.contentDocument.head.appendChild(css);

	// create settings
	const settings = document.createElement('script');
	settings.type = 'text/props';
	settings.innerHTML = JSON.stringify({
		website: {
			isCall,
			isFloatingIcon,
			isPreview: true,
			Widgets: [{
				settings: widgetSettings,
			}],
		},
	});
	iFrameBody.appendChild(settings);

	// create script
	const script = document.createElement('script');
	script.innerText = widgetScript.data;
	script.async = true;
	habitat.appendChild(script);

	iFrame.style.height = `${habitat.scrollHeight + 80}px`;
}

export function updateWidgetSettings(id, widgetSettings, isCall, isFloatingIcon) {
	const iframe = document.getElementById(id);

	if (!iframe.contentDocument.querySelector('[type="text/props"]')) {
		console.log(`[UPDATEWIDGETSETTINGS]::Iframe with id: ${id} has a null value.`);
		return;
	}

	iframe.contentDocument.querySelector('[type="text/props"]').innerHTML = JSON.stringify({
		website: {
			isCall,
			isFloatingIcon,
			isPreview: true,
			Widgets: [{
				settings: widgetSettings,
			}],
		},
	});

	iframe.contentWindow.hydrateMyWidget();

	iframe.contentDocument.body.querySelector('#root').style.background = isFloatingIcon ? '#fff' : widgetSettings.globalSetup.background;

	// Iframe size
	const layout = setLayout(widgetSettings.globalSetup.layout);
	iframe.contentDocument.body.querySelector('#root').style.margin = layout.margin;
	iframe.contentDocument.body.querySelector('#root').style.padding = layout.padding;
	iframe.contentDocument.body.querySelector('#root').style.width = layout.width;
	iframe.contentDocument.body.querySelector('#root').style.height = layout.height;
}
