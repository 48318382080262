// @ts-nocheck
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import { Settings, Code, Style } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faPencilRuler, faLaptopCode } from '@fortawesome/free-solid-svg-icons';

function StepIcon(props) {
	const theme = useTheme();

	const useStepIconStyles = makeStyles({
		root: {
			backgroundColor: theme.palette.grey[500],
			zIndex: 1,
			color: '#fff',
			width: 50,
			height: 50,
			fontSize: '16px',
			display: 'flex',
			borderRadius: '50%',
			justifyContent: 'center',
			alignItems: 'center',
		},
		active: {
			backgroundColor: theme.palette.primary.main,
		},
		completed: {
			backgroundColor: theme.palette.primary.main,
		},
	});

	const classes = useStepIconStyles();
	const { active, completed, icon } = props;

	const icons = {
		1: <FontAwesomeIcon icon={faCogs} />,
		2: <FontAwesomeIcon icon={faPencilRuler} />,
		3: <FontAwesomeIcon icon={faLaptopCode} />,
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{completed ? <CheckIcon /> : icons[String(icon)]}
		</div>
	);
}

export default StepIcon;

StepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool.isRequired,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool.isRequired,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node.isRequired,
};
