// @ts-nocheck
import {
	AGENTS_GET_AGENTS,
	AGENTS_CREATE_AGENT,
	AGENTS_UPDATE_AGENT,
	AGENTS_GET_AGENTS_SUCCESS,
	AGENTS_CREATE_AGENT_SUCCESS,
	AGENTS_UPDATE_AGENT_SUCCESS,
	AGENTS_UPDATE_AGENT_ACTIVITY,
	AGENTS_DELETE_AGENT_SUCCESS,
	AgentsReducer, AgentsActionsType,
} from './agentTypes';

const updateAgentStore = (data, agentState) => {
	data.map((agent) => {
		if (agent.id === agentState.id) {
			agent.availability = agentState.availability;
			agent.name = agentState.name;
			agent.phone = agentState.phone;
			agent.isActive = agentState.isActive;
		}
		return agent;
	});

	return data;
};

const initialState: AgentsReducer = {
	loading: false,
	loaded: false,
	data: [],
	fakeAgents: [],
};

export const reducer = (state: AgentsReducer = initialState, action: AgentsActionsType) => {
	switch (action.type) {
		case AGENTS_GET_AGENTS:
			return { ...state, loading: true };
		case AGENTS_CREATE_AGENT:
			return { ...state, loading: true };
		case AGENTS_UPDATE_AGENT:
			return { ...state, loading: true };
		case AGENTS_UPDATE_AGENT_ACTIVITY:
			return { ...state, loading: false };
		case AGENTS_GET_AGENTS_SUCCESS:
			return {
				...state,
				data: action.payload.filter(agent => agent.name !== null),
				fakeAgents: action.payload.filter(agent => agent.name === null),
				loading: false,
				loaded: true,
			};
		case AGENTS_CREATE_AGENT_SUCCESS: {
			const newState = {
				...state,
				loading: false,
			};

			if (action.payload.isRegular) {
				newState.data = state.data.concat(action.payload);
			} else {
				newState.fakeAgents = state.fakeAgents.concat(action.payload);
			}

			return {
				...state,
				data: state.data.concat(action.payload),
				loading: false,
			};
		}
		case AGENTS_UPDATE_AGENT_SUCCESS: {
			const newState = {
				...state,
				loading: false,
			};

			if (action.payload.isRegular) {
				newState.data = [...updateAgentStore(state.data, action.payload)];
			} else {
				newState.fakeAgents = [...updateAgentStore(state.fakeAgents, action.payload)];
			}
			return newState;
		}
		case AGENTS_DELETE_AGENT_SUCCESS: {
			const newState = {
				...state,
				data: state.data.filter(data => data.id !== action.payload.id),
				loading: false,
				loaded: true,
			};
			return newState;
		}
		default:
			return state;
	}
};
