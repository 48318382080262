export const MESSAGES = {
	AUTH_REGISTER_SUCCESS: () => 'A sign up verification email was sent to your address!',
	AUTH_REGISTER_ERROR: () => 'Failed to send verification code.',
	AUTH_REGISTER_USERNAME_EXISTS: () => 'Account with that username already exists.',
	AUTH_REGISTER_TERMS_AND_CONDITIONS: () => 'Accepting terms & conditions is required.',
	AUTH_LOGIN_ERROR: () => 'The login details are incorrect.',
	FORGOT_PASSWORD_SUCCESS: () => 'Your account has been successfully reset.',
	WIDGET_CREATE_SUCCESS: () => 'Widget saved!',
	WIDGET_CREATE_ERROR: () => 'Widget creation failed.',
	WIDGET_EDIT_SUCCESS: () => 'Widget saved!',
	WIDGET_EDIT_ERROR: () => 'Widget update failed.',
	WIDGET_DELETE_ERROR: () => 'Failed to delete widget.',
	WIDGET_DELETE_SUCCESS: () => 'Widget deleted successfully.',
	WIDGET_NO_DAY_ACTIVE: () => 'A minimum of one active day is required.',
	WIDGET_ACTIVE_DAY_BUT_TIME_NOT_SET: () => 'A minimum of one time frame for active days is required.',
	WIDGET_UPLOAD_IMAGE_ERROR: () => 'Failed to upload picture.',
	WIDGET_ACTIVITY_CHANGE: ({ widgetName }) => `Activity status of widget ${widgetName} has been changed.`,
	AGENT_STATUS_CHANGE_SUCCESS: ({ agentName }) => `Activity status of agent ${agentName} has been changed.`,
	AGENT_STATUS_CHANGE_ERROR: () => ' Agent activity update failed.',
	AGENT_EDIT_ERROR: () => 'Failed to update agent.',
	AGENT_EDIT_SUCCESS: ({ agentName }) => `Agent ${agentName} has been updated.`,
	AGENT_CREATE_ERROR: () => 'Failed to save agent.',
	AGENT_CREATE_SUCCESS: ({ agentName }) => `Agent ${agentName} has been saved.`,
	AGENT_FAIL_TO_GET_DATA: () => 'Failed to get agents.',
	PAYMENT_AUTO_RECHARGE_SUCCESS: () => 'You\'ve successfully purchased credits and configured auto-recharge.',
	PAYMENT_AUTO_RECHARGE_UPDATE_SUCCESS: () => 'You\'ve successfully updated auto-recharge.',
	PAYMENT_AUTO_RECHARGE_ERROR: () => 'An error has occurred with auto-recharge.',
	PAYMENT_STRIPE_ERROR: () => 'External service error.',
	PAYMENT_BUY_CREDITS_SUCCESS: () => 'You\'ve successfully bought credits.',
	PAYMENT_BUY_CREDITS_ERROR: () => 'An error has occurred with the card payment.',
	PAYMENT_CREDIT_CARD_REMOVAL_ERROR: () => 'Credit card removal failed.',
	PAYMENT_MANUAL_CHARGE_SUCCESS: () => 'You\'ve successfully bought credits.',
	PAYMENT_MANUAL_CHARGE_ERROR: () => 'An error has occurred with the card payment.',
	PAYMENT_GET_PAYMENT_METHOD_ERROR: () => 'Failed to get payment methods.',
	PAYMENT_METHOD_CREATE_ERROR: () => 'An error has occurred while creating payment method.',
	ACCOUNT_SETTINGS_CHANGE_NAME_OR_TIMEZONE_SUCCESS: () => 'You\'ve successfully updated your data.',
	ACCOUNT_SETTINGS_CHANGE_NAME_OR_TIMEZONE_ERROR: () => 'Failed to updated your data.',
	ACCOUNT_SETTINGS_EMAIL_CHANGE_SUCCESS: () => 'Email verification link has been sent.',
	ACCOUNT_SETTINGS_EMAIL_CHANGE_ERROR: () => 'Failed to send verification code.',
	ACCOUNT_SETTINGS_EMAIL_VERIFICATION_ERROR: () => 'Invalid or expired verification code.',
	ACCOUNT_SETTINGS_ERROR: () => 'Account details update failed.',
	ACCOUNT_SETTINGS_PASSWORD_CHANGE_ERROR: () => 'Failed to change password.',
	ACCOUNT_SETTINGS_PASSWORD_NOT_MATCHING: () => 'Password and Confirm Password didn\'t match',
	ACCOUNT_SETTINGS_PASSWORD_CHANGE_SUCCESS: () => 'Your password has been changed.',
	CALLS_HISTORY_EXPORT_CSV_ERROR: () => 'Failed to export as CSV.',
	CALLS_HISTORY_GET_CALLS_ERROR: () => 'Failed to get calls.',
	ANALYTICS_GET_DATA_ERROR: () => 'Failed to get analytics data.',
	PAYMENTS_HISTORY_GET_DATA_ERROR: () => 'Failed to get payment data.',
	UPCOMING_CALLS_GET_DATA_ERROR: () => 'Failed to get upcoming calls.',

	// Validation errors

	REQUIRED_FIELD: () => 'Required field',
	VALID_URL: () => 'Must be a valid URL',
	VALUE_MUST_BE_POSITIVE_NUMBER: () => 'Must be positive',
	LOWER_THAN: fieldName => `Lower than ${fieldName}`,
	GREATER_THAN: ({ fieldName }) => `Greater than ${fieldName}`,
	VALUE_INVALID_TYPE: ({ fieldValue, type }) => `Value "${fieldValue}" is not a ${type}`,
	MIN_FIELD_LENGTH: ({ length }) => `Minimum field length ${length}`,
	MAX_FIELD_LENGTH: ({ length }) => `Maximum field length ${length}`,
	REQUIRED_SPECIFIC_FIELD: ({ fieldName }) => `${fieldName} is required.`,
	INVALID_INPUT_FIELD: ({ fieldName }) => `${fieldName} is not valid.`,
	WRONG_EMAIL_FORMAT: () => 'Wrong email format',
	WRONG_PHONE_NUMBER_FORMAT: () => 'Wrong phone number format',
	INPUT_ACTIVE_DAY_TIMEFRAME: () => 'A minimum of one time frame for active days is required.',

	// Global

	USER_CREDITS_FALL_BELLOW_SET_BALANCE: ({ credits }) => `Your credits are running low: ${credits} credits. Buy more credits.`,
	USER_CREDITS_FALL_BELLOW_1: () => 'You ran out of your credits. Please, recharge your balance.',
	USER_SPENT_CREDITS_ERROR: () => 'An error has occurred with the spent credits',
	USER_OVERDRAFT_REACHED_MESSAGE: () => 'Your conversion toolbox is disabled due to an unpaid bill.',
	USER_OVERDRAFT_REACHED_LINK: () => 'Please, pay your bill to regain access to the conversion toolbox.',

	// Other

	GET_METADATA_ERROR: () => 'Failed to get metadata.',
	GET_WEBSITES_ERROR: () => 'Failed to get websites.',
	GET_WIDGETS_ERROR: () => 'Failed to get widgets.',
	ADD_WEBSITE_ERROR: () => 'Failed to add website.',
	UNKNOWN_ERROR_OCCURRED: () => 'Unknown error occurred.',

	UPDATE_IMAGE_ERROR: () => 'Failed to update image on widget.',
	UPDATE_IMAGE_SUCCESS: () => 'Image on widget updated successfully.',
};

export const getMessage = (messageId: keyof typeof MESSAGES, options = undefined): string => MESSAGES[messageId](options);
