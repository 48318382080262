// @ts-nocheck
import { put, takeLatest } from 'redux-saga/effects';
import {
	UPDATE_EMAIL_FULL_NAME,
} from './actions';
import axios from 'axios';
import URLS from '../../app/constants/urls';
import { actions as authActions } from '../../app/modules/Auth/_redux/authRedux';
import { postNotification } from '../notifications/actions';
import { getMessage } from '../../app/constants/messages';

function* updateEmailFullName(action) {
	try {
		yield axios.patch(`${process.env.REACT_APP_API_URL}${URLS.CHANGE_USER_PARAMS}`, {
			...action.payload,
		});
		if (action.payload.name || action.payload.timezone || action.payload.twilioNumberId) {
			yield put(authActions.requestUser());
			yield put(postNotification(getMessage('ACCOUNT_SETTINGS_CHANGE_NAME_OR_TIMEZONE_SUCCESS'), 'success'));
		}
	} catch (error) {
		yield put(postNotification(action.meta.errorMsg, 'error'));
	}
}

export function* updateEmailFullNameSaga() {
	yield takeLatest(UPDATE_EMAIL_FULL_NAME, updateEmailFullName);
}
