import React from 'react';
import { Grid } from '@material-ui/core';
import TextFieldInput from '../../../../../components/_inputs/TextFieldInput';
import AutocompleteInput from '../../../../../components/_inputs/AutocompleteInput';
import SelectInput from '../../../../../components/_inputs/SelectInput';

const BaseOptions = React.memo((props: any) => {
	const {
		baseOptionsFormik,
		websiteUrlFormik,
		websiteOptions,
		widgetTypeOptions,
	} = props;

	return (
		<Grid
			container
			spacing={2}
			direction="row"
			justify="center"
			alignItems="center"
		>
			<Grid item xs={12} sm="auto">
				<TextFieldInput
					id="name"
					name="name"
					label="Widget name"
					placeholder="Widget name"
					value={baseOptionsFormik.values.name}
					onChange={baseOptionsFormik.handleChange}
					onBlur={baseOptionsFormik.handleBlur}
					error={Boolean(baseOptionsFormik.errors.name)}
					helperText={baseOptionsFormik.errors.name || ' '}
				/>
			</Grid>
			<Grid item xs={12} sm="auto">
				<AutocompleteInput
					style={{ marginBottom: 0 }}
					setFieldValue={websiteUrlFormik.setFieldValue}
					setFieldTouched={websiteUrlFormik.setFieldTouched}
					options={websiteOptions}
					textInputProps={{
						id: 'websiteUrl',
						name: 'websiteUrl',
						label: 'Website URL',
						placeholder: 'Url',
						type: 'url',
						value: websiteUrlFormik.values.websiteUrl,
						onChange: websiteUrlFormik.handleChange,
						onBlur: websiteUrlFormik.handleBlur,
						error: Boolean(websiteUrlFormik.errors.websiteUrl),
						helperText: websiteUrlFormik.errors.websiteUrl || ' ',
					}}
				/>
			</Grid>
			<Grid item xs={12} sm="auto">
				<SelectInput
					id="widgetType"
					label="Widget type"
					noAllOption
					options={widgetTypeOptions}
					value={baseOptionsFormik.values.widgetType || 'All Widgets'}
					onChange={baseOptionsFormik.handleChange}
					onBlur={baseOptionsFormik.handleBlur}
					error={Boolean(baseOptionsFormik.errors.widgetType)}
					helperText={baseOptionsFormik.errors.widgetType || ' '}
				/>
			</Grid>
		</Grid>
	);
});

export default BaseOptions;
