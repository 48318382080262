import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box, CircularProgress, Grid, Switch,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';

// Constants
import { generalInitialValues, designInitialValues } from '../../constants/widgetBuilder';
import { getMessage } from '../../constants/messages';

// Types
import { RootStateType } from '../../../redux/rootReducer';
import {
	WIDGET_REQUEST_UPDATE_SINGLE_WIDGET,
	WidgetActionTypes,
	WidgetType,
} from '../../../redux/widget/widgetTypes';

// Actions
import { postNotification } from '../../../redux/notifications/actions';

// Styles
import { widgetStyles } from './Widgets.styles';

// Components
import { TableFilter } from '../../components/TableBuilder/TableFilter';
import TableGenerator from '../../components/TableBuilder/TableGenerator';
import ColoredPaper from '../../components/ColoredPaper/ColoredPaper';
import PrimaryButton from '../../components/_actions/PrimaryButton';
import TertiaryButton from '../../components/_actions/TertiaryButton';
import PromptDialog from '../../components/PromptDialog/PromptDialog';
import { deleteWidgetAction } from '../../../redux/widget/widgetActions';
import { TABLE_STATE } from '../../constants/general';

const Widgets = () => {
	const classes = widgetStyles();

	// Redux
	const dispatch = useDispatch();
	const websites = useSelector((state: RootStateType) => state.website.websites);
	const metaData = useSelector((state: RootStateType) => state.metaData);

	const {
		widgetTypes,
	} = metaData;

	const widgets = useSelector((state: RootStateType) => state.widget);
	const updateWidget = useCallback((widget: WidgetType) => dispatch<WidgetActionTypes>({
		type: WIDGET_REQUEST_UPDATE_SINGLE_WIDGET,
		payload: widget,
	}), [dispatch]);

	// State
	const [dataToFilter, setDataToFilter] = useState(widgets.widgets);
	const [filteredData, setFilteredData] = useState(widgets.widgets);
	const [tableData, setTableData] = useState({});
	const [widgetToDelete, setWidgetToDelete] = useState(null);

	useEffect(() => {
		setDataToFilter(widgets.widgets);
	}, [widgets.widgets]);

	useEffect(() => {
		const newTableData = {
			isLoading: filteredData.length < 1 ? TABLE_STATE.noDataWithActiveFilters : undefined,
			columnHeader: ['Website', 'Widget Name', 'Widget Type', 'Status', ''],
			rows: filteredData.map((widget) => {
				const websiteDomain = websites.find(website => website.id === widget.websiteId)?.domain;
				const foundTypeId = widgets.widgets.find(_widget => _widget.typeId === widget.typeId)?.typeId;
				const foundWidgetTypeName = widgetTypes?.find(widgetType => widgetType?.id === foundTypeId)?.name;

				return ({
					id: widget.id,
					cells: [
						{
							id: widget.websiteId,
							value: websiteDomain,
							itemToRender: websiteDomain,
						},
						{
							id: `${widget.id} - ${widget.name}`,
							value: widget.name,
							itemToRender: widget.name,
						},
						{
							id: widget.id,
							value: widgetTypes.find(type => type.id === foundTypeId)?.name,
							itemToRender: <ColoredPaper value={foundWidgetTypeName} />,
						},
						{
							id: `status ${widget.id}`,
							value: widget.isActive,
							itemToRender: (
								<Switch
									checked={widget.isActive}
									value={widget.isActive}
									color="primary"
									inputProps={{ 'aria-label': 'switch widget' }}
									onClick={() => {
										updateWidget({
											...widget,
											isActive: !widget.isActive,
										});
										dispatch(postNotification(getMessage('WIDGET_ACTIVITY_CHANGE', { widgetName: widget.name }), 'success'));
									}}
								/>
							),
						},
						{
							id: `button ${widget.id}`,
							itemToRender: (
								<Grid container justify="center" spacing={2}>
									<Grid item>
										<NavLink
											exact
											to={{
												pathname: `/widgets/manage/general/${widget.id}`,
											}}
										>
											<TertiaryButton
												variant="contained"
												variantColor="blue"
												size="medium"
											>
												Edit
											</TertiaryButton>
										</NavLink>
									</Grid>
									<Grid item>
										<TertiaryButton
											variant="contained"
											variantColor="orange"
											size="medium"
											onClick={() => setWidgetToDelete(widget.id)}
										>
											Delete
										</TertiaryButton>
									</Grid>
								</Grid>
							),
						},
					],
				});
			}),
		};
		setTableData(newTableData);
	}, [dispatch, filteredData, updateWidget, websites, widgetTypes, widgets]);

	return (
		<>
			{widgetToDelete && (
				<PromptDialog
					isOpen
					handleClose={() => setWidgetToDelete(null)}
					handleAccept={() => deleteWidgetAction(widgetToDelete, dispatch)}
					title="Delete widget?"
					description="Are you sure you want to delete widget? This action is irreversible."
				/>
			)}
			<Box display="flex" p={0} my={2}>
				<Grid container justify="space-between">
					<Grid item>
						<Box width="100%">
							<p className={classes.h4}>Your Widgets</p>
							<p className={classes.h6}>Websites and widgets you are using.</p>
						</Box>
					</Grid>
					<Grid item className={classes.actionGridItem}>
						{!widgets.loading && widgets.widgets.length > 0 && (
							<Box flexShrink={0} className={classes.actionBox}>
								<NavLink
									exact
									to={{
										pathname: '/widgets/manage/general',
										state: {
											widgetId: null,
											generalInitialState: generalInitialValues,
											designInitialState: designInitialValues,
										},
									}}
								>
									<PrimaryButton variant="contained" size="large">
										Create New
										Widget
									</PrimaryButton>
								</NavLink>
							</Box>
						)}
					</Grid>
				</Grid>
			</Box>
			{widgets.loading && (
				<div className="d-flex justify-content-center">
					<CircularProgress size="3rem" color="primary" role="status" />
				</div>
			)}
			{!widgets.loading && widgets.widgets.length > 0 && (
				<>
					<TableFilter
						dataToFilter={dataToFilter}
						returnFilteredData={setFilteredData}
						filterItems={[
							{
								id: 'Websites',
								type: 'select',
								label: 'Websites',
								options: websites.map(website => website.domain),
								emptyValue: 'All',
								filterFunction: (data, value) => {
									const websiteId = websites.find(website => website.domain === value)?.id;
									return data.websiteId === websiteId;
								},
							},
							{
								id: 'widgetType',
								type: 'select',
								label: 'Widget type',
								emptyValue: 'All',
								options: widgetTypes.map(type => type.name),
								filterFunction: (data, value) => data.typeId === widgetTypes.find(type => type.name === value)?.id,
							},
							{
								id: 'Status',
								type: 'select',
								label: 'Status',
								options: [true, false],
								emptyValue: 'All',
								filterFunction: (data, value) => data?.isActive === value,
							},
						]}
					/>
					<Box p={0} className={classes.contentWrapper}>
						<TableGenerator tableData={tableData} />
					</Box>
				</>
			)}
			{!widgets.loading && widgets.widgets.length < 1 && (
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
				>
					<Grid item>
						<h4 className={classes.noDataParagraph}>You don’t have any widgets created, yet.</h4>
					</Grid>
					<br />
					<Grid item>
						<NavLink
							exact
							to={{
								pathname: '/widgets/manage/general',
								state: {
									widgetId: null,
									generalInitialState: generalInitialValues,
									designInitialState: designInitialValues,
								},
							}}
						>
							<PrimaryButton variant="contained" size="large">Create New Widget</PrimaryButton>
						</NavLink>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default Widgets;
