// @ts-nocheck
import Axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { postNotification } from '../notifications/actions';
import { getMessage } from '../../app/constants/messages';

export const actionTypes = {
	REQUEST_STARTED: 'REQUEST_STARTED',
	REQUEST_ENDED: 'REQUEST_ENDED',
	REQUEST_ERROR: 'WEBSITE_REQUEST_ERROR',
	UPDATE_WEBSITES: 'UPDATE_WEBSITES',
	UPDATE_WEBSITE: 'UPDATE_WEBSITE',
	REQUEST_GET_WEBSITES: 'REQUEST_GET_WEBSITES',
	REQUEST_POST_WEBSITE: 'REQUEST_POST_WEBSITE',
	ADD_WEBSITE: 'ADD_WEBSITE',
};

const initialWebsiteState = {
	websites: [],
	isFetching: false,
	error: null,
	loading: true,
	loaded: false,
};

export const reducer = (state = initialWebsiteState, action) => {
	switch (action.type) {
		case actionTypes.REQUEST_STARTED: {
			return { ...state, isFetching: true };
		}
		case actionTypes.REQUEST_ENDED: {
			return { ...state, isFetching: false };
		}
		case actionTypes.REQUEST_ERROR: {
			const data = action.payload;
			return { ...state, isFetching: true, error: data };
		}
		case actionTypes.UPDATE_WEBSITES: {
			const data = action.payload;
			return {
				...state,
				websites: data,
				loading: false,
				loaded: true,
			};
		}
		case actionTypes.UPDATE_WEBSITE: { // update/add?
			const data = action.payload;
			const newState = { ...state };
			newState.websites.push(data);
			return newState;
		}
		case actionTypes.ADD_WEBSITE: {
			const data = action.payload;
			const newState = { ...state };
			newState.websites.push(data);
			return newState;
		}
		default:
			return state;
	}
};

export const actions = {
	startRequest: () => ({ type: actionTypes.REQUEST_STARTED }),
	endRequest: () => ({ type: actionTypes.REQUEST_ENDED }),
	errorRequest: data => ({ type: actionTypes.REQUEST_ERROR, payload: data }),
	requestGetWebsites: () => ({ type: actionTypes.REQUEST_GET_WEBSITES }),
	requestPostWebsite: data => ({ type: actionTypes.REQUEST_POST_WEBSITE, payload: data }),
	updateWebsites: data => ({ type: actionTypes.UPDATE_WEBSITES, payload: data }),
	updateWebsite: data => ({ type: actionTypes.UPDATE_WEBSITE, payload: data }),
	addWebsite: data => ({ type: actionTypes.ADD_WEBSITE, payload: data }),
};

export function* saga() {
	yield takeLatest(actionTypes.REQUEST_GET_WEBSITES, function* getWebsitesSaga() {
		try {
			yield actions.startRequest();
			const { data: websites } = yield Axios.get(`${process.env.REACT_APP_API_URL}/client/websites`);
			yield put(actions.updateWebsites(websites));
			yield put(actions.endRequest());
		} catch (error) {
			yield put(postNotification(getMessage('GET_WEBSITES_ERROR'), 'error'));
		}
	});
	yield takeLatest(actionTypes.REQUEST_POST_WEBSITE, function* putWebsiteSaga(action) { // put -> post
		try {
			yield actions.startRequest();
			const { data } = yield Axios.post(`${process.env.REACT_APP_API_URL}/client/websites`, { domain: action.payload });
			yield put(actions.addWebsite(data));
			yield put(actions.endRequest());
		} catch (error) {
			yield put(postNotification(getMessage('ADD_WEBSITE_ERROR'), 'error'));
		}
	});
}
