/* eslint-disable no-underscore-dangle */
import { actions } from '../app/modules/Auth/_redux/authRedux';
import { login, LOGIN_URL } from '../app/modules/Auth/_redux/authCrud';
import { REASON_CODES } from '../app/api';

export default function setupAxios(axios, store) {
	axios.interceptors.request.use(
		(config) => {
			// Find better way to not include authorization on aws
			const isAwsRequest = config.url.includes(process.env.REACT_APP_AWS_URL);

			const { auth: { authToken } } = store.getState();
			if (authToken && !isAwsRequest) {
				config.headers.Authorization = `Bearer ${authToken}`;
			}
			return config;
		},
		err => Promise.reject(err)
	);

	axios.interceptors.response.use((res) => {
		return res;
	}, async (error) => {
		const originalRequest = error.config;
		const { status, data } = error.response;
		const reasonCode = data.error?.description;

		if (status === 401 && !originalRequest._retry && (reasonCode === REASON_CODES.INVALID_OR_EXPIRED_ACCESS_TOKEN)) {
			originalRequest._retry = true;
			const { auth: { refreshToken } } = store.getState();

			const params = new URLSearchParams();
			params.append('grant_type', 'refresh_token');
			params.append('refresh_token', refreshToken);
			params.append('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID);

			try {
				const { data } = await login(params);
				store.dispatch(actions.login(data.accessToken, data.refreshToken));
				originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
				return axios.request(originalRequest);
			} catch (e) {
				store.dispatch(actions.logout());
				return Promise.reject(e);
			}
		}
		return Promise.reject(error);
	});
}
