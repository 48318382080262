import React, { useState } from 'react';
import {
	Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid,
} from '@material-ui/core';
import SecondaryButton from '../../components/_actions/SecondaryButton';

import { autoRechargeOptionsButtonStyles as buttonStyles } from './Billing.styles';

import { disableAutoRecharge } from '../../../api';
import { useDispatch } from 'react-redux';

import { actions } from '../../../redux/metaData/metaDataRedux';

type BillingAutoRechargeOptionsType = {
    setShowForm: (boolean) => void
}

const BillingAutoRechargeOptions: React.FC<BillingAutoRechargeOptionsType> = (props) => {
	const { setShowForm } = props;

	const [open, setOpen] = useState(false);

	const dispatch = useDispatch();

	const classes = buttonStyles();

	const handleCancelAutoRecharge = async () => {
		await disableAutoRecharge();
		dispatch(actions.updateAutoRecharge(null));
	};

	const handleChangePaymentSettings = () => {
		setShowForm(true);
	};

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				width="100%"
				flexDirection="column"
			>
				<Box
					flexShrink={0}
					display="flex"
					flexDirection="row"
					alignItems="center"
					fontSize="20px"
				>
					<Box
						mr={2.5}
					>
						<Button onClick={() => { setOpen(true); }} className={`${classes.button} ${classes.cancelButton}`}>
							Cancel recurring charges
						</Button>
					</Box>
					<Box>
						<Button onClick={handleChangePaymentSettings} className={`${classes.button} ${classes.changeButton}`}>
							Change payment settings
						</Button>
					</Box>
				</Box>
			</Box>
			<Dialog open={open} onClose={handleCancelAutoRecharge} aria-labelledby="form-dialog-title">
				<DialogTitle style={{ textAlign: 'center' }} id="form-dialog-title">
					<b>
						Are you sure you want to cancel auto-recharge?
					</b>
				</DialogTitle>
				<DialogContent>
					<DialogContentText style={{ fontSize: '16px', textAlign: 'center' }}>
						If you run out of credits all widgets on your website will stop showing and you will not be able to make calls.
					</DialogContentText>
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
					>
						<br />
						<Grid item>
							<SecondaryButton variantColor="orange" onClick={handleCancelAutoRecharge}>
								Yes, I am sure.
							</SecondaryButton>
						</Grid>
						<br />
						<Grid item>
							<SecondaryButton variantColor="black" onClick={() => { setOpen(false); }}>
								Cancel
							</SecondaryButton>
						</Grid>
						<br />
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default BillingAutoRechargeOptions;
