export const checkFilterState = (filterState) => {
	for (const key in filterState) {
		if (Object.hasOwnProperty.call(filterState, key)) {
			const filterParam = filterState[key];
			if (filterParam === '' || !filterParam) {
				return false;
			}
		}
	}
	return true;
};
