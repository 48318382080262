import { ActiveHoursType } from '../../typecheckings';

export const AGENTS_GET_AGENTS = 'AGENTS_GET_AGENTS';
export const AGENTS_GET_AGENTS_SUCCESS = 'AGENTS_GET_AGENTS_SUCCESS';
export const AGENTS_CREATE_AGENT = 'AGENTS_CREATE_AGENT';
export const AGENTS_CREATE_AGENT_SUCCESS = 'AGENTS_CREATE_AGENT_SUCCESS';
export const AGENTS_UPDATE_AGENT = 'AGENTS_UPDATE_AGENT';
export const AGENTS_UPDATE_AGENT_SUCCESS = 'AGENTS_UPDATE_AGENT_SUCCESS';
export const AGENTS_UPDATE_AGENT_ACTIVITY = 'AGENTS_UPDATE_AGENT_ACTIVITY';
export const AGENTS_UPDATE_AGENT_ACTIVITY_SUCCESS = 'AGENTS_UPDATE_AGENT_ACTIVITY_SUCCESS';
export const AGENTS_DELETE_AGENT_SUCCESS = 'AGENTS_DELETE_AGENT_SUCCESS';

export type AgentType = {
	id: string
	numericId: string
	name: string
	email: string
	phone: string
	userId: string
	availability: {
		activeHours: ActiveHoursType[]
	}
	isVerified: boolean
	isActive: boolean
	isRegular: boolean
	createdAt: string
	updatedAt: string
	deletedAt: null
}

export type AgentsReducer = {
	loading: boolean
	loaded: boolean
	data: AgentType[]
	fakeAgents: AgentType[]
}

export interface GetAgentsInterface {
	type: typeof AGENTS_GET_AGENTS
}

export interface GetAgentsSuccessInterface {
	type: typeof AGENTS_GET_AGENTS_SUCCESS
	payload: AgentType[]
	meta: {
		errorMsg: string;
	}
}

export interface CreateAgentsInterface {
	type: typeof AGENTS_CREATE_AGENT
	payload: AgentType
	meta: {
		errorMsg: string;
	}
}

export interface CreateAgentsSuccessInterface {
	type: typeof AGENTS_CREATE_AGENT_SUCCESS
	payload: AgentType
}

export interface UpdateAgentsInterface {
	type: typeof AGENTS_UPDATE_AGENT
	payload: AgentType
}

export interface UpdateAgentsSuccessInterface {
	type: typeof AGENTS_UPDATE_AGENT_SUCCESS
	payload: AgentType
}

export interface UpdateAgentsActivityInterface {
	type: typeof AGENTS_UPDATE_AGENT_ACTIVITY
	payload: AgentType
}

export interface UpdateAgentsActivitySuccessInterface {
	type: typeof AGENTS_UPDATE_AGENT_ACTIVITY_SUCCESS
	payload: AgentType
}

export interface DeleteAgentSuccessInterface {
	type: typeof AGENTS_DELETE_AGENT_SUCCESS
	payload: AgentType
}

export type AgentsActionsType = GetAgentsInterface
	| CreateAgentsInterface
	| UpdateAgentsActivityInterface
	| UpdateAgentsInterface
	| GetAgentsSuccessInterface
	| CreateAgentsSuccessInterface
	| UpdateAgentsSuccessInterface
	| UpdateAgentsActivitySuccessInterface
	| DeleteAgentSuccessInterface;
