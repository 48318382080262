import React from 'react';
import { useSelector } from 'react-redux';
import {
	Box, Grid,
} from '@material-ui/core';
// Types
import { RootStateType } from '../../../redux/rootReducer';
// Components
import TableGenerator from '../../components/TableBuilder/TableGenerator';
import Code from '../../components/Code/Code';
import { widgetStyles } from '../Widgets/Widgets.styles';

const Widgets = () => {
	const classes = widgetStyles();
	// Redux
	const websites = useSelector((state: RootStateType) => state.website.websites);

	const data = {
		isLoading: false,
		columnHeader: ['Website', 'Code'],
		rows: websites.map((website) => {
			return {
				id: website.id,
				cells: [
					{
						id: website.id,
						value: website.id,
						itemToRender: website.domain,
					},
					{
						id: `${website.id}-${website.domain}`,
						value: website.domain,
						itemToRender: <Code websiteId={website.id} />,
					},
				],
			};
		}),
	};

	return (
		<>
			<Box display="flex" p={0} my={2}>
				<Grid container justify="space-between">
					<Grid item>
						<Box width="100%">
							<p className={classes.h4}>Your Websites</p>
							<p className={classes.h6}>Copy website code.</p>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box p={0}>
				<TableGenerator tableData={data} />
			</Box>
			{websites.length < 1 && (
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
				>
					<Grid item>
						<h4 className={classes.noDataParagraph}>You don’t have any website created, yet. Please create a widget to create a website.</h4>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default Widgets;
