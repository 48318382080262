const URLS = { // maybe should include ${process.env.REACT_APP_API_URL} right here for urls
	ANALYTICS: '/client/analytics',
	AGENTS: '/client/agents/',
	LOCAL_DASHBOARD: '/dashboard',
	PAYMENTS: '/client/payments',
	CHANGE_USER_PARAMS: '/user',
	VERIFY_CHANGE_EMAIL: '/user/email',
	VERIFY_EMAIL_AFTER_REGISTER: '/user/register',
	UPCOMING_CALLS: '/upcoming-calls',
};

export default URLS;
