// @ts-nocheck
import { put, takeLatest } from 'redux-saga/effects';

import { getAgents } from '../agents/agentActions';
import * as widget from '../widget/widgetRedux';
import * as metaData from '../metaData/metaDataRedux';
import * as website from '../website/websiteRedux';
import { postNotification } from '../notifications/actions';

export const actionTypes = {
	INITIAL_FETCH: 'INITIAL_FETCH',
};

export function* saga() {
	yield takeLatest(actionTypes.INITIAL_FETCH, function* initialFetchSaga() {
		try {
			yield put(metaData.actions.getMetaData());
			yield put(widget.actions.requestGetWidgets());
			yield put(website.actions.requestGetWebsites());
			yield put(getAgents());
		} catch (error) {
			yield put(postNotification('Failed to start application.', 'true'));
		}
	});
}
