import {
	Box, Card, CardContent, Grid,
} from '@material-ui/core';
import React from 'react';

function CountsBox({ data, classes }) {
	return (
		<Box className={classes.box}>
			<div className="d-flex justify-content-center">
				<Grid container spacing={2}>
					<Grid item xs={6} sm={3}>
						<Card raised className={classes.cards}>
							<CardContent className={classes.cardContents}>
								<b className={classes.pageVisitsCardText}>Page Visits</b>
								<p>{data?.pageVisits}</p>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={6} sm={3}>
						<Card raised className={classes.cards}>
							<CardContent className={classes.cardContents}>
								<b className={classes.widgetViewsCardText}>Widget Views</b>
								<p>{data?.widgetViews}</p>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={6} sm={3}>
						<Card raised className={classes.cards}>
							<CardContent className={classes.cardContents}>
								<b className={classes.callsCardText}>Calls</b>
								<p>{data?.calls}</p>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={6} sm={3}>
						<Card raised className={classes.cards}>
							<CardContent className={classes.cardContents}>
								<b className={classes.conversionRateCardText}>Conversion Rate</b>
								<p>{`${data?.conversionRate} %`}</p>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		</Box>
	);
}

export default CountsBox;
