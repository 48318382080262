import { AgentType } from '../agents/agentTypes';

export const actionTypes = {
	REQUEST_STARTED: 'REQUEST_STARTED',
	REQUEST_ENDED: 'REQUEST_ENDED',
	REQUEST_ERROR: 'REQUEST_ERROR',
	UPDATE_WIDGETS: 'UPDATE_WIDGETS',
	UPDATE_WIDGET: 'UPDATE_WIDGET',
	ADD_WIDGET: 'ADD_WIDGET',
	REQUEST_GET_WIDGETS: 'REQUEST_GET_WIDGETS',
	REQUEST_PUT_WIDGET: 'REQUEST_PUT_WIDGET',
	REQUEST_POST_WIDGET: 'REQUEST_POST_WIDGET',
};

export const WIDGET_REQUEST_START = 'WIDGET_REQUEST_START';
export const WIDGET_REQUEST_END = 'WIDGET_REQUEST_END';
export const WIDGET_REQUEST_ERROR = 'WIDGET_REQUEST_ERROR';
export const WIDGET_GET_WIDGETS = 'WIDGET_GET_WIDGETS';
export const WIDGET_REQUEST_WIDGETS = 'WIDGET_REQUEST_WIDGETS';
export const WIDGET_UPDATE_SINGLE_WIDGET = 'WIDGET_UPDATE_SINGLE_WIDGET';
export const WIDGET_REQUEST_UPDATE_SINGLE_WIDGET = 'WIDGET_REQUEST_UPDATE_SINGLE_WIDGET';
export const WIDGET_REQUEST_CREATE_WIDGET = 'WIDGET_REQUEST_CREATE_WIDGET';
export const WIDGET_CREATE_WIDGET = 'WIDGET_CREATE_WIDGET';
export const WIDGET_DELETE_WIDGET = 'WIDGET_DELETE_WIDGET';
export const WIDGET_DELETE_WIDGET_SUCCESS = 'WIDGET_DELETE_WIDGET_SUCCESS';
export const WIDGET_UPDATE_WIDGET_IMAGE = 'WIDGET_UPDATE_WIDGET_IMAGE';
export const WIDGET_REQUEST_UPDATE_WIDGET_IMAGE = 'WIDGET_REQUEST_UPDATE_WIDGET_IMAGE';

type WidgetSettingsTextItem = {
	text: string
	isActive: boolean
}

type WidgetSettingsActiveHours = {
	day: string
	isActive: boolean
	times: {
		from: string
		to: string
	}[]
}

type WidgetSettingsRedirections = {
	callNow: {
		isActive: boolean
		value: string
	}
	scheduleCall: {
		isActive: boolean
		value: string
	}
}

type WidgetSettingsShowScheduleForm = {
	duringWorkingHours: boolean
	outsideWorkingHours: boolean
}

type WidgetSettingsDeviceDisplay = {
	onDesktop: boolean
	onMobile: boolean
}

export type WidgetSettingsType = {
	globalSetup: {
		layout: string;
		background: string
		textOptions: {
			size: number
			color: string
			fontFamily: string
		}
		buttonOptions: {
			fontSize: number,
			size: number
			color: number
		}
		screenBackground: {
			color: string
			opacity: number
		}
		floatingIcon?: {
			size: number
			bgColor: string
			phoneIconColor: string
			position?: string
		}
	}
	callSetup: {
		picture: {
			isActive: boolean
			src: string
			size: number
		}
		main: WidgetSettingsTextItem
		disclaimer: WidgetSettingsTextItem
		buttonText: string
		callBackText: string
	}
	scheduleSetup: {
		timeToCallText: string
		buttonText: string
		main: WidgetSettingsTextItem
		disclaimer: WidgetSettingsTextItem
		customField1: WidgetSettingsTextItem
		customField2: WidgetSettingsTextItem
		customField3: WidgetSettingsTextItem
	}
	settings: {
		showsAfter: number
		repeatAfter: number
		displaysPerVisit: number
		agentNumber: string
		callingRule: string
		numberOfTries: string
		minutesBetweenTries: string
		useSchedule?: boolean
		activeHours: WidgetSettingsActiveHours[]
		redirections: WidgetSettingsRedirections
		showScheduleForm?: WidgetSettingsShowScheduleForm
		deviceDisplay?: WidgetSettingsDeviceDisplay
	}
	notifications: {
		email: {
			value: string
			isActive: boolean
		}
		phoneNumber: {
			value: string
			isActive: boolean
		}
	}
	waitingAudio: {
		isActive: boolean
		url: string | null | undefined
	}
}

export type WidgetType = {
	id: string
	numericId?: string
	websiteId: string
	userId: string
	typeId: string
	callingRuleId: string
	isActive: boolean
	settings: Partial<WidgetSettingsType>
	name: string
	createdAt: string
	updatedAt: string
	deletedAt: null
	agents: AgentType[]
}

export interface WidgetReducerInterface {
	widgets: WidgetType[]
	isFetching: boolean
	error: null | string
	loading: boolean
}

// Actions

interface WidgetRequestStart {
	type: typeof WIDGET_REQUEST_START
}

interface WidgetRequestEnd {
	type: typeof WIDGET_REQUEST_END
}

interface WidgetRequestError {
	type: typeof WIDGET_REQUEST_ERROR
	payload: string
}

interface WidgetGetWidgets {
	type: typeof WIDGET_GET_WIDGETS
	payload: WidgetType[]
}

export interface WidgetRequestWidgets {
	type: typeof WIDGET_REQUEST_WIDGETS
}

interface WidgetUpdateSingleWidget {
	type: typeof WIDGET_UPDATE_SINGLE_WIDGET
	payload: WidgetType
}

export interface WidgetRequestUpdateSingleWidget {
	type: typeof WIDGET_REQUEST_UPDATE_SINGLE_WIDGET
	payload: WidgetType
}

interface WidgetUpdateWidgetImage {
	type: typeof WIDGET_UPDATE_WIDGET_IMAGE
	payload: WidgetType
}

export interface WidgetRequestUpdateWidgetImage {
	type: typeof WIDGET_REQUEST_UPDATE_SINGLE_WIDGET
	payload: WidgetType
}

interface WidgetCreateWidget {
	type: typeof WIDGET_CREATE_WIDGET
	payload: WidgetType
}

export interface WidgetRequestCreateWidget {
	type: typeof WIDGET_REQUEST_CREATE_WIDGET
	payload: WidgetType
}

interface WidgetDeleteWidget {
	type: typeof WIDGET_DELETE_WIDGET
	payload: string
}

interface WidgetDeleteWidgetSuccess {
	type: typeof WIDGET_DELETE_WIDGET_SUCCESS
	payload: string
}

export type WidgetActionTypes = WidgetRequestStart
	| WidgetRequestEnd
	| WidgetRequestError
	| WidgetGetWidgets
	| WidgetUpdateSingleWidget
	| WidgetCreateWidget
	| WidgetRequestCreateWidget
	| WidgetRequestUpdateSingleWidget
	| WidgetDeleteWidget
	| WidgetDeleteWidgetSuccess
	| WidgetUpdateWidgetImage
	| WidgetRequestUpdateWidgetImage;
