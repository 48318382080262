// @ts-nocheck
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Material ui
import {
	Box, useTheme,
} from '@material-ui/core';

// Constants
import { addEditAgentSchema } from '../../../constants/agentFormik';
import { getMessage } from '../../../constants/messages';

// Redux
import { saveAgent, updateAgent } from '../../../../redux/agents/agentActions';

// Components
import TextFieldInput from '../../../components/_inputs/TextFieldInput';
import PhoneNumberInput from '../../../components/_inputs/PhoneNumberInput';

import SecondaryButton from '../../../components/_actions/SecondaryButton';
import { postNotification } from '../../../../redux/notifications/actions';
import { manageAgents } from './AgentManagerForm.styles';
import WeeklyPickers from '../../../components/AvailabilityPicker/WeeklyPickers';

const AgentManagerForm = (props) => {
	const {
		agent,
	} = props;
	const dispatch = useDispatch();
	const history = useHistory();
	const activeHoursRef = useRef();
	const [loading, setLoading] = useState(false);

	const user = useSelector((state: RootStateType) => state.auth.user);

	const theme = useTheme();
	const classes = manageAgents(theme);

	const formik = useFormik({
		validateOnChange: false,
		initialValues: {
			name: agent.name,
			phone: agent.phone,
		},
		enableReinitialize: true,
		validationSchema: addEditAgentSchema,
		onSubmit: async (values) => {
			if (!loading) {
				setLoading(true);

				// Active hours are not valid

				const activeHoursValidation = await activeHoursRef.current.validate();
				if (Object.keys(activeHoursValidation).length !== 0) {
					setLoading(false);
					return false;
				}

				const activeHours = activeHoursRef.current.values();

				const agentData = {
					name: values.name,
					phone: values.phone,
					availability: {
						activeHours,
					},
				};

				if (props.agent.id) {
					agentData.id = props.agent.id;
					dispatch(updateAgent(agentData, getMessage('AGENT_EDIT_ERROR')));
					dispatch(postNotification(getMessage('AGENT_EDIT_SUCCESS', { agentName: values.name }), 'success'));
				} else {
					dispatch(saveAgent(agentData, getMessage('AGENT_CREATE_ERROR')));
					dispatch(postNotification(getMessage('AGENT_CREATE_SUCCESS', { agentName: values.name }), 'success'));
				}
				setLoading(false);
				history.push('/agents');
			}
			return false;
		},
	});

	return (
		<>
			<Box display="flex" p={0} my={2}>
				<Box width="100%">
					<p className={classes.h4}>{agent.name ? 'Edit Agent' : 'Create New Agent'}</p>
					<p className={classes.h6}>{agent.name ? `${agent.name}'s details.` : null}</p>
				</Box>
			</Box>
			<form
				onSubmit={formik.handleSubmit}
				className="form fv-plugins-bootstrap fv-plugins-framework"
			>
				<div>
					<TextFieldInput
						name="name"
						id="name"
						size="medium"
						label="Name" // Doesn't work without onChange?
						value={formik.values.name}
						large
						{...formik.getFieldProps('name')}
						error={formik.touched.name && !!formik.errors.name}
						helperText={(formik.touched.name && formik.errors.name) || ' '}
					/>
				</div>
				<div>
					<PhoneNumberInput
						id="phone"
						name="phone"
						label="Phone Number"
						value={formik.values.phone}
						onDataChange={formik.setFieldValue}
						onFieldBlur={formik.setFieldTouched}
						error={formik.touched.phone && !!formik.errors.phone}
						helperText={(formik.touched.phone && formik.errors.phone) || ' '}
						country={user.country}
					/>
				</div>
				<p className={classes.h6}>Availability</p>
				<WeeklyPickers activeHours={agent.availability.activeHours} ref={activeHoursRef} />
				<br />
				<SecondaryButton
					variantColor="orange"
					type="submit"
					disabled={loading}
				>
					<span>Save Agent</span>
					{loading && <span className="ml-3 mr-3 spinner spinner-white" />}
				</SecondaryButton>
			</form>
		</>
	);
};

export default AgentManagerForm;
