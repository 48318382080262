// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { format } from 'date-fns';
import { makeStyles, Box } from '@material-ui/core';
import { Pagination } from '../components/Pagination/Pagination';
import defaultValues from '../constants/defaultValues';
import ColoredPaper from '../components/ColoredPaper/ColoredPaper';
import { getMessage } from '../constants/messages';

// Components
import TableGenerator from '../components/TableBuilder/TableGenerator';
import { TableFilter } from '../components/TableBuilder/TableFilter';
import { checkFilterState } from '../lib/checkFilterState';
import { convertUTCToTimezone, returnFilterDateTime } from '../lib/timezoneHelpers';
import { postNotification } from '../../redux/notifications/actions';
import { TABLE_STATE } from '../constants/general';

const useStyles = makeStyles(theme => ({
	container: {
		fontSize: '18px',
		marginBottom: '30px',
	},
	contentWrapper: {
		overflowX: 'auto',
		marginTop: theme.customSpacing.secondary,
	},
	cardHeaderAction: {
		margin: '0',
	},
	noDataParagraph: {
		textAlign: 'center',
	},
	columnHeaders: {
		padding: '12px',
		whiteSpace: 'nowrap',
	},
	rowCells: {
		padding: '12px',
		whiteSpace: 'nowrap',
	},
	callId: {
		// maxWidth: "200px", // dots with a smaller link that displays a tip tool showing the whole call ID ?
		padding: '12px',
		whiteSpace: 'nowrap',
		overflowX: 'auto',
	},
	iconButtons: {
		padding: '2px 6px 2px 6px',
	},
	h4: {
		...theme.typography.h4,
	},
	h6: {
		...theme.typography.h6,
	},
}));

const UpcomingCalls = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	// Move calls data to redux?
	const [callsData, setCallsData] = useState(null);
	const [dataToRender, setDataToRender] = useState(null);
	const [tableData, setTableData] = useState([]);

	const websites = useSelector(state => state.website.websites);
	const widgets = useSelector(state => state.widget.widgets);
	const widgetTypes = useSelector(state => state.metaData.widgetTypes);
	const agents = useSelector(state => state.agents.data);
	const user = useSelector(state => state.auth.user);

	const [filterState, setFilterState] = useState({});
	const [page, setPage] = useState(1);
	const [loadingState, setLoadingState] = useState(TABLE_STATE.loading);

	const handlePageChange = (event, value) => {
		setLoadingState(TABLE_STATE.loading);
		setPage(value);
	};

	useEffect(() => {
		setLoadingState(TABLE_STATE.loading);
		async function getCallsData() {
			try {
				if (new Date(filterState.dateFrom) > new Date(filterState.dateTo)) {
					setLoadingState(TABLE_STATE.dateFilterError);
				} else {
					const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}/client/scheduled-calls`, {
						params: {
							page,
							perPage: defaultValues.CALLS_PER_PAGE,
							agentId: filterState?.agentId,
							websiteId: websites?.find(website => website?.domain === filterState?.Websites)?.id,
							dateFrom: filterState['Date from'],
							dateTo: filterState['Date to'],
							status: filterState?.Status === '' ? undefined : filterState?.Status?.toLowerCase(),
						},
					});
					const compareDateFrom = filterState.dateFrom ? filterState.dateFrom : defaultValues.UPCOMING_CALLS_DATE_FROM;
					const compareDateTo = filterState.dateTo ? filterState.dateTo : defaultValues.UPCOMING_CALLS_DATE_TO;
					const result = data.rows.filter(item => new Date(item.scheduledAt) >= new Date(compareDateFrom) &&
					new Date(item.scheduledAt) <= new Date(compareDateTo));
					setCallsData(result);

					if (!checkFilterState(filterState) && result.length < 1) {
						setLoadingState(TABLE_STATE.noDataWithActiveFilters);
					} else if (checkFilterState(filterState) && result.length < 1) {
						setLoadingState(TABLE_STATE.noData);
					} else {
						setLoadingState(TABLE_STATE.dataLoaded);
					}
				}
			} catch (error) {
				dispatch(postNotification(getMessage('UPCOMING_CALLS_GET_DATA_ERROR'), 'error'));
			}
		}
		getCallsData();
	}, [page, filterState, websites, widgetTypes, agents, dispatch]);

	useEffect(() => {
		if (callsData) {
			setTableData(callsData);
		}

		if (callsData?.count <= defaultValues.CALLS_PER_PAGE) setPage(1);
	}, [callsData]);

	useEffect(() => {
		const newTableData = {
			isLoading: loadingState,
			columnHeaderClasses: classes.columnHeaders,
			columnHeader: ['Website', 'Widget Type', 'Customer Number', 'Agent', 'Scheduled At', 'Widget Name', 'Custom Field #1', 'Custom Field #2', 'Custom Field #3'],
			rows: tableData?.map((call) => {
				const foundWidgetTypeId = widgets?.find(widget => widget?.id === call?.widgetId)?.typeId;
				const foundWidgetName = widgets?.find(widget => widget?.id === call?.widgetId)?.name;
				const foundWidgetTypeName = widgetTypes?.find(widgetType => widgetType?.id === foundWidgetTypeId)?.name;
				return ({
					id: call.id,
					cells: [
						{
							id: call.Website.domain,
							value: call.Website.domain,
							itemToRender: call.Website.domain,
							classes: classes.rowCells,
						},
						{
							id: call.widgetId,
							value: call.widgetId,
							itemToRender: <ColoredPaper value={foundWidgetTypeName} />,
							classes: classes.rowCells,
						},
						{
							id: call.customerNumber,
							value: call.customerNumber,
							itemToRender: call.customerNumber,
							classes: classes.rowCells,
						},
						{
							id: `callId ${call.id} agentId ${call?.agentId}`,
							value: agents.find(agent => agent.id === call.agentId)?.name,
							itemToRender: agents.find(agent => agent.id === call.agentId)?.name,
							classes: classes.rowCells,
						},
						{
							id: call.scheduledAt,
							value: call.scheduledAt,
							itemToRender: format(convertUTCToTimezone(call.scheduledAt, user.timezone), 'MM/dd/yyyy - hh:mm a'),
							classes: classes.rowCells,
						},
						{
							id: `widgetName ${call.id} ${foundWidgetName}`,
							value: foundWidgetName,
							itemToRender: foundWidgetName,
							classes: classes.rowCells,
						},
						{
							id: `customField #1 ${call.id} ${foundWidgetName}`,
							value: call.customFields?.customField1 ? call.customFields.customField1 : '/',
							itemToRender: call.customFields?.customField1 ? call.customFields.customField1 : '/',
							classes: classes.rowCells,
						},
						{
							id: `customField #2 ${call.id} ${foundWidgetName}`,
							value: call.customFields?.customField2 ? call.customFields.customField2 : '/',
							itemToRender: call.customFields?.customField2 ? call.customFields.customField2 : '/',
							classes: classes.rowCells,
						},
						{
							id: `customField #3 ${call.id} ${foundWidgetName}`,
							value: call.customFields?.customField3 ? call.customFields.customField3 : '/',
							itemToRender: call.customFields?.customField3 ? call.customFields.customField3 : '/',
							classes: classes.rowCells,
						},
					],
				});
			}),
		};

		setDataToRender(newTableData);
	}, [callsData, tableData, widgets, widgetTypes, classes, agents, loadingState, user.timezone]);

	return (
		<>
			<Box display="flex" p={0} my={2}>
				<Box width="100%">
					<p className={classes.h4}>Upcoming Calls</p>
					<p className={classes.h6}>Upcoming calls from clients.</p>
				</Box>
			</Box>
			<TableFilter
				setFilterState={setFilterState}
				filterItems={[
					{
						id: 'Websites',
						type: 'select',
						label: 'Websites',
						emptyValue: 'All',
						options: websites.map(website => website.domain),
					},
					{
						id: 'agentId',
						type: 'select',
						label: 'Agent',
						options: agents.filter(agent => agent.isRegular).map(agent => ({
							key: `${agent.id} ${agent.name}`,
							value: agent.id,
							label: agent.name,
						})),
						emptyValue: {
							key: 'all',
							value: null,
							label: 'All',
						},
					},
					{
						id: 'dateFrom',
						type: 'date',
						label: 'Date from',
						disablePast: true,
						defaultValue: returnFilterDateTime(new Date(), 'from'),
					},
					{
						id: 'dateTo',
						type: 'date',
						label: 'Date to',
						disablePast: true,
						defaultValue: returnFilterDateTime(defaultValues.UPCOMING_CALLS_DATE_TO, 'to'),
					},
				]}
			/>
			<Box className={classes.contentWrapper}>
				<TableGenerator tableData={dataToRender} />
			</Box>
			{tableData.length > 0 && (
				<Box className={classes.contentWrapper}>
					<Box display="flex" justifyContent="center">
						<Pagination
							defaultPage={1}
							page={page}
							onChange={handlePageChange}
							shape="rounded"
							count={Math.ceil(callsData.length / defaultValues.CALLS_PER_PAGE)}
						/>
					</Box>
				</Box>
			)}
		</>
	);
};

export default UpcomingCalls;
