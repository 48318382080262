// @ts-nocheck
/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';

// Material
import {
	FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, OutlinedInput,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { returnFilterDateTime } from '../../lib/timezoneHelpers';

const useStyles = makeStyles(theme => ({
	select: {
		...theme.completeStyles.inputDesign,
		[theme.breakpoints.up('xs')]: {
			width: '200px',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	notchedOutline: {
		'& legend': {
			maxWidth: '1000px',
		},
	},
}));

interface FilterItemOption {
	key: string
	label: string
	value: any
}

// TODO: Split date and string items to different interfaces
interface FilterItem {
	type: string,
	id: string,
	label: string,
	filterFunction?: (data: any, value: any) => any,
	options?: any[],
	defaultValue?: string | Date,
	emptyValue?: string | FilterItemOption,
	disablePast?: boolean,
	disableFuture?: boolean,
}
interface TableFilterProps {
	filterItems: FilterItem[],
	dataToFilter?: any,
	returnFilteredData?: (filteredData: any) => void,
	setFilterState?: (state: any) => any,
}

export const TableFilter: React.FC<TableFilterProps> = ({
	dataToFilter,
	filterItems,
	returnFilteredData,
	setFilterState,
}) => {
	const classes = useStyles();
	const [fieldValues, setFiledValues] = useState(filterItems.reduce((acc, currentObject) => ({
		...acc,
		[currentObject.id]: currentObject.defaultValue,
	}), {}));

	const filterData = useCallback(() => {
		const data = dataToFilter;
		return filterItems.reduce((
			accumulator: any[],
			value: FilterItem,
		) => accumulator?.filter((item: any) => {
			if (fieldValues[value.id] !== undefined && fieldValues[value.id] !== '' && fieldValues[value.id] !== value.emptyValue) {
				return value.filterFunction(item, fieldValues[value.id]);
			}
			return true;
		}), data);
	}, [fieldValues, dataToFilter]);

	// Return filter state to parent if necessary
	useEffect(() => {
		if (setFilterState) {
			setFilterState(fieldValues);
		}
	}, [fieldValues, setFilterState]);

	// Return filtered data to parent
	useEffect(() => {
		if (returnFilteredData) {
			returnFilteredData(filterData());
		}
	}, [filterData, returnFilteredData]);

	const handleOnChange = (event: any, key: string) => {
		let value = event?.target?.value !== undefined ? event.target.value : event;

		if (key === 'dateFrom') {
			value = returnFilterDateTime(value, 'from');
		}

		if (key === 'dateTo') {
			value = returnFilterDateTime(value, 'to');
		}

		setFiledValues((state: any) => {
			const newFieldValues = {
				...state,
				[key]: value,
			};
			return newFieldValues;
		});
	};

	const getOptionLabel = (option) => {
		if (option.label) {
			return option.label;
		}

		if (typeof option === 'boolean') {
			return option ? 'Active' : 'Inactive';
		}

		return option;
	};

	const renderFilterItems = (filterItem: FilterItem) => {
		if (filterItem.type === 'select') {
			return (
				<FormControl variant="outlined" className={classes.select} key={filterItem.id}>
					<InputLabel id={filterItem.id} shrink>{filterItem.label}</InputLabel>
					<Select
						defaultValue={filterItem.defaultValue || (typeof filterItem.emptyValue !== 'string' ? filterItem.emptyValue?.value : filterItem.emptyValue)}
						id={filterItem.id}
						onChange={event => handleOnChange(event, filterItem.id)}
						displayEmpty
						input={(
							<OutlinedInput
								label={filterItem.label}
								classes={{
									notchedOutline: classes.notchedOutline,
								}}
							/>
						)}
					>
						{filterItem.emptyValue ? (
							<MenuItem
								key={`${filterItem.id} none value`}
								value={typeof filterItem.emptyValue !== 'string' ? filterItem.emptyValue?.value : filterItem.emptyValue}
							>
								{typeof filterItem.emptyValue !== 'string' ? filterItem.emptyValue?.label : filterItem.emptyValue}
							</MenuItem>
						) : null}
						{filterItem.options.map(element => (
							<MenuItem
								key={element?.key !== undefined ? element.key : element}
								value={element?.value !== undefined ? element.value : element}
							>
								{ getOptionLabel(element) }
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		}
		if (filterItem.type === 'date') {
			return (
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						inputVariant="outlined"
						margin="none"
						className={classes.select}
						id={`date-picker-dialog ${filterItem.id}`}
						label={filterItem.label}
						format="MM/dd/yyyy"
						disablePast={filterItem.disablePast}
						disableFuture={filterItem.disableFuture}
						value={fieldValues?.[filterItem.id] || filterItem.defaultValue}
						onChange={event => handleOnChange(event, filterItem.id)}
						KeyboardButtonProps={{ 'aria-label': filterItem.label }}
					/>
				</MuiPickersUtilsProvider>
			);
		}
		return null;
	};

	return (
		<Grid container justify="center" spacing={2}>
			{filterItems?.map(item => (
				<Grid key={item.id} item xs={12} sm="auto">
					{renderFilterItems(item)}
				</Grid>
			))}
		</Grid>
	);
};

TableFilter.defaultProps = {
	setFilterState: null,
};
