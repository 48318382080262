// @ts-nocheck
import React, { useMemo } from 'react';

// Components
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	text: {
		marginBottom: '8px',
		...theme.completeStyles.inputDesign,
	},
	time: {
		minWidth: '80px',
	},
	setWidth: {
		width: '200px',
		[theme.breakpoints.down('xs')]: {
			minWidth: '100%',
			maxWidth: '100%',
		},
	},
	large: {
		width: '300px',
	},
}));

const TextFieldInput = React.memo((props: any) => {
	const {
		large,
		type,
		fullWidth,
	} = props;

	const classes = useStyles();
	const memoProps = useMemo(() => props, [props]);

	return (
		<TextField
			variant="outlined"
			className={`${classes.text} ${large && classes.large} ${type === 'time' && classes.time} ${!fullWidth && classes.setWidth}`}
			{...memoProps}
		/>
	);
});

export default TextFieldInput;
