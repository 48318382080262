import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	thankYouText: {
		fontSize: '26px',
		textAlign: 'center',
	},
});

export default useStyles;
