import React, { Suspense, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import * as initialFetch from '../redux/saga/initialFetchSaga';

// Types
import { RootStateType } from '../redux/rootReducer';

// Pages
import DashboardPage from './pages/Dashboard/DashboardPage';
import Calls from './pages/Calls';
import Widgets from './pages/Widgets/Widgets';
import Settings from './pages/Settings';
import Agents from './pages/Agents/Agents';
import Payments from './pages/Payments';
import Billing from './pages/Billing/Billing';
import VerifyEmail from '../app/modules/Auth/pages/VerifyEmail';
import AgentManager from './pages/AgentManager/AgentManager';
import WidgetManager from './pages/WidgetManger/WidgetManager';
import UpcomingCalls from './pages/UpcomingCalls';
import BillingAutoRechargeNote from './pages/Billing/BillingAutoRechargeNote';
import Websites from './pages/Websites/Websites';

export default function BasePage() {
	const dispatch = useDispatch();
	const areWebsitesLoaded = useSelector((state: RootStateType) => state.website.loaded);
	const areAgentsLoaded = useSelector((state: RootStateType) => state.agents.loaded);

	useEffect(() => {
		dispatch({ type: initialFetch.actionTypes.INITIAL_FETCH });
	}, [dispatch]);

	if (!areWebsitesLoaded || !areAgentsLoaded) {
		return <LayoutSplashScreen />;
	}

	return (
		<div className="convertmore">
			<Suspense fallback={<LayoutSplashScreen />}>
				<Switch>
					<Redirect exact from="/" to="/dashboard" />
					<Redirect exact from="/index.tsx" to="/dashboard" />
					<ContentRoute path="/dashboard" component={DashboardPage} />
					<ContentRoute path="/calls" component={Calls} />
					<ContentRoute path="/payments" component={Payments} />
					<ContentRoute exact path="/websites" component={Websites} />
					<ContentRoute exact path="/widgets" component={Widgets} />
					<ContentRoute exact path="/widgets/manage" component={WidgetManager} />
					<ContentRoute path="/widgets/manage/general/:id?" component={WidgetManager} />
					<ContentRoute path="/widgets/manage/design/:id?" component={WidgetManager} />
					<ContentRoute path="/widgets/manage/code/:id?" component={WidgetManager} />
					<ContentRoute exact path="/agents" component={Agents} />
					<ContentRoute exact path="/agents/manage/:agentId?" component={AgentManager} />
					<ContentRoute path="/settings" component={Settings} />
					<ContentRoute exact path="/billing" component={Billing} />
					<ContentRoute path="/billing/success" component={BillingAutoRechargeNote} />
					<ContentRoute path="/upcoming-calls" component={UpcomingCalls} />
					<ContentRoute path="/verify/:verificationCode" component={VerifyEmail} />
					<Redirect to="error" />
				</Switch>
			</Suspense>
		</div>
	);
}
