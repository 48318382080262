// @ts-nocheck
import React, { useMemo } from 'react';

// Components
import { makeStyles, MenuItem, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	select: {
		width: '200px',
		marginBottom: '8px',
		[theme.breakpoints.down('xs')]: {
			minWidth: '100%',
		},
		[theme.breakpoints.up('xs')]: {
			maxWidth: '200px',
		},
	},
	inputDesign: {
		...theme.completeStyles.inputDesign,
	},
}));

const SelectInput = React.memo((props: any) => {
	const {
		label, id, options, fullWidth, noAllOption, helperText, error,
	} = props;
	const classes = useStyles();
	const memoProps = useMemo(() => ({ helperText, error, props }), [props, helperText, error]);

	return (
		<TextField
			select
			variant="outlined"
			label={label}
			fullWidth={fullWidth}
			helperText={memoProps.helperText}
			error={memoProps.error}
			className={fullWidth ? `${classes.inputDesign}` : `${classes.inputDesign} ${classes.select}`}
			{...memoProps.props}
			inputProps={{ name: id }}
		>
			{!noAllOption && <MenuItem value=""><em>All</em></MenuItem>}
			{options.map(item => (
				<MenuItem key={item.key || item} value={item.value || item}>{item.label || item}</MenuItem>
			))}
		</TextField>
	);
});

export default SelectInput;
