import { withStyles } from '@material-ui/styles';
import { StepConnector } from '@material-ui/core';

export const WidgetBuilderConnector = withStyles(theme => ({
	alternativeLabel: {
		top: 25,
	},
	active: {
		'& $line': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	completed: {
		'& $line': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: theme.palette.grey[500],
		borderRadius: 1,
	},
}))(StepConnector);
