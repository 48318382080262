// @ts-nocheck
import { makeStyles, Snackbar, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, {
	useCallback, useState, useEffect, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { postNotification } from '../../../redux/notifications/actions';

const useStyles = makeStyles(theme => ({
	notification: {
		// position: 'relative',
		width: 'max-content',
		top: '67px',
		[theme.breakpoints.up('md')]: {
			top: '84px',
		},
		[theme.breakpoints.up('xs')]: {
			width: 'max-content',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			top: '60px !important',
			left: '16px !important',
			right: '0px !important',
			maxWidth: '100% !important',
			paddingRight: '32px',
		},
	},
	alert: {
		width: 'max-content',
		[theme.breakpoints.up('xs')]: {
			width: 'max-content',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
}));

function Notification({
	open, message, type, duration,
}) {
	const theme = useTheme();
	const classes = useStyles(theme);
	const [active, setActive] = useState(true);
	const ref = useRef();

	const dispatch = useDispatch();

	const handleClose = useCallback(() => {
		setActive(false);
		dispatch(postNotification('', undefined, undefined));
	}, [setActive, dispatch]);

	const useOutsideClick = (ref, callback) => {
		const handleClick = (e) => {
			if (ref.current && !ref.current.contains(e.target)) {
				callback();
			}
		};
		useEffect(() => {
			document.addEventListener('click', handleClick);
			return () => {
				document.removeEventListener('click', handleClick);
			};
		});
	};

	useOutsideClick(ref, () => {
		handleClose();
	});

	return (
	// anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		<Snackbar
			ref={ref}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			className={classes.notification}
			ClickAwayListenerProps={{ touchEvent: false, mouseEvent: false }}
			open={open && active}
			autoHideDuration={duration}
			onClose={handleClose}
		>
			{/* onClose={handleClose} */}
			<Alert className={classes.alert} onClose={handleClose} severity={type}>
				{message}
			</Alert>
		</Snackbar>
	);
}

export default Notification;
