// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';

// Types
import { RootStateType } from '../../../redux/rootReducer';

// Constants
import { DEFAULT_AGENT_STATE } from '../../constants/agentFormik';

// Components
import AgentManagerForm from './AgentManagerForm/AgentManagerForm';

const AgentManager = ({ match }) => {
	const agents = useSelector((state: RootStateType) => state.agents.data);

	let agent = DEFAULT_AGENT_STATE;
	if (match.params.agentId) {
		agent = agents.find(agent => agent.id === match.params.agentId);
	}

	return (
		<AgentManagerForm agent={agent} />
	);
};

export default AgentManager;
