import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import Cards from 'react-credit-cards';

// constants
import { getMessage } from '../../constants/messages';

// Style
import 'react-credit-cards/es/styles-compiled.css';
import { postNotification } from '../../../redux/notifications/actions';

type CreditCardElementType = {
	paymentMethod: Record<string, any>
	setCurrentPaymentMethod: (any) => void
}

const CreditCardElement: React.FC<CreditCardElementType> = (props) => {
	const {
		paymentMethod,
		setCurrentPaymentMethod,
	} = props;
	const dispatch = useDispatch();

	if (!paymentMethod) {
		return null;
	}

	const removeCreditCard = async () => {
		try {
			setCurrentPaymentMethod(null);
		} catch (error) {
			dispatch(postNotification(getMessage('PAYMENT_CREDIT_CARD_REMOVAL_ERROR'), 'error'));
		}
	};

	const displayMonth = () => `0${paymentMethod.card.exp_month}`.slice(-2);

	const cardState = {
		cvc: '',
		expiry: `${displayMonth()}/${paymentMethod.card.exp_year}`,
		focus: '',
		name: ' ',
		number: `**** **** **** ${paymentMethod.card.last4}`,
	};

	return (
		<div style={{ overflow: 'auto', width: 'fit-content' }}>
			<Cards
				preview
				cvc={cardState.cvc}
				expiry={cardState.expiry}
				focused={cardState.focus}
				name={cardState.name}
				number={cardState.number}
			/>
			<Grid container justify="flex-end">
				<Button onClick={removeCreditCard}>Remove card</Button>
			</Grid>
		</div>
	);
};

export default CreditCardElement;
