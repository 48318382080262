import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

// Metronic
import {
	Box, CircularProgress, Grid, Switch,
} from '@material-ui/core';

// Constants
import weekObjects from '../../constants/weekObject';
import { getMessage } from '../../constants/messages';
import URLS from '../../constants/urls';

// Types
import { RootStateType } from '../../../redux/rootReducer';
import { AGENTS_UPDATE_AGENT_SUCCESS, AGENTS_DELETE_AGENT_SUCCESS, AgentType } from '../../../redux/agents/agentTypes';

// Styles
import { agentsStyles } from './Agents.styles';

// Actions
import { postNotification } from '../../../redux/notifications/actions';
import { deleteAgent } from '../../../api';

// Component
import TableGenerator from '../../components/TableBuilder/TableGenerator';
import PrimaryButton from '../../components/_actions/PrimaryButton';
import TertiaryButton from '../../components/_actions/TertiaryButton';
import PromptDialog from '../../components/PromptDialog/PromptDialog';

const Agents = () => {
	const classes = agentsStyles();

	const dispatch = useDispatch();

	const agentsState = useSelector((state: RootStateType) => state.agents);
	const [tableData, setTableData] = useState({});
	const [completeAgents, setCompleteAgents] = useState([]);
	const [agentToDelete, setAgentToDelete] = useState(null);

	const handleActivityUpdate = async (agent: AgentType) => {
		try {
			// PROKLETE SAGE
			const json = await axios.put(`${process.env.REACT_APP_API_URL}${URLS.AGENTS}${agent.id}`, { ...agent, isActive: !agent.isActive });
			dispatch(
				{
					type: AGENTS_UPDATE_AGENT_SUCCESS,
					payload: json.data,
					meta: {
						errorMsg: 'Cannot update agent activity',
					},
				}
			);
			// put(updateAgentActivity({ ...agent, isActive: !agent.isActive }));
			dispatch(postNotification(getMessage('AGENT_STATUS_CHANGE_SUCCESS', { agentName: agent.name }), 'success'));
		} catch (error) {
			dispatch(postNotification(getMessage('AGENT_STATUS_CHANGE_ERROR'), 'error'));
		}
	};

	const handleAgentDelete = async (agent: AgentType) => {
		try	{
			await deleteAgent(agent.id);
			dispatch({
				type: AGENTS_DELETE_AGENT_SUCCESS,
				payload: agent,
			});
			dispatch(postNotification(`Agent ${agent.name} has been deleted successfully.`, 'success'));
		} catch (error) {
			dispatch(postNotification('Failed to delete agent.', 'error'));
		}
	};

	useEffect(() => {
		const agentsFormatted = (agentsState.data as AgentType[]).filter((agent: AgentType) => agent.name !== null);
		setCompleteAgents(agentsFormatted);

		const newTableData = {
			columnHeader: ['Name', 'Phone Number', 'Active', ''],
			rows: agentsFormatted.map(agent => ({
				id: agent.id,
				cells: [
					{
						id: agent.id,
						value: agent.name,
						itemToRender: agent.name,
					},
					{
						id: agent.phone,
						value: agent.phone,
						itemToRender: agent.phone,
					},
					{
						id: `status ${agent.id}`,
						value: agent.isActive,
						itemToRender: (
							<Switch
								checked={agent.isActive}
								value={agent.isActive}
								color="primary"
								inputProps={{ 'aria-label': 'switch widget' }}
								onClick={() => handleActivityUpdate(agent)}
							/>
						),
					},
					{
						id: `button ${agent.id}`,
						itemToRender: (
							<Grid container justify="center" spacing={2}>
								<Grid item>
									<NavLink
										exact
										to={{
											pathname: `/agents/manage/${agent.id}`,
											state: agent,
										}}
									>
										<TertiaryButton
											variantColor="blue"
										>
											Edit
										</TertiaryButton>
									</NavLink>
								</Grid>
								<Grid item>
									<TertiaryButton
										onClick={() => setAgentToDelete(agent)}
									>
										Delete
									</TertiaryButton>
								</Grid>
							</Grid>
						),
					},
				],
			})),
		};
		setTableData(newTableData);
	}, [agentsState, dispatch]); // open

	useEffect(() => {
		// Agent needs to have activeHours always set this on backend
		completeAgents.forEach((agent) => {
			if (!agent.availability.activeHours) {
				agent.availability.activeHours = weekObjects;
			}
		});
	}, [completeAgents]);

	return (
		<>
			{agentToDelete && (
				<PromptDialog
					isOpen
					handleClose={() => setAgentToDelete(null)}
					handleAccept={() => handleAgentDelete(agentToDelete)}
					title="Delete agent?"
					description="Are you sure you want to delete agent? This action is irreversible."
				/>
			)}
			<Box display="flex" p={0} my={2}>
				{/* spacing={2} */}
				<Grid container justify="space-between">
					<Grid item>
						<Box width="100%">
							<p className={classes.h4}>Your Agents</p>
							<p className={classes.h6}>Agents that you are managing.</p>
						</Box>
					</Grid>
					<Grid item className={classes.actionGridItem}>
						{!agentsState.loading && completeAgents?.length > 0 && (
							<Box className={classes.actionBox}>
								<NavLink exact to="/agents/manage">
									<PrimaryButton>Create New Agent</PrimaryButton>
								</NavLink>
							</Box>
						)}
					</Grid>
				</Grid>
			</Box>
			{ agentsState.loading && (
				<div className="d-flex justify-content-center">
					<CircularProgress size="3rem" color="primary" role="status" />
				</div>
			)}
			{ !agentsState.loading && completeAgents?.length > 0 && (
				<Box p={0} className={classes.contentWrapper}>
					<TableGenerator tableData={tableData} />
				</Box>
			)}
			{ !agentsState.loading && completeAgents?.length < 1 && (
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
				>
					<Grid item>
						<h4 className={classes.noDataParagraph}>You don’t have any added agent, yet.</h4>
					</Grid>
					<br />
					<Grid item>
						<NavLink exact to="/agents/manage">
							<PrimaryButton>Create New Agent</PrimaryButton>
						</NavLink>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default Agents;
