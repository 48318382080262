// @ts-nocheck
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { createNewPassword } from '../_redux/authCrud';
import TextFieldInput from '../../../components/_inputs/TextFieldInput';
// import { Button } from "@material-ui/core";
import SecondaryButton from '../../../components/_actions/SecondaryButton';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { getMessage } from '../../../constants/messages';

const useStyles = makeStyles({
	actionBox: {
		marginBottom: '1.429rem',
	},
	actionGridItem: {
		alignSelf: 'flex-end',
	},
});

const initialValues = {
	password: '',
	confirmPassword: '',
};

function NewPassword(props) {
	const classes = useStyles();
	const { intl } = props;
	const [isRequested, setIsRequested] = useState(false);
	const NewPasswordSchema = Yup.object().shape({
		password: Yup.string()
			.min(5, getMessage('MIN_FIELD_LENGTH', { length: 5 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
		confirmPassword: Yup.string()
			.required(getMessage('REQUIRED_FIELD'))
			.when('password', {
				is: val => (!!(val && val.length > 0)),
				then: Yup.string().oneOf(
					[Yup.ref('password')],
					getMessage('ACCOUNT_SETTINGS_PASSWORD_NOT_MATCHING')
				),
			}),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: NewPasswordSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			createNewPassword(props.match.params.verificationCode, values.password, true)
				.then(() => {
					setIsRequested(true);
				})
				.catch(() => {
					setIsRequested(false);
					setSubmitting(false);
					setStatus(
						intl.formatMessage(
							{ id: 'AUTH.VALIDATION.NOT_FOUND' },
							{ name: 'Placeholder' } // soon to implement properly formatted server error messages
						)
					);
				});
		},
	});

	return (
		<>
			{isRequested && <Redirect to="/auth" />}
			{!isRequested && (
				<div className="login-form login-forgot" style={{ display: 'block' }}>
					<div className="text-center mb-10 mb-lg-20">
						<h3 className="font-size-h1">New Password ?</h3>
						<div className="text-muted font-weight-bold">
							Enter your new password
						</div>
					</div>
					<form
						onSubmit={formik.handleSubmit}
						className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
					>
						{formik.status && (
							<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
								<div className="alert-text font-weight-bold">
									{formik.status}
								</div>
							</div>
						)}

						<TextFieldInput
							label="New Password"
							name="password"
							type="password"
							{...formik.getFieldProps('password')}
							fullWidth
							error={formik.touched.password && !!formik.errors.password}
							helperText={(formik.touched.password && formik.errors.password) || ' '}
						/>
						<TextFieldInput
							label="Confirm New Password"
							name="confirmPassword"
							type="password"
							{...formik.getFieldProps('confirmPassword')}
							fullWidth
							error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
							helperText={(formik.touched.confirmPassword && formik.errors.confirmPassword) || ' '}
						/>
						<Box display="flex" p={0} my={2}>
							<Grid container justify="center" spacing={2}>
								<Grid item>
									<Box width="100%">
										<SecondaryButton
											variantColor="orange"
											type="submit"
											disabled={
												formik.isSubmitting ||
                                                !formik.isValid ||
                                                formik.values.password === '' ||
                                                formik.values.confirmPassword === ''
											}
											// className="font-weight-bold px-9 py-4 my-3 mx-4" // btn btn-primary
										>
											<span>Submit</span>
											{formik.isSubmitting && <span className="ml-3 spinner spinner-white" />}
										</SecondaryButton>
									</Box>
								</Grid>
								<Grid item className={classes.actionGridItem}>
									<Box className={classes.actionBox}>
										<Link
											to="/auth/login"
											href="/auth/login"
											className="ml-4"
										>
											<SecondaryButton
												variantColor="orange"
												type="button"
												className="font-weight-bold px-9 py-4" // btn btn-primary
											>
												<span>Cancel</span>
											</SecondaryButton>
										</Link>

									</Box>
								</Grid>
							</Grid>
						</Box>
					</form>
				</div>
			)}
		</>
	);
}

export default injectIntl(connect(null, null)(NewPassword));
