import React, { useState } from 'react';
import { Box, ClickAwayListener } from '@material-ui/core';
import { SketchPicker } from 'react-color';

import './style.scss';
import TertiaryButton from '../_actions/TertiaryButton';

type ColorPickerType = {
	selectedColor: string
	onSave: (string) => void,
};

const ColorPicker: React.FC<ColorPickerType> = React.memo((props) => {
	const {
		selectedColor,
		onSave,
	} = props;

	const [color, setColor] = useState(selectedColor);
	const [showColorPicker, setShowColorPicker] = useState(false);

	return (
		<ClickAwayListener
			mouseEvent="onMouseUp"
			onClickAway={() => {
				setShowColorPicker(false);
				setColor(selectedColor);
			}}
		>
			<div style={{ position: 'relative' }}>
				<Box
					border="1px solid black"
					borderRadius="4px"
					style={{ backgroundColor: color, cursor: 'pointer' }}
					onClick={() => setShowColorPicker(true)}
					height="20px"
					width="20px"
					marginBottom="3px"
				/>
				{showColorPicker && (
					<div className="color-picker">
						<SketchPicker
							color={color}
							onChangeComplete={(event) => {
								setColor(event.hex);
							}}
							onChange={(event) => {
								setColor(event.hex);
							}}
						/>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-evenly',
								padding: '20px 0px',
							}}
						>
							<TertiaryButton
								variant="contained"
								variantColor="blue"
								size="medium"
								onClick={(e) => {
									e.preventDefault();
									onSave(color);
									setShowColorPicker(false);
								}}
							>
								Apply
							</TertiaryButton>
							<TertiaryButton
								variant="contained"
								variantColor="orange"
								size="medium"
								onClick={() => {
									setShowColorPicker(false);
									setColor(selectedColor);
								}}
							>
								Cancel
							</TertiaryButton>
						</div>
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
});

export default ColorPicker;
