import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import TextFieldInput from '../../../../../components/_inputs/TextFieldInput';

const TimedOptions = React.memo((props: any) => {
	const {
		baseOptionsFormik,
	} = props;

	return (
		<Grid item>
			<br />
			<Typography variant="h5">
				<Box fontWeight="fontWeightBold">
					Timed popup behavior
				</Box>
			</Typography>
			<br />
			<div>
				<TextFieldInput
					id="showsAfter"
					name="showsAfter"
					label="Shows after (seconds)"
					placeholder="Shows after (seconds)"
					type="number"
					onChange={baseOptionsFormik.handleChange}
					value={baseOptionsFormik.values.showsAfter}
					onBlur={baseOptionsFormik.handleBlur}
					error={Boolean(baseOptionsFormik.errors.showsAfter)}
					helperText={baseOptionsFormik.errors.showsAfter || ' '}
				/>
			</div>
			<div>
				<TextFieldInput
					id="repeatAfter"
					label="Repeat after (seconds)"
					placeholder="Repeat after (seconds)"
					type="number"
					name="repeatAfter"
					onChange={baseOptionsFormik.handleChange}
					value={baseOptionsFormik.values.repeatAfter}
					onBlur={baseOptionsFormik.handleBlur}
					error={Boolean(baseOptionsFormik.errors.repeatAfter)}
					helperText={baseOptionsFormik.errors.repeatAfter || ' '}
				/>
			</div>
			<div>
				<TextFieldInput
					id="displaysPerVisit"
					label="Display per visit (times)"
					placeholder="Display per visit (times)"
					type="number"
					onChange={baseOptionsFormik.handleChange}
					onBlur={baseOptionsFormik.handleBlur}
					value={baseOptionsFormik.values.displaysPerVisit}
					error={Boolean(baseOptionsFormik.errors.displaysPerVisit)}
					helperText={baseOptionsFormik.errors.displaysPerVisit || ' '}
				/>
			</div>
		</Grid>
	);
});

export default TimedOptions;
