import {
	REQUEST_NOTIFICATION,
	POST_NOTIFICATION, FLUSH_NOTIFICATION,
} from './actions';

const notificationsReducer = (state = { message: '', type: '', extraAction: '' }, action) => {
	switch (action.type) {
		case REQUEST_NOTIFICATION:
			return { ...state };
		case POST_NOTIFICATION:
			return {
				message: action.payload.message,
				type: action.payload.type,
				extraAction: action.payload.extraAction,
			};
		case FLUSH_NOTIFICATION:
			return {
				message: '',
				type: '',
				extraAction: '',
			};
		default:
			return state;
	}
};

export default notificationsReducer;
