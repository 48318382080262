// @ts-nocheck
import React, { useRef } from 'react';

// Components
import { Box, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Publish } from '@material-ui/icons';

const StyledTextField = withStyles({
	root: {
		width: '100%',
	},
})(TextField);

const StyledBox = withStyles({
	root: {
		width: '300px',
		maxWidth: '100%',
		padding: 0,
		border: 'none',
		marginBottom: '8px',
		boxShadow: 'none',
		backgroundColor: 'transparent',
		textTransform: 'none',
	},
})(Box);

function FileUpload({
	disable, onChange, fullWidth, ...restProps
}) {
	const inputRef = useRef(null);
	const { accept = 'image/*' } = restProps;

	return (
		<StyledBox
			style={{ width: fullWidth && '100%' }}
			disabled={disable}
		>
			<input
				ref={inputRef}
				accept={accept}
				type="file"
				hidden
				onChange={event => onChange(event.target.files[0])}
			/>
			<StyledTextField
				fullWidth={fullWidth}
				disabled={disable}
				inputProps={{ style: { cursor: 'pointer' } }}
				// eslint-disable-next-line react/jsx-no-duplicate-props
				InputProps={{
					endAdornment: (<Publish style={{ cursor: 'pointer' }} />),
					style: { cursor: 'pointer' },
					onClick: () => {
						if (!disable) { inputRef.current.click(); }
					},
				}}
				variant="outlined"
				type="text"
				readOnly
				{...restProps}
			/>
		</StyledBox>
	);
}

export default FileUpload;
