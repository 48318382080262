/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';

import {
	Box,
	CardContent,
	Grid,
	LinearProgress,
	Switch,
	Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Constants
import { ALL_WIDGETS_DATA, DEVICE_DISPLAY_OPTIONS, SHOW_SCHEDULE_OPTIONS } from '../../../../constants/widgetBuilder';

// Types
import { RootStateType } from '../../../../../redux/rootReducer';

// Components
import SelectInput from '../../../../components/_inputs/SelectInput';
import TextFieldInput from '../../../../components/_inputs/TextFieldInput';
import AutocompleteMultiple from '../../../../components/_inputs/AutocompleteMultiple';
import InputWithSwitch from '../../../../components/_inputs/InputWithSwitch';
import PhoneNumberInput from '../../../../components/_inputs/PhoneNumberInput';
import SecondaryButton from '../../../../components/_actions/SecondaryButton';
import BaseOptions from './GeneralComponents/BaseOptions';
import TimedOptions from './GeneralComponents/TimedOptions';
import WeeklyPickers from '../../../../components/AvailabilityPicker/WeeklyPickers';
import FileUpload from '../../../../components/_inputs/FileUpload';

// Api
import { getUserFiles } from '../../../../../api';

function GeneralStep(props) {
	const {
		baseOptionsFormik,
		agentOptionsFormik,
		customCallerIdFormik,
		notificationOptionsFormik,
		redirectionOptionsFormik,
		showScheduleOptionsFormik,
		waitingAudioOptionsFormik,
		deviceDisplayOptionsFormik,
		websiteUrlFormik,
		retryOptionsFormik,
		validateForms,
		activeHoursRef,
		activeHours,
		isNewWidget,
	} = props;

	const history = useHistory();

	const { id } = useParams<Record<'id', string>>();

	const websites = useSelector((state: RootStateType) => state.website.websites);
	let widgetTypes = useSelector((state: RootStateType) => state.metaData.widgetTypes);
	const displayCustomNumberToCustomers = useSelector((state:any) => state.auth.user.customCallerIdSettings?.displayCustomNumberToCustomers);

	let widgetTypeIndexModifier = 0; // we use this to modify index of the widgets for if's later

	if (isNewWidget) {
		widgetTypes = [ALL_WIDGETS_DATA, ...widgetTypes];
		widgetTypeIndexModifier = 1;
	}
	const callingRules = useSelector((state: RootStateType) => state.metaData.callingRules);
	const agents = useSelector((state: RootStateType) => state.agents.data);

	// @ts-ignore
	const user = useSelector((state: RootStateType) => state.auth.user);
	const hasAdvancedSettings = user?.hasAdvancedWidgetSettings;

	const websiteOptions = useMemo(() => websites.map(websiteOption => websiteOption.domain), [websites]);
	const agentsOptions = useMemo(() => agents.filter(agent => (agent.name !== null)).map(agent => agent.name), [agents]);

	const widgetTypeOptions = useMemo(() => widgetTypes.map(widgetType => widgetType.name), widgetTypes);

	const initShowScheduleValue = SHOW_SCHEDULE_OPTIONS.find(
		option => option.duringWorking === showScheduleOptionsFormik.values.duringWorkingHours
		&& option.outsideWorking === showScheduleOptionsFormik.values.outsideWorkingHours
	)?.value;

	const initDeviceDisplayValue = DEVICE_DISPLAY_OPTIONS.find(
		option => option.onDesktop === deviceDisplayOptionsFormik.values.onDesktop
			&& option.onMobile === deviceDisplayOptionsFormik.values.onMobile
	)?.value;

	const [showScheduleValue, setShowScheduleValue] = useState(initShowScheduleValue);
	const [deviceDisplayValue, setDeviceDisplayValue] = useState(initDeviceDisplayValue);

	const [userFiles, setUserFiles] = useState(null);
	const [audioFile, setAudioFile] = useState(null);
	const [audioFileProgress, setAudioFileProgress] = useState(0);
	const [audioFileErrors, setAudioFileErrors] = useState('');

	useEffect(() => {
		if (!userFiles) {
			getUserFiles().then(data => setUserFiles(data));
		}
	}, [userFiles]);

	useEffect(() => {
		if (userFiles && userFiles.length > 0) {
			const waitingAudioFile = userFiles.find((image) => {
				const url = new URL(image.url);
				return `${url.origin}${url.pathname}` === waitingAudioOptionsFormik.getFieldProps('url').value;
			});
			setAudioFile(waitingAudioFile);
		}
	}, [userFiles]);

	useEffect(() => {
		const { duringWorkingHours, outsideWorkingHours } = showScheduleOptionsFormik.values;

		setShowScheduleValue(
			SHOW_SCHEDULE_OPTIONS.find(option => option.duringWorking === duringWorkingHours
			&& option.outsideWorking === outsideWorkingHours)?.value
		);
	}, [showScheduleOptionsFormik.values]);

	useEffect(() => {
		const { onDesktop, onMobile } = deviceDisplayOptionsFormik.values;

		setDeviceDisplayValue(
			DEVICE_DISPLAY_OPTIONS.find(option => option.onDesktop === onDesktop
				&& option.onMobile === onMobile)?.value
		);
	}, [deviceDisplayOptionsFormik.values]);

	function handleWidgetTypeSelect(event) {
		if (event.target.value !== widgetTypes[1 + widgetTypeIndexModifier].name) {
			baseOptionsFormik.setFieldValue('showsAfter', '', false);
			baseOptionsFormik.setFieldValue('repeatAfter', '', false);
			baseOptionsFormik.setFieldValue('displaysPerVisit', '', false);
		}
	}

	function handleUseAgentSwitch(value) {
		if (value) {
			agentOptionsFormik.setFieldValue('agentNumber', '', false);
		} else {
			agentOptionsFormik.setFieldValue('agents', [], false);
			agentOptionsFormik.setFieldValue('callingRule', '', false);
		}
	}

	function handleRedirectionSwitchOnClick(value, field) {
		if (!value) {
			redirectionOptionsFormik.setFieldValue(field, '', false);
		}
	}

	function handleSwitchOnClick(value, field) {
		if (!value) {
			notificationOptionsFormik.setFieldValue(field, '', false);
		}
	}

	function handleShowScheduleChange(value) {
		const selectedValue = SHOW_SCHEDULE_OPTIONS.find(option => option.value === value);

		showScheduleOptionsFormik.setFieldValue('duringWorkingHours', selectedValue.duringWorking, false);
		showScheduleOptionsFormik.setFieldValue('outsideWorkingHours', selectedValue.outsideWorking, false);
	}

	function handleDeviceDisplayChange(value) {
		const selectedValue = DEVICE_DISPLAY_OPTIONS.find(option => option.value === value);
		deviceDisplayOptionsFormik.setFieldValue('onDesktop', selectedValue.onDesktop, false);
		deviceDisplayOptionsFormik.setFieldValue('onMobile', selectedValue.onMobile, false);
	}

	async function fileUpload(file) {
		setAudioFileErrors('');
		if (file) {
			if (file.size > 5000001) {
				// if file size i bigger than 5MB.
				setAudioFileErrors('The image size limit is 5MB.');
				return;
			}
			try {
				setAudioFileProgress(1);

				const formData = new FormData();
				formData.append(file.name, file);

				const options = {
					onUploadProgress: (progressEvent) => {
						const { loaded, total } = progressEvent;
						const percent = Math.floor((loaded * 100) / total);
						if (percent < 100) {
							setAudioFileProgress(percent);
						}
					},
				};

				// @ts-ignore
				const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/user/files`, formData, options, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
				const audioFile = data.find(item => item.filename === file.name);
				setAudioFileProgress(100);
				const url = new URL(audioFile?.url);
				waitingAudioOptionsFormik.setFieldValue('url', `${url.origin}${url.pathname}`);

				setAudioFile(audioFile);

				console.log({ audioFile });
				setUserFiles([...userFiles, audioFile]);
			} catch (error) {
				// dispatch(postNotification(getMessage('WIDGET_UPLOAD_IMAGE_ERROR'), 'error'));
			}
		} else {
			waitingAudioOptionsFormik.setFieldValue('url', '');
		}
		setAudioFileProgress(0);
	}

	return (
		<CardContent>
			<br />
			<br />
			<Grid container>
				<BaseOptions
					baseOptionsFormik={baseOptionsFormik}
					websiteUrlFormik={websiteUrlFormik}
					handleWidgetTypeSelect={handleWidgetTypeSelect}
					websiteOptions={websiteOptions}
					widgetTypeOptions={widgetTypeOptions}
				/>
				<Grid item xs={12}>
					{(baseOptionsFormik.values.widgetType === widgetTypes[1 + widgetTypeIndexModifier].name || (isNewWidget && baseOptionsFormik.values.widgetType === widgetTypes[0].name)) && (
						<TimedOptions baseOptionsFormik={baseOptionsFormik} />
					)}
				</Grid>
				<Grid item style={{ width: '615px', maxWidth: '100%' }}>
					<Grid container>
						<Grid item>
							<br />
							<Typography variant="h5">
								<Box fontWeight="fontWeightBold">
									Numbers to call
								</Box>
							</Typography>
							<p>Enter number of a person who is responsible for taking calls or use agents instead.</p>
						</Grid>
						{!agentOptionsFormik.values.useAgents && (
							<PhoneNumberInput
								id="agentNumber"
								label="Number"
								placeholder="Number"
								name="agentNumber"
								onDataChange={agentOptionsFormik.setFieldValue}
								onFieldBlur={agentOptionsFormik.setFieldTouched}
								value={agentOptionsFormik.values.agentNumber}
								error={Boolean(agentOptionsFormik.errors.agentNumber)}
								helperText={agentOptionsFormik.errors.agentNumber || ' '}
								country={user.country}
							/>
						)}
						{agentOptionsFormik.values.useAgents && (
							<Grid container justify="space-between">
								<Grid item>
									<AutocompleteMultiple
										initialValue={agentOptionsFormik.values.agents}
										options={agentsOptions}
										id="agents"
										setFormikValue={value => agentOptionsFormik.setFieldValue('agents', value)}
										textInputProps={{
											id: 'agents',
											label: 'Agents',
											placeholder: 'Agents',
											type: 'tel',
											name: 'agents',
											onBlur: agentOptionsFormik.handleBlur,
											error: Boolean(agentOptionsFormik.errors.agents),
											helperText: agentOptionsFormik.errors.agents || ' ',
										}}
									/>
								</Grid>
								<Grid item>
									<SelectInput
										noAllOption
										id="callingRule"
										label="Calling rule"
										name="callingRule"
										options={callingRules.map(callingRule => callingRule.name)}
										value={agentOptionsFormik.values.callingRule}
										onChange={agentOptionsFormik.handleChange}
										onBlur={agentOptionsFormik.handleBlur}
										error={Boolean(agentOptionsFormik.errors.callingRule)}
										helperText={agentOptionsFormik.errors.callingRule || ' '}
									/>
								</Grid>
							</Grid>
						)}
					</Grid>
					<Box fontSize="18px" marginBottom="13px">
						<Switch
							color="primary"
							{...agentOptionsFormik.getFieldProps('useAgents')}
							checked={agentOptionsFormik.getFieldProps('useAgents').value}
							onClick={(event: any) => handleUseAgentSwitch(event.target.checked)}
						/>
						Use agents
					</Box>
				</Grid>
				<Grid item xs={false} md={1} />
				<Grid item xs={12}>
					<br />
					<Typography variant="h5">
						<Box fontWeight="fontWeightBold">
							Retry calls if nobody answers
						</Box>
					</Typography>
					<br />
					<TextFieldInput
						id="numberOfTries"
						label="Number of tries"
						placeholder="Number of tries (seconds)"
						type="text"
						value={retryOptionsFormik.values.numberOfTries}
						onChange={retryOptionsFormik.handleChange}
						onBlur={retryOptionsFormik.handleBlur}
						error={Boolean(retryOptionsFormik.errors.numberOfTries)}
						helperText={retryOptionsFormik.errors.numberOfTries || ' '}
					/>
					<Grid item xs={false} md={1} />
					<TextFieldInput
						id="minutesBetweenTries"
						label="Seconds in between"
						placeholder="Seconds in between"
						type="text"
						name="minutesBetweenTries"
						value={retryOptionsFormik.values.minutesBetweenTries}
						onChange={retryOptionsFormik.handleChange}
						onBlur={retryOptionsFormik.handleBlur}
						error={Boolean(retryOptionsFormik.errors.minutesBetweenTries)}
						helperText={retryOptionsFormik.errors.minutesBetweenTries || ' '}
					/>
				</Grid>
				<br />
				<Grid item xs={12}>
					<br />
					<Typography variant="h5">
						<Box fontWeight="fontWeightBold">
							Times available for calls
						</Box>
					</Typography>
					<br />
					<WeeklyPickers
						activeHours={activeHours}
						ref={activeHoursRef}
					/>
				</Grid>
				<Grid item xs={12} />
				<Grid item xs={12}>
					<br />
					<br />
					<Typography variant="h5">
						<Box fontWeight="fontWeightBold">
							When to display “Schedule a call” option?
						</Box>
					</Typography>
					<br />
					<SelectInput
						id="showScheduleSelect"
						noAllOption
						options={SHOW_SCHEDULE_OPTIONS}
						value={showScheduleValue}
						onChange={(event) => { handleShowScheduleChange(event.target.value); }}
					/>
				</Grid>
				<Grid item xs={12} style={{ maxWidth: '100%' }}>
					<div>
						<br />
						<Typography variant="h5">
							<Box fontWeight="fontWeightBold">
								Notification options
							</Box>
						</Typography>
						<p>Get a notification when a call is scheduled, received or missed.</p>
						<br />
						<InputWithSwitch
							switchProps={{
								checked: notificationOptionsFormik.values?.phoneNumber?.isActive,
								...notificationOptionsFormik.getFieldProps('phoneNumber.isActive'),
								onClick: event => handleSwitchOnClick(event.target.checked, 'phoneNumber.value'),
								color: 'primary',
								inputProps: { 'aria-label': 'switch widget' },
							}}
							element={(
								<PhoneNumberInput
									id="phoneNumber.value"
									label="Phone number"
									placeholder="Phone number"
									name="phoneNumber.value"
									onDataChange={notificationOptionsFormik.setFieldValue}
									onFieldBlur={notificationOptionsFormik.setFieldTouched}
									value={notificationOptionsFormik.values.phoneNumber.value}
									disabled={!notificationOptionsFormik.values?.phoneNumber?.isActive}
									error={Boolean(notificationOptionsFormik.errors?.phoneNumber?.value)}
									helperText={notificationOptionsFormik.errors?.phoneNumber?.value || ' '}
									country={user.country}
								/>
							)}
						/>
					</div>
					<div>
						<InputWithSwitch
							switchProps={{
								checked: notificationOptionsFormik.values?.email?.isActive,
								...notificationOptionsFormik.getFieldProps('email.isActive'),
								onClick: event => handleSwitchOnClick(event.target.checked, 'email.value'),
								color: 'primary',
								inputProps: { 'aria-label': 'switch widget' },
							}}
							textFieldProps={{
								id: 'email.value',
								label: 'E-mail',
								placeholder: 'E-mail',
								type: 'text',
								large: true,
								disabled: !notificationOptionsFormik.values?.email?.isActive,
								...notificationOptionsFormik.getFieldProps('email.value'),
								error: Boolean(notificationOptionsFormik.errors?.email?.value),
								helperText: notificationOptionsFormik.errors?.email?.value || ' ',
							}}
						/>
					</div>
				</Grid>
				<Grid>
					<div>
						<br />
						<Typography variant="h5">
							<Box fontWeight="fontWeightBold">
								Redirection options
							</Box>
						</Typography>
						<div>Redirect visitors to a custom URL after filling the call now or schedule call form.</div>
						<div> For tracking conversion using JS events visit this link.</div>
						<br />
						<br />

						<InputWithSwitch
							switchProps={{
								checked: redirectionOptionsFormik.values.callNow.isActive,
								...redirectionOptionsFormik.getFieldProps('callNow.isActive'),
								onClick: event => handleRedirectionSwitchOnClick(event.target.checked, 'callNow.value'),
								color: 'primary',
								inputProps: { 'aria-label': 'switch widget' },
							}}
							textFieldProps={{
								id: 'callNow.value',
								label: '"Call Now" Redirection URL',
								name: 'callNow',
								placeholder: '"Call Now" Redirection URL',
								type: 'text',
								large: true,
								disabled: !redirectionOptionsFormik.values.callNow.isActive,
								...redirectionOptionsFormik.getFieldProps('callNow.value'),
								error: Boolean(redirectionOptionsFormik.errors.callNow?.value),
								helperText: redirectionOptionsFormik.errors.callNow?.value || ' ',
							}}
						/>
					</div>
					<div>
						<InputWithSwitch
							switchProps={{
								checked: redirectionOptionsFormik.values.scheduleCall.isActive,
								...redirectionOptionsFormik.getFieldProps('scheduleCall.isActive'),
								onClick: event => handleRedirectionSwitchOnClick(event.target.checked, 'scheduleCall.value'),
								color: 'primary',
								inputProps: { 'aria-label': 'switch widget' },
							}}
							textFieldProps={{
								id: 'scheduleCall.value',
								label: '"Schedule a Call" Redirection URL',
								name: 'scheduleCall',
								placeholder: '"Schedule a Call" Redirection URL',
								type: 'text',
								large: true,
								disabled: !redirectionOptionsFormik.values.scheduleCall.isActive,
								...redirectionOptionsFormik.getFieldProps('scheduleCall.value'),
								error: Boolean(redirectionOptionsFormik.errors.scheduleCall?.value),
								helperText: redirectionOptionsFormik.errors.scheduleCall?.value || ' ',
							}}
						/>
					</div>
					<div>
						<br />
						<Typography variant="h5">
							<Box fontWeight="fontWeightBold">
								Custom audio message
							</Box>
						</Typography>
						<div>Set an audio message that users will hear while wait for your agents to answer.</div>
						<br />
						<Grid item xs={12} sm="auto">
							<Box ml={8} mb={3}>
								{audioFileErrors !== '' &&
								<Alert severity="error">{audioFileErrors}</Alert>}
							</Box>
						</Grid>
						<InputWithSwitch
							switchProps={{
								checked: waitingAudioOptionsFormik.values.isActive,
								...waitingAudioOptionsFormik.getFieldProps('isActive'),
								onClick: (event) => {
									waitingAudioOptionsFormik.setFieldValue('isActive', event.target.checked);
								},
								color: 'primary',
								inputProps: { 'aria-label': 'switch' },
							}}
							element={(
								<FileUpload
									label="Upload audio file"
									accept="audio/mpeg,audio/wav,audio/wave,audio/x-wav,audio/aiff,audio/x-aifc,audio/x-aiff,audio/x-gsm,audio/gsm,audio/ulaw"
									disable={!waitingAudioOptionsFormik.values.isActive}
									{...waitingAudioOptionsFormik.getFieldProps('url')}
									value={audioFile?.filename || ''}
									onChange={file => fileUpload(file)}
									error={!!waitingAudioOptionsFormik.errors.url}
									helperText={waitingAudioOptionsFormik.errors.url}
								/>
							)}
						/>
						{audioFileProgress > 0 && (
							<Box mb={3} ml={8}>
								<span>
									{audioFileProgress}
									%
								</span>
								<LinearProgress variant="determinate" value={audioFileProgress} />
							</Box>
						)}
					</div>
					{hasAdvancedSettings && (
						<Grid item xs={12}>
							<br />
							<Typography variant="h5">
								<Box fontWeight="fontWeightBold">
									Devices
								</Box>
							</Typography>
							<div>Select devices you want your widget to be displayed on.</div>
							<br />
							<SelectInput
								id="showScheduleSelect"
								noAllOption
								options={DEVICE_DISPLAY_OPTIONS}
								value={deviceDisplayValue}
								onChange={(event) => { handleDeviceDisplayChange(event.target.value); }}
							/>
						</Grid>
					)}
					{displayCustomNumberToCustomers && (
						<Grid item xs={12} style={{ maxWidth: '100%' }}>
							<div>
								<br />
								<Typography variant="h5">
									<Box fontWeight="fontWeightBold">
										Custom caller id
									</Box>
								</Typography>
								<p>Enter phone number that will be shown as a caller id to customers</p>
								<br />
								<PhoneNumberInput
									id="customCallerId.value"
									label="Phone number"
									placeholder="Phone number"
									name="customCallerId.value"
									onDataChange={customCallerIdFormik.setFieldValue}
									onFieldBlur={customCallerIdFormik.setFieldTouched}
									value={customCallerIdFormik.values.customCallerId?.value || ''}
									error={Boolean(customCallerIdFormik.errors?.customCallerId?.value)}
									helperText={customCallerIdFormik.errors?.customCallerId?.value || ' '}
									country={user.country}
								/>
							</div>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid container justify="center" spacing={2}>
				<Grid item>
					<SecondaryButton
						variantColor="orange"
						onClick={() => {
							validateForms(0).then(isValid => isValid && history.push(`/widgets/manage/design/${id || ''}`));
							return false;
						}}
					>
						Next - Design Step
					</SecondaryButton>
				</Grid>
			</Grid>
		</CardContent>
	);
}

export default GeneralStep;
