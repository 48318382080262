// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
	Link, Switch, Redirect, useLocation,
} from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ContentRoute } from '../../../../_metronic/layout';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import NewPassword from './NewPassword';
import VerifyEmail from './VerifyEmail';
import ThankYou from './ThankYou/ThankYou';
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import { makeStyles, useTheme } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	promptText: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '18px',
		},
	},
}));

export function AuthPage() {
	const today = new Date().getFullYear();

	const theme = useTheme();
	const classes = useStyles(theme);

	const location = useLocation();

	return (
		<div className="d-flex flex-column flex-root">
			{/* begin::Login */}
			<div
				className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
				id="kt_login"
			>
				{/* begin::Content */}
				<div className="flex-row-fluid d-flex flex-column position-relative p-7">
					{/* begin::Content header */}
					<div className="">
						<Link to="/" className="flex-column-auto">
							<img
								alt="Logo"
								className="max-h-40px max-h-sm-50px"
								style={{ maxWidth: '70%' }}
								src={toAbsoluteUrl('/media/logos/logo_blue_final.svg')}
							/>
						</Link>
					</div>
					{location.pathname !== '/auth/thank-you' && (
						<div className={`text-center text-sm-right mt-10 ${classes.promptText}`}>
							{location.pathname === '/auth/registration' ? (
								<>
									<span className="font-weight-bold text-dark-50">Already have an account?</span>
									<Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup">Sign In!</Link>
								</>
							) : (
								<>
									<span className="font-weight-bold text-dark-50">Don&apos;t have an account yet?</span>
									<Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
								</>
							)}
						</div>
					)}
					{/* end::Content header */}

					{/* begin::Content body */}
					<div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
						<Switch>
							<ContentRoute path="/auth/login" component={Login} />
							<ContentRoute path="/auth/registration" component={Registration} />
							<ContentRoute path="/auth/thank-you" component={ThankYou} />
							<ContentRoute
								path="/auth/forgot-password"
								component={ForgotPassword}
							/>
							<ContentRoute path="/reset/:verificationCode" component={NewPassword} />
							<ContentRoute path="/verify/:verificationCode" component={VerifyEmail} />
							<Redirect from="/auth" exact to="/auth/login" />
							<Redirect to="/auth/login" />
						</Switch>
					</div>
					{/* end::Content body */}

					{/* begin::Mobile footer */}
					<br />
					<div
						className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5"
					>
						<div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
							&copy;
							{' '}
							{` ${today.toString()} `}
							{' '}
							Convertmore
						</div>
						<div className="d-flex order-1 order-sm-2 my-2">
							<Link
								to={{ pathname: 'https://convertmore.com/privacy-policy/ ' }}
								href="https://convertmore.com/privacy-policy/ "
								target="_blank"
								className="text-dark-75 text-hover-primary ml-4"
								rel="noopener noreferrer"
							>
								Privacy
							</Link>
							<Link
								to={{ pathname: 'https://convertmore.com/terms-of-use/ ' }}
								href="https://convertmore.com/terms-of-use/ "
								target="_blank"
								className="text-dark-75 text-hover-primary ml-4"
								rel="noopener noreferrer"
							>
								Legal
							</Link>
							<Link
								to={{ pathname: 'https://convertmore.com/privacy-policy/ ' }}
								href="https://convertmore.com/privacy-policy/ "
								target="_blank"
								className="text-dark-75 text-hover-primary ml-4"
								rel="noopener noreferrer"
							>
								Contact
							</Link>
						</div>
					</div>
					{/* end::Mobile footer */}
				</div>
				{/* end::Content */}
			</div>
			{/* end::Login */}
		</div>
	);
}
