import React, { Fragment, useState, useEffect } from 'react';

// Material
import {
	Card,
	CardContent,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	pees: {
		paddingBottom: '20px',
		paddingTop: '20px',
		fontSize: theme.typography.body1.fontSize,
	},
	gridDataContainer: {
		paddingBottom: '10px',
		paddingTop: '10px',
	},
	spans: {
		fontSize: theme.typography.body1.fontSize,
		color: theme.palette.convertmoreBlack.borderColor,
	},
	spanStatValues: {
		fontSize: theme.typography.body1.fontSize,
		paddingLeft: '5px',
		color: theme.palette.convertmoreBlack.borderColor,
	},
	popover: {
		pointerEvents: 'none',
	},
	infoRoundedIcon: {
		color: theme.palette.convertmoreBlue.main,
	},
	paper: {
		padding: '10px',
		marginTop: '2px',
	},
	typography: {
		fontSize: theme.typography.body1.fontSize,
	},
	timesClickedCard: {
		minHeight: 175,
	},
}));

interface WidgetData {
	key: string,
	header: string,
	calls: {
		successful: number,
		missed: number,
		busy: number,
		noAnswer: number,
	}
	scheduledCalls: {
		successful: number,
		missed: number,
		busy: number,
		noAnswer: number,
	},
	widgetViews: number,
}

const CALL_TYPES = [
	{
		key: 'calls',
		label: 'Calls:',
	},
	{
		key: 'scheduledCalls',
		label: 'Scheduled calls:',
	},
];

const CALL_STATUSES = [
	{
		key: 'successful',
		label: 'Successful: ',
	},
	{
		key: 'missed',
		label: 'Missed: ',
	},
	{
		key: 'busy',
		label: 'Busy: ',
	},
	{
		key: 'noAnswer',
		label: 'No Answer: ',
	},
];

const widgetTypeData: WidgetData = {
	key: '',
	header: '',
	calls: {
		successful: 0,
		missed: 0,
		busy: 0,
		noAnswer: 0,
	},
	scheduledCalls: {
		successful: 0,
		missed: 0,
		busy: 0,
		noAnswer: 0,
	},
	widgetViews: 0,
};

function sumData(currentWidget: WidgetData, widget: WidgetData): WidgetData {
	const calls = {
		successful: widget.calls.successful + currentWidget.calls.successful,
		missed: widget.calls.missed + currentWidget.calls.missed,
		busy: widget.calls.busy + currentWidget.calls.busy,
		noAnswer: widget.calls.noAnswer + currentWidget.calls.noAnswer,
	};
	const scheduledCalls = {
		successful: widget.scheduledCalls.successful + currentWidget.scheduledCalls.successful,
		missed: widget.scheduledCalls.missed + currentWidget.scheduledCalls.missed,
		busy: widget.scheduledCalls.busy + currentWidget.scheduledCalls.busy,
		noAnswer: widget.calls.noAnswer + currentWidget.calls.noAnswer,
	};
	return {
		...widget,
		calls,
		scheduledCalls,
		widgetViews: widget.widgetViews + currentWidget.widgetViews,
	};
}

const DataGrid = ({
	setCardStatistic,
	widgetTypes,
	analyticsData,
}) => {
	const [widgetsInfo, setWidgetsInfo] = useState([]);

	const classes = useStyles();

	const floatingIconId = widgetTypes.find(widgetType => widgetType.name === 'Floating icon')?.id;
	const timedPopupId = widgetTypes.find(widgetType => widgetType.name === 'Timed popup')?.id;
	const exitPopupId = widgetTypes.find(widgetType => widgetType.name === 'Exit popup')?.id;

	useEffect(() => {
		let totalPageVisits = 0;
		let totalNumberOfCalls = 0;
		let totalWidgetViews = 0;

		let floatingExist = false;
		let timedExist = false;
		let exitExist = false;

		let floatingIconData: WidgetData = {
			...widgetTypeData,
			key: floatingIconId,
			header: 'Floating Icon',
		};

		let timedPopupData: WidgetData = {
			...widgetTypeData,
			key: timedPopupId,
			header: 'Timed Popup',
		};

		let exitPopupData: WidgetData = {
			...widgetTypeData,
			key: exitPopupId,
			header: 'Exit Popup',
		};

		analyticsData.forEach((analytic: { usage: { widgets: any[]; pageViews: number; }; }) => {
			analytic.usage.widgets.forEach((widget) => {
				widget.calls.noAnswer = widget.calls['no-answer'] || 0;
				widget.scheduledCalls.noAnswer = widget.scheduledCalls['no-answer'] || 0;

				if (widget.typeId === floatingIconId) {
					floatingExist = true;
					floatingIconData = sumData(widget, floatingIconData);
				}
				if (widget.typeId === timedPopupId) {
					timedExist = true;
					timedPopupData = sumData(widget, timedPopupData);
				}
				if (widget.typeId === exitPopupId) {
					exitExist = true;
					exitPopupData = sumData(widget, exitPopupData);
				}

				totalNumberOfCalls += (
					widget.calls.successful +
					widget.calls.missed +
					widget.calls.busy +
					widget.calls.noAnswer +
					widget.scheduledCalls.successful +
					widget.scheduledCalls.missed +
					widget.scheduledCalls.busy +
					widget.scheduledCalls.noAnswer
				);
				totalWidgetViews += widget.widgetViews;
			});
			totalPageVisits += analytic.usage.pageViews;
		});

		setWidgetsInfo([
			floatingExist ? floatingIconData : null,
			timedExist ? timedPopupData : null,
			exitExist ? exitPopupData : null,
		]);

		setCardStatistic({
			pageVisits: totalPageVisits,
			widgetViews: totalWidgetViews || 0,
			calls: totalNumberOfCalls,
			conversionRate: (totalNumberOfCalls > 0 && totalWidgetViews > 0)
				? ((totalNumberOfCalls / totalWidgetViews) * 100).toFixed(2) : 0,
		});
	}, [setCardStatistic, analyticsData, widgetTypes]);

	return (
		<div className={classes.root}>
			{widgetsInfo?.map((widget) => {
				if (!widget) {
					return null;
				}
				return (
					<Fragment key={widget.key}>
						<Typography
							className={classes.typography}
						>
							{widget.header}
						</Typography>
						<Grid key={`grid ${widget.key}`} spacing={2} container>
							{CALL_TYPES.map(callsType => (
								<Grid item xs={12} sm={4} key={`grid ${widget.key} ${callsType.key}`}>
									<Card variant="outlined">
										<CardContent>
											<b className={classes.pees}>{callsType.label}</b>
											<div className={classes.gridDataContainer}>
												<table>
													<tbody>
														{CALL_STATUSES.map(callStatus => (
															<tr key={`${widget.key} ${callsType.key} ${callStatus.key}`}>
																<td>
																	<span className={classes.spans}>
																		{callStatus.label}
																	</span>
																</td>
																<td>
																	<span className={classes.spanStatValues}>
																		{widget[callsType.key][callStatus.key]}
																	</span>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</CardContent>
									</Card>
								</Grid>
							))}
							<Grid item xs={12} sm={4}>
								<Card variant="outlined" classes={{ root: classes.timesClickedCard }}>
									<CardContent>
										<b className={classes.pees}>Times Clicked:</b>
										<div className={classes.gridDataContainer}>
											<span className={classes.spans}>{widget.widgetViews || 0}</span>
										</div>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
						<br />
						<br />
					</Fragment>
				);
			})}
		</div>
	);
};

export default DataGrid;
