import dayjs from 'dayjs';

import { ActiveHoursType, ActiveHoursTimesType } from '../../typecheckings';

const from = dayjs().hour(9).minute(0).toISOString();
const to = dayjs().hour(17).minute(0).toISOString();

export const DEFAULT_TIMES: ActiveHoursTimesType[] = [{
	from,
	to,
}];

const weekObjects: ActiveHoursType[] = [
	{
		day: 'Monday',
		isActive: true,
		times: DEFAULT_TIMES,
	},
	{
		day: 'Tuesday',
		isActive: true,
		times: DEFAULT_TIMES,
	},
	{
		day: 'Wednesday',
		isActive: true,
		times: DEFAULT_TIMES,
	},
	{
		day: 'Thursday',
		isActive: true,
		times: DEFAULT_TIMES,
	},
	{
		day: 'Friday',
		isActive: true,
		times: DEFAULT_TIMES,
	},
	{
		day: 'Saturday',
		isActive: false,
		times: DEFAULT_TIMES,
	},
	{
		day: 'Sunday',
		isActive: false,
		times: DEFAULT_TIMES,
	},
];

export default weekObjects;
