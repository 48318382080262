import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	select: {
		minWidth: '120px',
		margin: '20px 0',
		paddingBottom: '1px',
	},
	container: {
		marginBottom: '30px',
	},
	contentWrapper: {
		fontSize: '18px',
	},
	cardHeaderAction: {
		margin: '0',
	},
	noDataParagraph: {
		textAlign: 'center',
	},
	tableHeaderCell: {
		borderBottom: 'none',
		padding: '30px 0px 0px 0px',
	},
	tableHeaderSpan: {
		textDecoration: 'underline',
	},
	chartContainer: {
		position: 'relative',
	},
	toAgentsBox: {
		width: '200px',
		marginLeft: '8px',
	},
	toWidgetsBox: {
		width: '200px',
		marginRight: '8px',
	},
	pageVisitsCardText: {
		color: theme.palette.chart.pageVisits.main,
	},
	widgetViewsCardText: {
		color: theme.palette.chart.widgetViews.main,
	},
	callsCardText: {
		color: theme.palette.chart.calls.main,
	},
	conversionRateCardText: {
		color: theme.palette.chart.conversionRate.main,
	},
	cardContents: {
		padding: 10,
		'&:last-child': {
			paddingBottom: 0,
		},
	},
	cards: {
		overflowWrap: 'break-word',
		boxShadow: '0px 2px 10px 2px rgba(70, 70, 70, 0.1) !important',
	},
	box: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#F4F4F4',
		paddingTop: '1.2rem',
		paddingBottom: '1.2rem',
		paddingLeft: '1.5rem',
		paddingRight: '1.5rem',
		marginBottom: '0rem',
		marginTop: '2.143rem',
	},
	analyticsTitle: {
		...theme.typography.h4,
	},
	statisticsData: {
		...theme.typography.h6,
		color: theme.palette.titleSubheader.main,
	},
	widgetsButton: {
		color: theme.palette.convertmoreOrange.white,
		backgroundColor: theme.palette.convertmoreOrange.main,
		'&:hover': {
			backgroundColor: theme.palette.convertmoreOrange.hover,
		},
		...theme.typography.buttons,
	},
	agentsButton: {
		color: theme.palette.convertmoreOrange.main,
		backgroundColor: theme.palette.convertmoreOrange.white,
		borderColor: theme.palette.convertmoreOrange.main,
		'&:hover': {
			color: theme.palette.convertmoreOrange.hover,
			borderColor: theme.palette.convertmoreOrange.hover,
			backgroundColor: theme.palette.convertmoreOrange.outlineHover,
		},
		...theme.typography.buttons,
	},
	h4: {
		...theme.typography.h4,
	},
	h6: {
		...theme.typography.h6,
	},
}));
