import React from 'react';
import { Paper } from '@material-ui/core';

// Constants
import defaultValues from '../../constants/defaultValues';

// Styles
import { coloredPaperStyles } from './ColoredPaper.styles';

const ColoredPaper = (props) => {
	const { value, paymentsPage, callsPage } = props;
	const classes = coloredPaperStyles();
	let paper;

	// for Widget types
	switch (value) {
		case defaultValues.WIDGET_TYPES[0]: // "Exit popup"
			paper = <Paper elevation={0} className={classes.exitPopupPaper}>{value}</Paper>;
			break;
		case defaultValues.WIDGET_TYPES[1]: // "Timed popup"
			paper = <Paper elevation={0} className={classes.timedPopupPaper}>{value}</Paper>;
			break;
		case defaultValues.WIDGET_TYPES[2]: // "Floating icon"
			paper = <Paper elevation={0} className={classes.floatingIconPaper}>{value}</Paper>;
			break;
		default:
			paper = '/';
	}
	// for Calls
	if (callsPage) {
		switch (value) {
			case defaultValues.CALL_STATUSES[0]: // "succesful"
				paper = <Paper elevation={0} className={classes.successfulPaper}>Received</Paper>;
				break;
			case defaultValues.CALL_STATUSES[1]: // "missed"
				paper = <Paper elevation={0} className={classes.missedPaper}>Missed</Paper>;
				break;
			case defaultValues.CALL_STATUSES[2]: // "busy"
				paper = <Paper elevation={0} className={classes.busyPaper}>Busy Line</Paper>;
				break;
			case defaultValues.CALL_STATUSES[3]: // "no-answer"
				paper = <Paper elevation={0} className={classes.noAnswerPaper}>No Answer</Paper>;
				break;
			default:
				paper = '/';
		}
	}
	// for Payments
	if (paymentsPage) {
		switch (value) {
			case defaultValues.PAYMENT_STATUSES[3]: // "succesful"
				paper = <Paper elevation={0} className={classes.successfulPaper}>Successful</Paper>;
				break;
			case defaultValues.PAYMENT_STATUSES[2]: // "canceled"
				paper = <Paper elevation={0} className={classes.busyPaper}>Canceled</Paper>;
				break;
			case defaultValues.PAYMENT_STATUSES[1]: // "failed"
				paper = <Paper elevation={0} className={classes.missedPaper}>Failed</Paper>;
				break;
			case defaultValues.PAYMENT_STATUSES[0]: // "on-hold"
				paper = <Paper elevation={0} className={classes.busyPaper}>On-hold</Paper>;
				break;
			default:
				paper = '/';
		}
	}
	return paper;
};

export default ColoredPaper;
