// @ts-nocheck
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Box, makeStyles, useTheme } from '@material-ui/core';

export const AddCreditCard = () => {
	const theme = useTheme();

	const createOptions = {
		base: {
			fontWeight: 400,
			fontSize: '14px',
			'::placeholder': {
				color: theme.palette.text.hint,
			},
		},
		invalid: {
			color: theme.palette.error.main,
		},
	};

	const style = makeStyles({
		stripeElement: {
			border: '1px solid',
			color: theme.palette.primary.contrastText,
			borderRadius: `${theme.shape.borderRadius}px`,
			borderColor: theme.palette.grey[400],
			padding: '18.5px 14px',

			'&:hover': {
				borderColor: theme.palette.grey[700],
			},
		},
	});

	return (
		<>
			<p>Add payment method</p>
			{/* <Box marginY="20px"> */}
			<Box>
				<Box className={style().stripeElement}>
					<CardElement options={{ style: createOptions }} />
				</Box>
			</Box>
		</>
	);
};
