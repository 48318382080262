import { persistReducer } from 'redux-persist';
import Axios from 'axios';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getMessage } from '../../app/constants/messages';

// Types
import {
	GET_METADATA,
	MetaDataActionTypes,
	MetaDataAutoRechargeSettings,
	MetaDataCredit,
	MetaDataTypes,
	RESET_META_DATA,
	UPDATE_AUTO_RECHARGE_INFORMATION,
	UPDATE_CREDITS,
	UPDATE_META_DATA,
	UPDATE_SETTINGS,
} from './metaDataTypes';

// Actions
import { postNotification } from '../notifications/actions';

export const actionTypes = {
	GET_METADATA: 'GET_METADATA',
	UPDATE_META_DATA: 'UPDATE_META_DATA',
	UPDATE_SETTINGS: 'UPDATE_SETTINGS',
	UPDATE_CREDITS_AMOUNT: 'UPDATE_CREDITS_AMOUNT',
	RESET_META_DATA: 'RESET_META_DATA',
};

const initialMetaDataState: MetaDataTypes = {
	widgetTypes: [],
	widgetLayouts: [],
	callingRules: [],
	twilioNumbers: [],
	creditPacks: [],
	settings: [],
	credits: [
		{
			credits: 0,
		},
	],
};

export const reducer = persistReducer(
	{ storage, key: 'covertmore--meta', whitelist: ['widgetTypes', 'widgetLayouts', 'callingRules', 'creditPack'] },
	(state: MetaDataTypes = initialMetaDataState, action: MetaDataActionTypes) => {
		switch (action.type) {
			case UPDATE_META_DATA: {
				const data = action.payload;
				return {
					...state as MetaDataTypes,
					widgetTypes: data.widgetTypes,
					widgetLayouts: data.widgetLayouts,
					twilioNumbers: data.twilioNumbers,
					callingRules: data.callingRules,
					creditPacks: data.creditPacks,
					settings: data.settings,
					credits: data.credits,
				};
			}
			case UPDATE_SETTINGS: {
				const data = action.payload;
				const newSettings = state.settings.map((item) => {
					if (item.type === 'billing') {
						return data;
					}
					return item;
				});
				const newState = {
					...state as MetaDataTypes,
					settings: newSettings,
				};
				return newState;
			}
			case UPDATE_CREDITS: {
				const newState = {
					...state as MetaDataTypes,
					credits: [action.payload],
				};
				return newState;
			}
			case UPDATE_AUTO_RECHARGE_INFORMATION: {
				const credits = { ...state.credits[0] };
				credits.autoRecharge = action.payload;

				return {
					...state,
					credits: [credits],
				};
			}
			case actionTypes.RESET_META_DATA: {
				return initialMetaDataState;
			}
			default:
				return state;
		}
	}
);

export const actions = {
	updateCredits: (credits: MetaDataCredit): MetaDataActionTypes => {
		return {
			type: UPDATE_CREDITS,
			payload: credits,
		};
	},
	updateAutoRecharge: (data: MetaDataAutoRechargeSettings): MetaDataActionTypes => {
		return {
			type: UPDATE_AUTO_RECHARGE_INFORMATION,
			payload: data,
		};
	},
	updateMetaData: (data: MetaDataTypes, errorMsg: string): MetaDataActionTypes => {
		return {
			type: UPDATE_META_DATA,
			payload: data,
			meta: {
				errorMsg,
			},
		};
	},
	updateSettings: (data, errorMsg: string): MetaDataActionTypes => {
		return {
			type: UPDATE_SETTINGS,
			payload: data,
			meta: {
				errorMsg,
			},
		};
	},
	getMetaData: () => {
		return {
			type: GET_METADATA,
		};
	},
	resetMetaData: () => {
		return {
			type: RESET_META_DATA,
		};
	},
};

export function* saga() {
	yield takeLatest(GET_METADATA, function* getMetadataSaga() {
		try {
			const { data: meta } = yield Axios.get(`${process.env.REACT_APP_API_URL}/client/meta`);
			yield put(actions.updateMetaData(meta, 'Failed to update metadata.'));
		} catch (error) {
			yield put(postNotification(getMessage('GET_METADATA_ERROR'), 'error'));
		}
	});
}
