import Axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { postNotification } from '../notifications/actions';
import { getMessage } from '../../app/constants/messages';
import {
	WIDGET_CREATE_WIDGET,
	WIDGET_DELETE_WIDGET,
	WIDGET_DELETE_WIDGET_SUCCESS,
	WIDGET_GET_WIDGETS,
	WIDGET_REQUEST_CREATE_WIDGET,
	WIDGET_REQUEST_END,
	WIDGET_REQUEST_ERROR,
	WIDGET_REQUEST_START,
	WIDGET_REQUEST_UPDATE_SINGLE_WIDGET,
	WIDGET_REQUEST_WIDGETS,
	WIDGET_UPDATE_SINGLE_WIDGET,
	WIDGET_UPDATE_WIDGET_IMAGE,
	WidgetActionTypes,
	WidgetReducerInterface,
	WidgetRequestCreateWidget,
	WidgetRequestUpdateSingleWidget,
	WidgetType,
} from './widgetTypes';

export const actionTypes = {
	REQUEST_STARTED: 'REQUEST_STARTED',
	REQUEST_ENDED: 'REQUEST_ENDED',
	REQUEST_ERROR: 'REQUEST_ERROR',
	UPDATE_WIDGETS: 'UPDATE_WIDGETS',
	UPDATE_WIDGET: 'UPDATE_WIDGET',
	ADD_WIDGET: 'ADD_WIDGET',
	REQUEST_GET_WIDGETS: 'REQUEST_GET_WIDGETS',
	REQUEST_PUT_WIDGET: 'REQUEST_PUT_WIDGET',
	REQUEST_POST_WIDGET: 'REQUEST_POST_WIDGET',
};

const initialMetaDataState: WidgetReducerInterface = {
	widgets: [],
	isFetching: false,
	error: null,
	loading: true,
};

export const reducer = (state: WidgetReducerInterface = initialMetaDataState, action: WidgetActionTypes) => {
	switch (action.type) {
		case WIDGET_REQUEST_START: {
			return { ...state, isFetching: true };
		}
		case WIDGET_REQUEST_END: {
			return { ...state, isFetching: false, loading: false };
		}
		case WIDGET_REQUEST_ERROR: {
			const data = action.payload;
			return { ...state, isFetching: true, error: data };
		}
		case WIDGET_GET_WIDGETS: {
			const data = action.payload;
			return { ...state, widgets: data };
		}
		case WIDGET_UPDATE_SINGLE_WIDGET: {
			const data = action.payload;
			const widgetsArray = [...state.widgets];

			return {
				...state,
				widgets: widgetsArray.reduce((acc, widget) => {
					let item = widget;
					if (widget.id === data.id) {
						item = data;
					} else if (widget.typeId === data.typeId && widget.websiteId === data.websiteId) {
						item.isActive = false;
					}

					acc.push(item);
					return acc;
				}, []),
			};
		}
		case WIDGET_DELETE_WIDGET_SUCCESS: {
			const widgetId = action.payload;
			return {
				...state,
				widgets: state.widgets.filter(widget => widget.id !== widgetId),
			};
		}
		case WIDGET_CREATE_WIDGET: {
			const data = action.payload;
			const newState = { ...state };
			newState.widgets = [data, ...newState.widgets];
			return newState;
		}
		case WIDGET_UPDATE_WIDGET_IMAGE: {
			const data = action.payload;
			const widgetsArray = [...state.widgets];

			return {
				...state,
				widgets: widgetsArray.reduce((acc, widget) => {
					const item = widget;
					if (widget.id === data.id) {
						item.settings.callSetup.picture = data.settings.callSetup.picture;
					}
					acc.push(item);
					return acc;
				}, []),
			};
		}
		default:
			return state;
	}
};

export const actions = {
	startRequest: (): WidgetActionTypes => {
		return {
			type: WIDGET_REQUEST_START,
		};
	},
	endRequest: (): WidgetActionTypes => {
		return {
			type: WIDGET_REQUEST_END,
		};
	},
	errorRequest: (data: string): WidgetActionTypes => {
		return {
			type: WIDGET_REQUEST_ERROR,
			payload: data,
		};
	},
	requestGetWidgets: () => {
		return { type: WIDGET_REQUEST_WIDGETS };
	},
	requestPutWidget: (data: WidgetType): WidgetActionTypes => {
		return {
			type: WIDGET_REQUEST_UPDATE_SINGLE_WIDGET,
			payload: data,
		};
	},
	requestPostWidget: (data: WidgetType): WidgetActionTypes => {
		return {
			type: WIDGET_REQUEST_CREATE_WIDGET,
			payload: data,
		};
	},
	updateWidgets: (data: WidgetType[]): WidgetActionTypes => {
		return {
			type: WIDGET_GET_WIDGETS,
			payload: data,
		};
	},
	updateWidget: (data: WidgetType): WidgetActionTypes => {
		return {
			type: WIDGET_UPDATE_SINGLE_WIDGET,
			payload: data,
		};
	},
	updateWidgetImage: (data: WidgetType): WidgetActionTypes => {
		return {
			type: WIDGET_UPDATE_WIDGET_IMAGE,
			payload: data,
		};
	},
	addWidget: (data: WidgetType): WidgetActionTypes => {
		return {
			type: WIDGET_CREATE_WIDGET,
			payload: data,
		};
	},
	widgetDelete: (widgetId): WidgetActionTypes => {
		return {
			type: WIDGET_DELETE_WIDGET,
			payload: widgetId,
		};
	},
};

export function* saga() {
	yield takeLatest(WIDGET_REQUEST_WIDGETS, function* getWidgetsSaga() {
		try {
			yield actions.startRequest();
			const { data: widgets } = yield Axios.get(`${process.env.REACT_APP_API_URL}/client/widgets`);
			yield put(actions.updateWidgets(widgets));
			yield put(actions.endRequest());
		} catch (error) {
			yield put(postNotification(getMessage('GET_WIDGETS_ERROR'), 'error'));
		}
	});
	yield takeLatest(WIDGET_REQUEST_UPDATE_SINGLE_WIDGET, function* putWidgetSaga(action: WidgetRequestUpdateSingleWidget) {
		try {
			yield actions.startRequest();
			const { data } = yield Axios.put(`${process.env.REACT_APP_API_URL}/client/widgets/${action.payload.id}`, action.payload);
			yield put(actions.updateWidget(data));
			yield put(actions.endRequest());
		} catch (error) {
			yield put(postNotification(getMessage('WIDGET_EDIT_ERROR'), 'error'));
		}
	});
	yield takeLatest(WIDGET_REQUEST_CREATE_WIDGET, function* postWidgetSaga(action: WidgetRequestCreateWidget) {
		try {
			yield actions.startRequest();
			const { data } = yield Axios.post(`${process.env.REACT_APP_API_URL}/client/widgets`, action.payload);
			yield put(actions.addWidget(data));

			// Does this do anything?
			yield put(actions.requestGetWidgets());
			yield put(actions.endRequest());
		} catch (error) {
			yield put(postNotification(getMessage('WIDGET_CREATE_ERROR'), 'error'));
		}
	});

	// @ts-ignore
	yield takeLatest(WIDGET_DELETE_WIDGET, function* deleteWidgetSaga(widgetId: string): Widge {
		try {
			yield actions.startRequest();
			yield Axios.delete(`${process.env.REACT_APP_API_URL}/client/widgets/${widgetId}`,);
			// Does this do anything?
			yield put(actions.requestGetWidgets());
			yield put(actions.endRequest());
		} catch (error) {
			yield put(postNotification(getMessage('WIDGET_CREATE_ERROR'), 'error'));
		}
	});
}
