/* eslint-disable */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import objectPath from 'object-path';

import { useHtmlClassService } from '../../_core/MetronicLayout';
import { UserProfileDropdown } from './dropdowns/UserProfileDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faClock } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress, makeStyles, useTheme } from '@material-ui/core';
import { formatUTCOffset } from '../../../../app/lib/timezoneHelpers';

const useStyles = makeStyles(theme => ({
	credits: {
		color: theme.palette.credits.main,
		[theme.breakpoints.down('xs')]: {
			fontSize: '10px',
		},
	},
	timezone: {
		cursor: 'pointer',
	},
	coinIcon: {
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0px !important', // there is a ml-2 class in credits bold paragraph below for bigger screens
		},
	},
}));

const handleTimezoneClick = (history) => {
	history.push('/settings');
};

export function QuickUserToggler() {
	const theme = useTheme();
	const classes = useStyles(theme);
	const history = useHistory();
	const { user } = useSelector(state => state.auth);
	const credits = useSelector(state => state.metaData.credits[0]);

	const uiService = useHtmlClassService();
	const layoutProps = useMemo(() => {
		return {
			offcanvas: objectPath.get(uiService.config, 'extras.user.layout') === 'offcanvas',
		};
	}, [uiService]);

	return (
		<>
			{layoutProps.offcanvas && (
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip id="quick-user-tooltip">Your Profile</Tooltip>}
				>
					<div className="topbar-item">
						<div className="d-flex flex-wrap">
							<b onClick={() => handleTimezoneClick(history)} className={`align-items-center pt-1 pb-1 pl-1 pr-1 ${classes.credits} ${classes.timezone}`}>
								<FontAwesomeIcon className="mr-2" size="lg" color={theme.palette.credits.main} icon={faClock} />
								{' '}
								Your timezone:
								{' '}
								{user.timezone}
								,
								{' '}
								{formatUTCOffset(user.timezone)}
							</b>
							<b className={`align-items-center pt-1 pb-1 pl-1 pr-1 ${classes.credits}`}>
								<FontAwesomeIcon className={`mr-2 ml-2 ${classes.coinIcon}`} size="lg" color={theme.palette.credits.main} icon={faCoins} />
								{' '}
								Your credits:
								{' '}
								{credits.credits}
							</b>
						</div>
						<div
							className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
							id="kt_quick_user_toggle"
						>
							<span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
							<span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
								{user.fullname}
							</span>
							<span className="symbol symbol-35 symbol-light-success">
								<span className="symbol-label font-size-h5 font-weight-bold">{user.fullname[0]}</span>
							</span>
						</div>
					</div>
				</OverlayTrigger>
			)}

			{!layoutProps.offcanvas && (<UserProfileDropdown />)}
		</>
	);
}
