// @ts-nocheck
import * as Yup from 'yup';

// Constants
import inputErrors from './inputErrors';
import weekObjects from './weekObject';
import PATTERNS from '../constants/regex';

// Types
import { WidgetType } from '../../redux/widget/widgetTypes';
import { MetaDataWidgetType } from '../../redux/metaData/metaDataTypes';

export const ALL_WIDGETS_DATA: MetaDataWidgetType = {
	id: 'dummy id',
	name: 'All Widgets',
	description: 'Special case to create all widget types',
};

// General settings
export const STEPS = ['1. General', '2. Design', '3. Code'];
export const WIDGET_TYPE = {
	timed: 'Timed popup', exit: 'Exit popup', side: 'Side popup', all: ALL_WIDGETS_DATA.name,
};
export const CALLING_RULES = { rule1: 'Rule 1', rule2: 'Rule 2', rule3: 'Rule 3' };

export const generalSchemaObject = {
	baseOptions: Yup.object().shape({
		widgetType: Yup.string().required(inputErrors.requiredField),
		name: Yup.string().nullable().required(inputErrors.requiredField),
		showsAfter: Yup.number().when('widgetType', {
			is: value => value === WIDGET_TYPE.timed || value === WIDGET_TYPE.all,
			then: Yup.number().required(inputErrors.requiredField),
		})
			.moreThan(-1, inputErrors.isPositive),
		repeatAfter: Yup.number().when('widgetType', {
			is: value => value === WIDGET_TYPE.timed || value === WIDGET_TYPE.all,
			then: Yup.number().required(inputErrors.requiredField),
		})
			.moreThan(-1, inputErrors.isPositive),
		displaysPerVisit: Yup.number()
			.when('widgetType', {
				is: value => value === WIDGET_TYPE.timed || value === WIDGET_TYPE.all,
				then: Yup.number().required(inputErrors.requiredField),
			})
			.moreThan(-1, inputErrors.isPositive),
	}),

	agentOptions: Yup.object().shape({
		agentNumber: Yup.string().when('useAgents', {
			is: false,
			then: Yup.string().matches(PATTERNS.phoneNumber, 'Not a valid phone number format').required(inputErrors.requiredField),
		}),
		agents: Yup.array().when('useAgents', {
			is: true,
			then: Yup.array().min(1).required(inputErrors.requiredField),
		}),
		callingRule: Yup.string().when('useAgents', {
			is: true,
			then: Yup.string().required(inputErrors.requiredField),
		}),
		useAgents: Yup.bool(),
	}),

	customCallerIdOptions: Yup.object().shape({
		customCallerId: Yup.object().shape({
			value:
				Yup.lazy((value) => {
					if (value === '+1') {
						return Yup.string();
					}
					return Yup.string().matches(PATTERNS.phoneNumber, { excludeEmptyString: true, message: 'Not a valid phone number format' }).optional();
				}),

		}).optional(),
	}),

	websiteUrl: Yup.object().shape({
		websiteUrl: Yup.string()
			.required(inputErrors.requiredField)
			.test('validUrl', inputErrors.validUrl, (value) => {
				const validUrl = !!PATTERNS.url.test(value, 'At least one active field',);
				const validDomain = PATTERNS.domain.test(value);

				return validUrl || validDomain;
			}),
	}),

	retryOptions: Yup.object().shape({
		numberOfTries: Yup.number()
			.typeError(inputErrors.invalidType)
			.required(inputErrors.requiredField)
			.moreThan(-1, inputErrors.isPositive),
		minutesBetweenTries: Yup.number()
			.typeError(inputErrors.invalidType)
			.required(inputErrors.requiredField)
			.moreThan(-1, inputErrors.isPositive),
	}),

	notifications: Yup.object().shape({
		email: Yup.object().shape({
			value: Yup.string().email('Invalid email').when('isActive', {
				is: true,
				then: Yup.string().required(inputErrors.requiredField),
			}),
			isActive: Yup.bool(),
		}),
		phoneNumber: Yup.object().shape({
			value: Yup.string().when('isActive', {
				is: true,
				then: Yup.string().required(inputErrors.requiredField),
			}),
			isActive: Yup.bool(),
		}),
	}),

	redirections: Yup.object().shape({
		callNow: Yup.object().shape({
			value: Yup.string()
				.when('isActive', {
					is: true,
					then: Yup.string()
						.required(inputErrors.requiredField)
						.test('validUrl', inputErrors.validUrl, value => !!PATTERNS.url.test(value)),
				}),
			isActive: Yup.bool(),
		}),
		scheduleCall: Yup.object().shape({
			value: Yup.string()
				.when('isActive', {
					is: true,
					then: Yup.string()
						.required(inputErrors.requiredField)
						.test('validUrl', inputErrors.validUrl, value => !!PATTERNS.url.test(value)),
				}),
			isActive: Yup.bool(),
		}),
	}),

	showingOptions: Yup.object().shape({
		duringWorkingHoursOnly: Yup.bool(),
	}),

	showScheduleOptions: Yup.object().shape({
		duringWorkingHours: Yup.bool(),
		outsideWorkingHours: Yup.bool(),
	}),

	waitingAudioOptions: Yup.object().shape({
		isActive: Yup.bool(),
		url: Yup.string()
			.url()
			.nullable()
			.when('isActive', {
				is: true,
				then: Yup.string()
					.required(inputErrors.requiredField),
			}),
	}),

	deviceDisplayOptions: Yup.object().shape({
		onDesktop: Yup.bool(),
		onMobile: Yup.bool(),
	}),
};

export const generalInitialValues = {
	widgetType: '',
	name: '',
	callingRule: '',
	agentNumber: '',
	agents: [],
	useAgents: false,
	websiteUrl: '',
	numberOfTries: '',
	minutesBetweenTries: '',
	showsAfter: '',
	repeatAfter: '',
	displaysPerVisit: '',
	useSchedule: false,
	activeHours: weekObjects,
	notifications: {
		email: {
			isActive: false,
			value: '',
		},
		phoneNumber: {
			isActive: false,
			value: '',
		},
	},
	redirections: {
		redirectCallNow: {
			isActive: false,
			value: '',
		},
		redirectScheduleCall: {
			isActive: false,
			value: '',
		},
	},
};

// Design settings
export const LAYOUT = ['Horizontal', 'Vertical', 'Full screen'];

export const designSchema = {
	globalSetup: Yup.object().shape({
		layout: Yup.string().required(inputErrors.requiredField),
		background: Yup.string(),
		textOptions: Yup.object().shape({
			size: Yup.number(),
			color: Yup.string(),
			fontFamily: Yup.string(),
		}),
		buttonOptions: Yup.object().shape({
			size: Yup.number(),
			color: Yup.string(),
			fontSize: Yup.number(),
		}),
		screenBackground: Yup.object().shape({
			color: Yup.string(),
			opacity: Yup.number(),
		}),
		floatingIcon: Yup.object().shape({
			size: Yup.number(),
			bgColor: Yup.string(),
			phoneIconColor: Yup.string(),
			position: Yup.string(),
		}),
	}),
	callSetup: Yup.object().shape({
		buttonText: Yup.string().required(inputErrors.requiredField),
		callBackText: Yup.string().required(inputErrors.requiredField),
		picture: Yup.object().shape({
			isActive: Yup.bool(),
			src: Yup.string().nullable().when('isActive', {
				is: true,
				then: Yup.string().required(inputErrors.requiredField),
			}),
			size: Yup.string(),
		}),
		main: Yup.object().shape({
			text: Yup.string().when('isActive', {
				is: true,
				then: Yup.string().required(inputErrors.requiredField),
			}),
			isActive: Yup.bool(),
		}),
		disclaimer: Yup.object().shape({
			text: Yup.string().when('isActive', {
				is: true,
				then: Yup.string().required(inputErrors.requiredField),
			}),
			isActive: Yup.bool(),
		}),
	}),
	scheduleSetup: Yup.object().shape({
		timeToCallText: Yup.string().required(inputErrors.requiredField),
		buttonText: Yup.string().required(inputErrors.requiredField),
		main: Yup.object().shape({
			text: Yup.string().when('isActive', {
				is: true,
				then: Yup.string().required(inputErrors.requiredField),
			}),
			isActive: Yup.bool(),
		}),
		disclaimer: Yup.object().shape({
			text: Yup.string().when('isActive', {
				is: true,
				then: Yup.string().required(inputErrors.requiredField),
			}),
			isActive: Yup.bool(),
		}),
		customField1: Yup.object().shape({
			text: Yup.string().when('isActive', {
				is: true,
				then: Yup.string().required(inputErrors.requiredField),
			}),
			isActive: Yup.bool(),
		}),
		customField2: Yup.object().shape({
			text: Yup.string().when('isActive', {
				is: true,
				then: Yup.string().required(inputErrors.requiredField),
			}),
			isActive: Yup.bool(),
		}),
		customField3: Yup.object().shape({
			text: Yup.string().when('isActive', {
				is: true,
				then: Yup.string().required(inputErrors.requiredField),
			}),
			isActive: Yup.bool(),
		}),
	}),
};

export const designInitialValues = {
	globalSetup: {
		layout: 'Vertical',
		background: '#ffffff',
		textOptions: {
			size: 20,
			color: '#000000',
			fontFamily: '',
		},
		buttonOptions: {
			fontSize: 20,
			size: 20,
			color: '#83D1FC',
		},
		screenBackground: {
			color: 'Light',
			opacity: 70,
		},
		floatingIcon: {
			size: 100,
			bgColor: '#F5A624',
			phoneIconColor: '#ffffff',
			position: 'right',
		},
	},
	callSetup: {
		picture: {
			isActive: false,
			src: '',
			size: 20,
		},
		main: {
			text: 'Main text',
			isActive: true,
		},
		disclaimer: {
			text: 'disclaimer',
			isActive: true,
		},
		buttonText: 'Call now',
		callBackText: 'Callback',
	},
	scheduleSetup: {
		timeToCallText: 'Pick a time for callback',
		buttonText: 'Schedule call',
		main: {
			text: 'Call back main text',
			isActive: true,
		},
		disclaimer: {
			text: 'Callback disclaimer',
			isActive: true,
		},
		customField1: {
			text: 'Custom field 1',
			isActive: true,
		},
		customField2: {
			text: 'Custom field 2',
			isActive: true,
		},
		customField3: {
			text: 'Custom field 3',
			isActive: true,
		},
	},
};

export const WIDGET_DEFAULT:WidgetType = {
	settings: {
		settings: {
			useAgents: false,
			numberOfTries: 3,
			minutesBetweenTries: 1,
			showsAfter: 15,
			repeatAfter: 120,
			displaysPerVisit: 3,
			useSchedule: true,
			activeHours: weekObjects,
			agentNumber: '',
			duringWorkingHoursOnly: false,
			redirections: {
				callNow: {
					isActive: false,
					value: '',
				},
				scheduleCall: {
					isActive: false,
					value: '',
				},
			},
			showScheduleForm: {
				duringWorkingHours: false,
				outsideWorkingHours: false,
			},
		},
		globalSetup: {
			layout: 'Vertical',
			background: '#ffffff',
			textOptions: {
				size: 20,
				color: '#000000',
				fontFamily: '',
			},
			buttonOptions: {
				fontSize: 20,
				size: 20,
				color: '#83D1FC',
				fontColor: '#ffffff',
			},
			screenBackground: {
				color: 'Light',
				opacity: 70,
			},
			floatingIcon: {
				size: 100,
				bgColor: '#F5A624',
				phoneIconColor: '#ffffff',
			},
		},
		callSetup: {
			picture: {
				isActive: false,
				src: '',
				size: 20,
			},
			main: {
				text: 'Leave us your phone number and we\'ll have someone call you within 10 seconds.',
				isActive: true,
			},
			disclaimer: {
				text: '100% Safe and Secure',
				isActive: true,
			},
			buttonText: 'Call Me Now',
			callBackText: 'Schedule a call instead',
		},
		scheduleSetup: {
			timeToCallText: 'Pick a time for the callback',
			buttonText: 'Schedule call',
			main: {
				text: 'Schedule a call for a specific time',
				isActive: true,
			},
			disclaimer: {
				text: 'All times are in your local timezone.',
				isActive: true,
			},
			customField1: {
				text: 'Name',
				isActive: true,
			},
			customField2: {
				text: '',
				isActive: false,
			},
			customField3: {
				text: '',
				isActive: false,
			},
		},
	},
	notifications: {
		email: {
			isActive: false,
			value: '',
		},
		phoneNumber: {
			isActive: false,
			value: '',
		},
	},
	redirections: {
		redirectCallNow: {
			isActive: false,
			value: '',
		},
		redirectScheduleCall: {
			isActive: false,
			value: '',
		},
	},
	waitingAudio: {
		isActive: false,
		value: '',
	},
	widgetType: ALL_WIDGETS_DATA.name,
	websiteUrl: '',
	name: '',
	callingRule: '',
	agents: [],
};

export const SHOW_SCHEDULE_OPTIONS = [
	{
		key: 'always',
		value: 'always',
		label: 'Always',
		duringWorking: true,
		outsideWorking: true,
	},
	{
		key: 'never',
		value: 'never',
		label: 'Never',
		duringWorking: false,
		outsideWorking: false,
	},
	{
		key: 'outside',
		value: 'outside',
		label: 'Only before/after times available for call',
		duringWorking: false,
		outsideWorking: true,
	},
	{
		key: 'during',
		value: 'during',
		label: 'Only during times available for call',
		duringWorking: true,
		outsideWorking: false,
	},
];

export const DEVICE_DISPLAY_OPTIONS = [
	{
		key: 'both',
		value: 'both',
		label: 'Desktop & Mobile',
		onDesktop: true,
		onMobile: true,
	},
	{
		key: 'desktop',
		value: 'desktop',
		label: 'Desktop only',
		onDesktop: true,
		onMobile: false,
	},
	{
		key: 'mobile',
		value: 'mobile',
		label: 'Mobile only',
		onDesktop: false,
		onMobile: true,
	},
];
