import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Material
import {
	Card, CardContent, CardHeader, Grid, useTheme, Box,
} from '@material-ui/core';

// Constants
import { STRIPE_KEY } from '../../constants/general';
import { getMessage } from '../../constants/messages';

// Types
import { RootStateType } from '../../../redux/rootReducer';

// Api calls
import { getPaymentMethod, getSpentCredits } from '../../../api';

// Actions
import { postNotification } from '../../../redux/notifications/actions';

// Metronic

// Styles
import { billingStyles } from './Billing.styles';

// Components
import ChargeManager from './ChargeManager';
import BillingAutoRechargeNote from './BillingAutoRechargeNote';
import BillingAutoRechargeOptions from './BillingAutoRechargeOptions';

const stripePromise = loadStripe(STRIPE_KEY);

const Billing = () => {
	// State initialization
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [loading, setLoading] = useState(true);
	const [showForm, setShowForm] = useState(false);
	const [autoRechargeCreditsPack, setAutoRechargeCreditsPack] = useState(null);
	const [spentCredits, setSpentCredits] = useState(0);

	// Redux data
	// @ts-ignore
	const [credits] = useSelector((state: RootStateType) => state.metaData.credits);
	const creditPacks = useSelector((state: RootStateType) => state.metaData.creditPacks);

	// Spent credits
	const getSpentCreditsCallback = async () => {
		try {
			setSpentCredits(await getSpentCredits());
			setLoading(false);
		} catch (error) {
			dispatch(postNotification(getMessage('USER_SPENT_CREDITS_ERROR'), 'error'));
		}
	};
	// Dispatch
	const dispatch = useDispatch();

	const theme = useTheme();
	const classes = billingStyles(theme);

	const getPaymentMethodCallback = async () => {
		try {
			setPaymentMethod(await getPaymentMethod());
			setLoading(false);
		} catch (error) {
			dispatch(postNotification(getMessage('PAYMENT_GET_PAYMENT_METHOD_ERROR'), 'error'));
		}
	};

	useEffect(() => {
		if (credits.autoRecharge) {
			setAutoRechargeCreditsPack(creditPacks.find(creditPack => creditPack.id === credits.autoRecharge.packId));
		}
	}, [credits.autoRecharge]);

	useEffect(() => {
		getPaymentMethodCallback();
		getSpentCreditsCallback();
	}, [loading]);

	const hasPurchases = true;
	const isManualPurchase = hasPurchases && (!credits.autoRecharge || (typeof credits.autoRecharge === 'object' && Object.keys(credits.autoRecharge).length === 0));
	const isAutoRecharge = hasPurchases && !!credits.autoRecharge;

	const showPaymentForm = !(!isManualPurchase && isAutoRecharge && !showForm);

	return (
		<>
			<Grid container justify="center">
				<Grid item lg={6} sm={8} xs={12}>
					<Box pt={2.5} width="100%">
						<p className={classes.h4}>Billing</p>
					</Box>
					{!showPaymentForm && (
						<>
							<BillingAutoRechargeNote
								criticalCredits={credits.autoRecharge.criticalCredits}
								creditPack={autoRechargeCreditsPack}
								minMonthlySpendingRequirement={credits.minMonthlySpendingRequirement}
								creditSpent={spentCredits}
							/>
							<BillingAutoRechargeOptions
								setShowForm={setShowForm}
							/>
						</>
					)}
				</Grid>
			</Grid>
			<br />
			{showPaymentForm && !loading && (
				<Card
					className={classes.container}
				>
					<CardHeader
						title=""
						subheader=""
						classes={{ root: classes.cardHeader }}
					/>
					<CardContent
						className={classes.contentWrapper}
					>
						<Grid container justify="center">
							<Grid item lg={6} sm={8} xs={12}>
								{/* <p className={classes.h4}>Configure Auto-Recharge</p> */}
								{/* <p className={classes.h6}>Set auto recharge when you are low on credits.</p> */}
								<Elements stripe={stripePromise}>
									<ChargeManager
										autoRecharge={credits.autoRecharge}
										creditPacks={creditPacks}
										paymentMethod={paymentMethod}
										loading={loading}
										getPaymentMethod={getPaymentMethodCallback}
										isAutoRecharge={isAutoRecharge}
										hasPurchases={hasPurchases}
										setShowForm={setShowForm}
									/>
								</Elements>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			)}
		</>
	);
};

export default Billing;
