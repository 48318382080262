// @ts-nocheck
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateEmailFullName } from '../../../redux/userSettings/actions';

// Constants
import { getMessage } from '../../constants/messages';

// Material ui
import { Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core';
import SecondaryButton from '../_actions/SecondaryButton';

// Components
import TextFieldInput from '../_inputs/TextFieldInput';
import { postNotification } from '../../../redux/notifications/actions';

const useStyles = makeStyles(theme => ({
	helperText: {
		textAlign: 'right',
	},
}));

function ChangeEmail(props) {
	const [emailSuccessMsg, setEmailSuccessMsg] = useState();
	// const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const classes = useStyles(theme);

	// const userEmail = useSelector(state => state.auth.user.email);

	const { intl, data: { userEmail } } = props;
	// const [isRequested, setIsRequested] = useState(false);
	const ChangeEmail = Yup.object().shape({
		newEmail: Yup.string()
			.email(getMessage('WRONG_EMAIL_FORMAT'))
			.min(3, getMessage('MIN_FIELD_LENGTH', { length: 3 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
	});

	const formik = useFormik({
		initialValues: { newEmail: userEmail },
		validationSchema: ChangeEmail,
		enableReinitialize: true,
		onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
			try {
				dispatch(updateEmailFullName({ email: values.newEmail }, getMessage('ACCOUNT_SETTINGS_EMAIL_CHANGE_ERROR')));
				// setIsRequested(true);
				// resetForm(formik.initialValues);
				setSubmitting(false);
				resetForm(formik);
				linkSentMessage('Link has been sent to your email address.');
				dispatch(postNotification(getMessage('ACCOUNT_SETTINGS_EMAIL_CHANGE_SUCCESS'), 'info'));
				dispatch(updateEmailFullName({ email: values.newEmail }, 'Failed to change email.'));
			} catch (error) {
				dispatch(postNotification(getMessage('ACCOUNT_SETTINGS_EMAIL_CHANGE_ERROR'), 'error'));
				// setIsRequested(false);
				setSubmitting(false);
				setStatus(
					intl.formatMessage(
						{ id: 'AUTH.VALIDATION.NOT_FOUND' },
						// { name: values.password }
						{ name: 'Placeholder' } // soon to implement properly formatted server error messages
					)
				);
			}
		},
	});

	const linkSentMessage = (msg) => {
		setEmailSuccessMsg(msg);
		setTimeout(() => {
			setEmailSuccessMsg();
		}, 5000);
	};

	return (
		<form>
			<Box mt={3} mb={1}>
				<TextFieldInput
					fullWidth
					label="Your Email"
					name="newEmail"
					type="email"
					value={formik.values.newEmail || userEmail}
					{...formik.getFieldProps('newEmail')}
					error={formik.touched.newEmail && !!formik.errors.newEmail}
					helperText={emailSuccessMsg || ''}
					FormHelperTextProps={{
						className: classes.helperText,
					}}
				/>
			</Box>
			<div className="form-group d-flex flex-wrap flex-start">
				<SecondaryButton
					variantColor="orange"
					onClick={() => formik.handleSubmit()}
					disabled={
						formik.isSubmitting ||
						!formik.isValid ||
						formik.values.newEmail === '' ||
						formik.values.newEmail === userEmail
					}
					// className="font-weight-bold px-9 py-4 my-3 mx-4" // btn btn-primary
				>
					<span>Save</span>
					{formik.isSubmitting && <span className="ml-3 spinner spinner-white" />}
				</SecondaryButton>
			</div>
			{/* Needed after handling of errors is added */}
			{/* {isRequested &&
				<Alert color="info" severity="info">A verification link has been sent to that email address.</Alert>
			} */}
		</form>
	);
}

export default injectIntl(ChangeEmail);
