const widgetApiUrl = process.env.REACT_APP_WIDGET_API_URL;
const widgetLoadScriptUrl = process.env.REACT_APP_WIDGET_LOADING_SCRIPT_URL;

/**
 * Generate production code snippet
 *
 * @param websiteId
 */
const getOldSnippet = (websiteId: string): string => (
// eslint-disable-next-line indent
`<script type="text/javascript">
window.convertMoreSettings = {
	settingsSrc: '${websiteId}',
	apiUrl: '${widgetApiUrl}',
}
</script>
<script defer id="convertmore" src="${widgetLoadScriptUrl}"></script>`
);

/**
 * Generate code snippet
 *
 * @param websiteId
 */
export const generateSnippet = (websiteId: string): string => (
// eslint-disable-next-line indent
`<script src="${widgetLoadScriptUrl}" type="text/javascript"></script>
<script id="convertmore" type="text/javascript" defer>
    convertmore('${websiteId}');
</script>`
);
