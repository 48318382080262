import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import {
	FormControlLabel, Checkbox, Box, Grid, makeStyles,
} from '@material-ui/core';

// Redux
import { register } from '../_redux/authCrud';
import TextFieldInput from '../../../components/_inputs/TextFieldInput';

// Components
import SecondaryButton from '../../../components/_actions/SecondaryButton';
import PhoneNumberInput from '../../../components/_inputs/PhoneNumberInput';

// Constants
import PATTERNS from '../../../constants/regex';
import { getMessage } from '../../../constants/messages';

// Lib
import { parseQuery } from '../../../lib/url';

const useStyles = makeStyles({
	actionBox: {
		marginBottom: '1.429rem',
	},
	actionGridItem: {
		alignSelf: 'flex-end',
	},
	checkboxSpan: {
		width: '0px',
		padding: '0px',
		margin: '0px',
		'& span': {
			padding: '0px 0px 0px 5px',
		},
	},
	textBox: {
		margin: '0px 5px',
	},
});

const initialValues = {
	fullname: '',
	email: '',
	phone: '',
	password: '',
	confirmPassword: '',
	acceptTerms: false,
};

const Registration = (props) => {
	const classes = useStyles();
	const location = useLocation();
	const reRef = useRef<ReCAPTCHA>();

	const { intl } = props;
	const [loading, setLoading] = useState(false);
	const RegistrationSchema = Yup.object().shape({
		fullname: Yup.string()
			.min(3, getMessage('MIN_FIELD_LENGTH', { length: 3 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
		email: Yup.string()
			.email(getMessage('WRONG_EMAIL_FORMAT'))
			.min(3, getMessage('MIN_FIELD_LENGTH', { length: 3 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
		phone: Yup.string()
			.matches(PATTERNS.phoneNumber, 'Not a valid phone number format')
			.required(getMessage('REQUIRED_FIELD')),
		password: Yup.string()
			.min(5, getMessage('MIN_FIELD_LENGTH', { length: 5 }))
			.max(50, getMessage('MAX_FIELD_LENGTH', { length: 50 }))
			.required(getMessage('REQUIRED_FIELD')),
		confirmPassword: Yup.string()
			.required(getMessage('REQUIRED_FIELD'))
			.when('password', {
				is: val => (!!(val && val.length > 0)),
				then: Yup.string().oneOf([Yup.ref('password')], getMessage('ACCOUNT_SETTINGS_PASSWORD_NOT_MATCHING')),
			}),
		acceptTerms: Yup.bool()
			.required(getMessage('AUTH_REGISTER_TERMS_AND_CONDITIONS')),
	});

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const [registerStatus, setRegisterStatus] = useState(false);

	const formik = useFormik({
		initialValues,
		validationSchema: RegistrationSchema,

		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setSubmitting(true);
			enableLoading();
			try {
				const query = parseQuery(location.search);
				const token = await reRef.current.executeAsync();
				const metadata: Record<string, any> = {};
				if (query?.src) {
					metadata.leadSource = query.src;
				}

				if (query?.sscid) {
					metadata.sscid = query.sscid;
				}

				reRef.current.reset();
				await register(values.fullname, values.email.toLowerCase(), values.phone, values.password, token, metadata);
				setRegisterStatus(true);
				setStatus(null);

				if (query.rel === 'ty') {
					window.location.href = 'https://moversdev.com/convertmore/lp-thank-you/';
				} else if (query.rel === 'ty2') {
					window.location.href = 'https://convertmore.com/lp2-thank-you/';
				} else {
					// @ts-ignore
					if (window.gtag) {
						// TODO: Cleanup this
						// @ts-ignore
						window.gtag('event', 'conversion', { send_to: 'AW-360437820/-cltCLqhg6sCELyw76sB' });
					}

					window.location.href = 'https://convertmore.com/main-thank-you';
				}
			} catch (error) {
				if (error.response.status === 409) {
					setStatus(
						intl.formatMessage({
							id: 'AUTH.VALIDATION.ACCOUNT_EXISTS',
						})
					);
				} else if (error.response.status === 410) {
					setStatus(
						intl.formatMessage({
							id: 'AUTH.VALIDATION.ACCOUNT_EXISTS_DELETED',
						})
					);
				} else {
					setStatus(
						intl.formatMessage({
							id: 'AUTH.GENERAL.ERROR',
						})
					);
				}
			}

			disableLoading();
			setSubmitting(false);
		},
	});

	return (
		<div className="login-form login-signin" style={{ display: 'block' }}>
			<div className="text-center mb-10 mb-lg-20">
				<h3 className="font-size-h1">
					<FormattedMessage id="AUTH.REGISTER.TITLE" />
				</h3>
				<p className="text-muted font-weight-bold">
					Enter your details to create your account
				</p>
			</div>
			<form
				id="kt_login_signin_form"
				className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
				onSubmit={formik.handleSubmit}
			>
				{/* begin: Alert */}
				{formik.status && (
					<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
						<div className="alert-text font-weight-bold">{formik.status}</div>
					</div>
				)}
				{/* end: Alert */}

				<TextFieldInput
					label="Full Name"
					name="fullname"
					{...formik.getFieldProps('fullname')}
					fullWidth
					error={formik.touched.fullname && !!formik.errors.fullname}
					helperText={(formik.touched.fullname && formik.errors.fullname) || ' '}
				/>
				<TextFieldInput
					label="Email"
					name="email"
					{...formik.getFieldProps('email')}
					fullWidth
					error={(formik.touched.email && !!formik.errors.email) || !!formik.status}
					helperText={(formik.touched.email && formik.errors.email) || ' '}
				/>
				<PhoneNumberInput
					id="phone"
					name="phone"
					label="Phone Number"
					value={formik.values.phone}
					fullWidth
					onDataChange={formik.setFieldValue}
					onFieldBlur={formik.setFieldTouched}
					error={formik.touched.phone && !!formik.errors.phone}
					helperText={(formik.touched.phone && formik.errors.phone) || ' '}
				/>
				<TextFieldInput
					label="Password"
					name="password"
					type="password"
					{...formik.getFieldProps('password')}
					fullWidth
					error={formik.touched.password && !!formik.errors.password}
					helperText={(formik.touched.password && formik.errors.password) || ' '}
				/>
				<TextFieldInput
					label="Confirm Password"
					name="confirmPassword"
					type="password"
					{...formik.getFieldProps('confirmPassword')}
					fullWidth
					error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
					helperText={(formik.touched.confirmPassword && formik.errors.confirmPassword) || ' '}
				/>
				<div className="form-group">
					<Box className={classes.textBox}>
						By marking the checkbox I confirm that I read and agree with the software &nbsp;
						<Link
							to={{ pathname: 'https://convertmore.com/terms-of-use/ ' }}
							href="https://convertmore.com/terms-of-use/ "
							target="_blank"
							className="mr-2"
							rel="noopener noreferrer"
						>
							Terms and Conditions
						</Link>
						and the
						<Link
							to={{ pathname: 'https://convertmore.com/privacy-policy/ ' }}
							href="https://convertmore.com/privacy-policy/ "
							target="_blank"
							className="mr-2"
							rel="noopener noreferrer"
						>
							&nbsp;Privacy Policy
						</Link>
						<FormControlLabel
							label=""
							className={classes.checkboxSpan}
							control={(
								<Checkbox
									onChange={formik.handleChange}
									className={classes.checkboxSpan}
									color="primary"
									name="acceptTerms"
									{...formik.getFieldProps('acceptTerms')}
								/>
							)}
						/>
					</Box>
					{formik.touched.acceptTerms && formik.errors.acceptTerms ? (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">{formik.errors.acceptTerms}</div>
						</div>
					) : null}
				</div>
				<ReCAPTCHA
					sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
					size="invisible"
					ref={reRef}
				/>
				<Box display="flex" p={0} my={2}>
					{registerStatus ? <b>{getMessage('AUTH_REGISTER_SUCCESS')}</b> : (
						<Grid container justify="center" spacing={2}>
							<Grid item>
								<Box width="100%">
									<SecondaryButton
										type="submit"
										variantColor="orange"
										disabled={
											formik.isSubmitting ||
											!formik.isValid ||
											!formik.values.acceptTerms
										}
									>
										<span>Submit</span>
										{loading && <span className="ml-3 spinner spinner-white" />}
									</SecondaryButton>
								</Box>
							</Grid>
							<Grid item className={classes.actionGridItem}>
								<Box className={classes.actionBox}>
									<Link
										to="/auth/login"
										href="/auth/login"
									>
										<SecondaryButton
											variantColor="black"
										>
											<span>Cancel</span>
										</SecondaryButton>
									</Link>
								</Box>
							</Grid>
						</Grid>
					)}
				</Box>
			</form>
		</div>
	);
};

export default injectIntl(connect(null, null)(Registration));
