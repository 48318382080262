import { makeStyles } from '@material-ui/core';

export const manageAgents = makeStyles(theme => ({
	container: {
		marginBottom: '30px',
	},
	contentWrapper: {
		fontSize: '18px',
	},
	cardHeaderAction: {
		margin: '0',
	},
	tableRow: {
		'& td': {
			border: 'none',
		},
	},
	h4: {
		...theme.typography.h4,
	},
	h6: {
		...theme.typography.h6,
	},
	textField: {
		maxWidth: '200px',
	},
}));
