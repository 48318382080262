// @ts-nocheck
import { makeStyles, Theme } from '@material-ui/core';

export const coloredPaperStyles = makeStyles((theme: Theme) => ({
	exitPopupPaper: {
		...theme.papers.exitPopup,
	},
	timedPopupPaper: {
		...theme.papers.timedPopup,
	},
	floatingIconPaper: {
		...theme.papers.floatingIcon,
	},
	successfulPaper: {
		...theme.papers.successfulPaper,
	},
	missedPaper: {
		...theme.papers.missedPaper,
	},
	busyPaper: {
		...theme.papers.busyPaper,
	},
	noAnswerPaper: {
		...theme.papers.noAnswerPaper,
	},
}));
