export const REQUEST_NOTIFICATION = 'REQUEST_NOTIFICATION';
export const POST_NOTIFICATION = 'POST_NOTIFICATION';
export const FLUSH_NOTIFICATION = 'FLUSH_NOTIFICATION';

export const getNotification = () => {
	return {
		type: REQUEST_NOTIFICATION,
	};
};
export const postNotification = (message, type: 'error' | 'success', extraAction = '') => ({
	type: POST_NOTIFICATION,
	payload: {
		message,
		type,
		extraAction,
	},
});
export const flushNotification = () => {
	return {
		type: FLUSH_NOTIFICATION,
	};
};
