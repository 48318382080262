import {
	UPDATE_EMAIL_FULL_NAME,
} from './actions';

const userSettingsReducer = (state = { data: {} }, action) => {
	switch (action.type) {
		case UPDATE_EMAIL_FULL_NAME:
			return { ...state, loading: true };
		default:
			return state;
	}
};

export default userSettingsReducer;
