import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';

// Constants
import { getMessage } from '../../../constants/messages';

import { AuthLogin, AuthReducerType } from './authTypes';

import { postNotification } from '../../../../redux/notifications/actions';
import { actions as metaDataActions } from '../../../../redux/metaData/metaDataRedux';

import { getUser, logout } from './authCrud';
import { UserType } from '../../../../redux/userSettings/userTypes';

export const actionTypes = {
	Login: '[Login] Action',
	Logout: '[Logout] Action',
	Register: '[Register] Action',
	UserRequested: '[Request User] Action',
	UserLoaded: '[Load User] Auth API',
	RequestError: '[Request Error] Action',
	LogoutRequested: '[Request Logout] Action',
};

const initialAuthState: AuthReducerType = {
	user: undefined,
	authToken: undefined,
	error: undefined,
	refreshToken: undefined,
};

export const reducer = persistReducer(
	{ storage, key: 'covertmore--auth', whitelist: ['user', 'authToken', 'refreshToken'] },
	(state = initialAuthState, action: AuthLogin) => {
		switch (action.type) {
			case actionTypes.Login: {
				const { authToken, refreshToken } = action.payload;
				return { ...state as AuthReducerType, authToken, refreshToken };
			}

			case actionTypes.Register: {
				const { authToken } = action.payload;
				return { authToken, user: undefined };
			}

			case actionTypes.Logout: {
				// TODO: Change this code. Actions in reducer aren't allowed.
				return initialAuthState;
			}

			case actionTypes.UserLoaded: {
				const { user } = action.payload;
				return { ...state as AuthReducerType, user };
			}

			case actionTypes.RequestError: {
				const { error } = action.payload;
				return { ...state as AuthReducerType, error };
			}

			default:
				return state;
		}
	}
);

export const actions = {
	login: (authToken: string, refreshToken: string) => {
		return {
			type: actionTypes.Login,
			payload: {
				authToken,
				refreshToken,
			},
		};
	},
	register: (authToken: string) => ({
		type: actionTypes.Register,
		payload: { authToken },
	}),
	logout: () => ({ type: actionTypes.Logout }),
	requestLogout: () => ({ type: actionTypes.LogoutRequested }),
	requestUser: () => ({ type: actionTypes.UserRequested }),
	fulfillUser: (user: UserType) => ({ type: actionTypes.UserLoaded, payload: { user } }),
	requestError: error => ({ type: actionTypes.RequestError, payload: { error } }),
};

export function* saga() {
	yield takeLatest(actionTypes.Login, function* loginSaga() {
		yield put(actions.requestUser());
	});

	yield takeLatest(actionTypes.LogoutRequested, function* logoutRequested() {
		yield logout();
		// @ts-ignore
		yield put(metaDataActions.resetMetaData());
		yield put(actions.logout());
	});

	yield takeLatest(actionTypes.Register, function* registerSaga() {
		yield put(actions.requestUser());
	});

	yield takeLatest(actionTypes.UserRequested, function* userRequested() {
		try {
			const { data: user } = yield getUser();
			moment.tz.setDefault(user.timezone);
			dayjs.tz.setDefault(user.timezone);
			yield put(actions.fulfillUser({
				id: user.id,
				username: user.name,
				email: user.email,
				createdAt: user.createdAt,
				fullname: user.name,
				timezone: user.timezone,
				hasAdvancedWidgetSettings: user.hasAdvancedWidgetSettings,
				twilioNumberId: user.twilioNumberId,
				customCallerIdSettings: user.customCallerIdSettings,
			}));
		} catch {
			yield put(postNotification(getMessage('UNKNOWN_ERROR_OCCURRED'), 'error'));
		}
	});
}
