import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core';

import TextFieldInput from './TextFieldInput';

const useStyles = makeStyles(theme => ({
	text: {
		marginBottom: '8px',
		maxWidth: '100%',
		width: '200px',
		[theme.breakpoints.down('xs')]: {
			minWidth: '100%',
		},
		[theme.breakpoints.up('xs')]: {
			maxWidth: '200px',
		},
	},
}));

const AutocompleteInput = React.memo((props: any) => {
	const {
		textInputProps, setFieldValue, setFieldTouched, options,
	} = props;
	const classes = useStyles();

	return (
		<Autocomplete
			{...props}
			freeSolo
			className={classes.text}
			selectOnFocus
			disableClearable
			options={options}
			handleHomeEndKeys
			forcePopupIcon
			value={textInputProps.value}
			onChange={(e, value) => {
				// @ts-ignore
				setFieldValue(textInputProps.name, value);
			}}
			onBlur={() => setFieldTouched(textInputProps.name, true)}
			renderInput={params => (
				<TextFieldInput
					{...params}
					id={textInputProps.id}
					name={textInputProps.name}
					label={textInputProps.label}
					placeholder={textInputProps.placeholder}
					type={textInputProps.type}
					value={textInputProps.value}
					onChange={textInputProps.onChange}
					onBlur={textInputProps.onBlur}
					error={textInputProps.error}
					helperText={textInputProps.helperText}
				/>
			)}
		/>
	);
});

export default AutocompleteInput;
