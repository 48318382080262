import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Constants and types
import { RootStateType } from '../../../redux/rootReducer';
import { ALL_WIDGETS_DATA, WIDGET_DEFAULT } from '../../constants/widgetBuilder';

// Metronic
import { useSubheader } from '../../../_metronic/layout';

// Components
import { WidgetBuilder } from './WidgetBuilder/WidgetBuilder';

const WidgetManager = ({ match }) => {
	const { setBreadcrumbs } = useSubheader();

	// Redux
	const websites = useSelector((state: RootStateType) => state.website.websites);
	const agents = useSelector((state: RootStateType) => state.agents.data);
	const fakeAgents = useSelector((state: RootStateType) => state.agents.fakeAgents);
	const widgets = useSelector((state: RootStateType) => state.widget.widgets);

	let widget = { ...(widgets[0] || WIDGET_DEFAULT) };
	widget.widgetType = ALL_WIDGETS_DATA.name;

	delete widget.id;
	delete widget.websiteId;
	delete widget.typeId;
	delete widget.name;

	if (match.params.id) {
		widget = widgets.find(widget => widget.id === match.params.id) || WIDGET_DEFAULT;
	}

	useEffect(() => {
		if (widget.id) {
			setBreadcrumbs(prevState => [...prevState, {
				pathname: `/widgets/manage/general/${widget.id}`,
				title: widget.name,
			}]);
		}
	}, [widget.id]);

	return (
		<WidgetBuilder
			agents={agents}
			fakeAgents={fakeAgents}
			websites={websites}
			widget={widget || widgets[0]}
			widgets={widgets}
		/>
	);
};

export default WidgetManager;
