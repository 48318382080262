/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
	Box, Checkbox, FormControlLabel, Grid, Dialog, DialogContentText, DialogTitle, DialogContent,
} from '@material-ui/core';

// Constants
import inputErrors from '../../constants/inputErrors';
import { getMessage } from '../../constants/messages';

// Types
import {
	MetaDataAutoRechargeSettings,
	MetaDataCreditPack,
} from '../../../redux/metaData/metaDataTypes';

// Actions
import { actions } from '../../../redux/metaData/metaDataRedux';
import { postNotification } from '../../../redux/notifications/actions';

// Material
import SelectInput from '../../components/_inputs/SelectInput';
import { AddCreditCard } from '../../components/AddCreditCard/AddCreditCard';
import CreditCardElement from '../../components/CreditCardElement/CreditCardElement';
import SecondaryButton from '../../components/_actions/SecondaryButton';

import PrimaryButton from '../../components/_actions/PrimaryButton';
import {
	assignStripePaymentMethodIdToClient,
	createPaymentIntent, deletePaymentMethod,
	disableAutoRecharge, getCredits,
	saveAutoRecharge,
} from '../../../api';
import { CRITICAL_CREDITS } from '../../constants/general';

type AutoRechargeType = {
	creditPacks: MetaDataCreditPack[]
	paymentMethod: Record<string, 'any'>
	loading: boolean
	getPaymentMethod: () => void
	autoRecharge: MetaDataAutoRechargeSettings
	isAutoRecharge: boolean
	hasPurchases: boolean
	setShowForm: (boolean) => void
}

const ChargeManager: React.FC<AutoRechargeType> = (props) => {
	const {
		creditPacks,
		paymentMethod,
		loading,
		getPaymentMethod,
		autoRecharge,
		setShowForm,
		hasPurchases,
		isAutoRecharge,
	} = props;

	const [buttonSpinner, setButtonSpinner] = useState(false);
	const [isOneTimePurchase, setIsOneTimePurchase] = useState(false);
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState(paymentMethod);

	// Popup
	const [open, setOpen] = useState(false);

	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();

	// Functions
	const handleClickOpen = useCallback(() => {
		if (isOneTimePurchase) {
			setIsOneTimePurchase(false);
		} else {
			setOpen(true);
		}
	}, [isOneTimePurchase]);

	const handleClose = () => {
		setOpen(false);
	};

	async function handleNavigation() {
		setOpen(false);
		setIsOneTimePurchase(true);
		if (hasPurchases) {
			await disableAutoRecharge();
			dispatch(actions.updateAutoRecharge(null));
		}
	}

	const formatCreditPack = pack => (pack ? `${pack.credits} for $${pack.price}` : '');

	const autoRechargeFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			numberOfCredits: autoRecharge?.criticalCredits || CRITICAL_CREDITS[2],
			packageToRecharge: formatCreditPack(creditPacks.find(pack => pack.id === autoRecharge?.packId) || creditPacks[2]),
		},
		validationSchema: Yup.object().shape({
			numberOfCredits: Yup.number().required(inputErrors.requiredField),
			packageToRecharge: Yup.string().required(inputErrors.requiredField),
		}),
		onSubmit: () => { // eslint-disable-line
		}, // eslint-disable-line
	});

	const handleSubmit = async (event) => {
		event.preventDefault();

		// If missing stripe or stripe elements
		if (!stripe || !elements) {
			return;
		}

		// if validation fails
		const { numberOfCredits, packageToRecharge } = await autoRechargeFormik.validateForm();
		if (numberOfCredits || packageToRecharge) {
			return;
		}

		// Prepare new payment method variable in case we don't have one
		let paymentMethodToUse = currentPaymentMethod;
		let stripeError;

		setButtonSpinner(true);
		const paymentType: 'manual' | 'auto' = isOneTimePurchase ? 'manual' : 'auto';

		if (!paymentMethodToUse) {
			const cardElement = elements.getElement('card');
			const { error, paymentMethod: stripePaymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				metadata: {
					paymentType,
				},
			});

			stripeError = error;
			try {
				paymentMethodToUse = await assignStripePaymentMethodIdToClient(stripePaymentMethod.id);
			} catch (err) {
				dispatch(postNotification(getMessage('PAYMENT_METHOD_CREATE_ERROR'), 'error'));
			}
		}

		if (stripeError) {
			dispatch(postNotification(stripeError.message, 'error'));
			setButtonSpinner(false);
			return;
		}

		try {
			const purchasePackage = creditPacks.find(pack => formatCreditPack(pack) === autoRechargeFormik.values.packageToRecharge);

			const doAPurchase = !isAutoRecharge || isOneTimePurchase;

			if (doAPurchase) {
				const data = await createPaymentIntent(purchasePackage.id);
				await stripe.confirmCardPayment(data.client_secret, {
					payment_method: paymentMethodToUse.id,
				});
			}

			if (paymentMethod && paymentMethodToUse.id !== paymentMethod.id) {
				await deletePaymentMethod(paymentMethod.id);
			}

			if (!isOneTimePurchase) {
				const autoRechargeInfo: MetaDataAutoRechargeSettings = {
					packId: purchasePackage.id,
					criticalCredits: autoRechargeFormik.values.numberOfCredits,
					paymentMethodId: paymentMethodToUse.id,
				};

				await saveAutoRecharge(autoRechargeInfo);
			}

			const updatedCredits = await getCredits();
			if (doAPurchase) {
				updatedCredits.credits += purchasePackage.credits;
			}

			dispatch(actions.updateCredits(updatedCredits));

			if (!paymentMethod || !currentPaymentMethod) {
				await getPaymentMethod();
			}

			setButtonSpinner(false);
			if (!isOneTimePurchase) {
				if (!isAutoRecharge) {
					dispatch(postNotification(getMessage('PAYMENT_AUTO_RECHARGE_SUCCESS'), 'success', 'refresh-credits'));
				} else {
					dispatch(postNotification(getMessage('PAYMENT_AUTO_RECHARGE_UPDATE_SUCCESS'), 'success'));
				}
				setShowForm(false);
			} else {
				dispatch(postNotification(getMessage('PAYMENT_MANUAL_CHARGE_SUCCESS'), 'success', 'refresh-credits'));
			}
		} catch (error) {
			dispatch(postNotification(getMessage('PAYMENT_BUY_CREDITS_ERROR'), 'error'));
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<p>Select a credit pack to purchase</p>
			<SelectInput
				noAllOption
				fullWidth
				id="packageToRecharge"
				label="Credit Pack"
				{...autoRechargeFormik.getFieldProps('packageToRecharge')}
				options={creditPacks.map(item => `${item.credits} for $${item.price}`)}
				error={autoRechargeFormik.touched.packageToRecharge && Boolean(autoRechargeFormik.errors.packageToRecharge)}
				helperText={(autoRechargeFormik.touched.packageToRecharge && autoRechargeFormik.errors.packageToRecharge) || ' '}
			/>
			<Box marginBottom="20px">
				{!currentPaymentMethod && !loading && <AddCreditCard />}
				<CreditCardElement
					setCurrentPaymentMethod={setCurrentPaymentMethod}
					paymentMethod={currentPaymentMethod}
				/>
			</Box>
			{!isOneTimePurchase && (
				<>
					<p>Auto-recharge if the balance falls below</p>
					<SelectInput
						noAllOption
						fullWidth
						id="numberOfCredits"
						label="Number of Credits"
						{...autoRechargeFormik.getFieldProps('numberOfCredits')}
						options={CRITICAL_CREDITS}
						error={autoRechargeFormik.touched.numberOfCredits && Boolean(autoRechargeFormik.errors.numberOfCredits)}
						helperText={(autoRechargeFormik.touched.numberOfCredits && autoRechargeFormik.errors.numberOfCredits) || ' '}
					/>
				</>
			)}
			<FormControlLabel
				control={(
					<Checkbox
						onClick={handleClickOpen}
						color="primary"
						checked={isOneTimePurchase}
					/>
				)}
				label="Make a one time payment instead"
			/>
			{/* start dialog */}
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle style={{ textAlign: 'center' }} id="form-dialog-title">
					<b>
						{!isAutoRecharge && 'Are you sure you want to make one time charge instead of setting auto payment.'}
						{isAutoRecharge && 'Are you sure you want to cancel auto-recharge?'}
					</b>
				</DialogTitle>
				<DialogContent>
					<DialogContentText style={{ fontSize: '16px', textAlign: 'center' }}>
						If you run out of credits all widgets on your website will stop showing and you will not be able to make calls.
					</DialogContentText>
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
					>
						<br />
						<Grid item>
							<SecondaryButton variantColor="orange" onClick={handleNavigation}>
								Yes, I am sure.
							</SecondaryButton>
						</Grid>
						<br />
						<Grid item>
							<SecondaryButton variantColor="black" onClick={handleClose}>
								Cancel
							</SecondaryButton>
						</Grid>
						<br />
					</Grid>
				</DialogContent>
			</Dialog>
			<br />
			<br />
			<Grid container justify="center" spacing={2}>
				<Grid item>
					<PrimaryButton
						type="submit"
						extendedWidth
						disabled={buttonSpinner}
					>
						{isOneTimePurchase && 'Purchase Credits'}
						{!isOneTimePurchase && !isAutoRecharge && 'Save and Purchase'}
						{!isOneTimePurchase && isAutoRecharge && 'Update Payment Settings'}
						{buttonSpinner && <span className="ml-3 spinner spinner-white" />}
					</PrimaryButton>
				</Grid>
			</Grid>
		</form>
	);
};

export default ChargeManager;
