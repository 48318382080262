/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import objectPath from 'object-path';
// LayoutContext
import { useHtmlClassService } from '../_core/MetronicLayout';
// Constants
import { getMessage } from '../../../app/constants/messages';
// Import Layout components
import { Header } from './header/Header';
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { Aside } from './aside/Aside';
import { Footer } from './footer/Footer';
import { LayoutInit } from './LayoutInit';
import { SubHeader } from './subheader/SubHeader';
import { QuickPanel } from './extras/offcanvas/QuickPanel';
import { QuickUser } from './extras/offcanvas/QuickUser';
import { ScrollTop } from './extras/ScrollTop';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';

import { getCredits } from '../../../api';

import { actions } from '../../../redux/metaData/metaDataRedux';


export function Layout({ children }) {
	const creditsArray = useSelector(state => state?.metaData?.credits);
	const creditsObject = creditsArray ? creditsArray[0] : undefined;
	const widgets = useSelector(state => state.widget);

	const autoRecharge = creditsObject?.autoRecharge;
	const credits = creditsObject?.credits;
	const overdraft = creditsObject?.overdraft;

	const [showWarning, setShowWarning] = useState(false);
	const [alertObject, setAlertObject] = useState({});

	const dispatch = useDispatch();

	useEffect(() => {
		const interval = setInterval( async() => {
			const updatedCredits = await getCredits();
			dispatch(actions.updateCredits(updatedCredits));
		}, 10 * 1000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (autoRecharge !== undefined && credits !== undefined && overdraft !== undefined && widgets.widgets.length > 0) {
			if (credits === 0 && overdraft === 0) {
				setShowWarning(true);
				setAlertObject({ message: 'Your widgets are disabled since you don’t have any credits.', severity: 'warning', cta: 'Buy credits.' });
			} else if (autoRecharge === null) { // manual
				if (credits <= 0) {
					setShowWarning(true);
					if (overdraft === credits) {
						setAlertObject({ message: getMessage('USER_OVERDRAFT_REACHED_MESSAGE'), severity: 'error', cta: getMessage('USER_OVERDRAFT_REACHED_LINK') });
					} else {
						setAlertObject({ message: getMessage('USER_CREDITS_FALL_BELLOW_1'), severity: 'warning' });
					}
				} else {
					setShowWarning(false);
				}
			} else { // automatic
				setShowWarning(true);
				if (credits <= 0) {
					if (overdraft === credits) {
						setAlertObject({ message: getMessage('USER_OVERDRAFT_REACHED_MESSAGE'), severity: 'error', cta: 'Pay your bill.' });
					} else {
						setAlertObject({ message: getMessage('USER_CREDITS_FALL_BELLOW_1'), severity: 'warning' });
					}
				} else if (credits < autoRecharge.criticalCredits) {
					setAlertObject({ message: getMessage('USER_CREDITS_FALL_BELLOW_SET_BALANCE', { credits }), severity: 'info' });
				} else {
					setShowWarning(false);
				}
			}
		}
	}, [credits, autoRecharge, overdraft, widgets.widgets.length]);

	const uiService = useHtmlClassService();
	// Layout settings (cssClasses/cssAttributes)
	const layoutProps = useMemo(() => {
		return {
			layoutConfig: uiService.config,
			selfLayout: objectPath.get(uiService.config, 'self.layout'),
			asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
			subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
			desktopHeaderDisplay: objectPath.get(
				uiService.config,
				'header.self.fixed.desktop'
			),
			contentCssClasses: uiService.getClasses('content', true),
			contentContainerClasses: uiService.getClasses('content_container', true),
			contentExtended: objectPath.get(uiService.config, 'content.extended'),
		};
	}, [uiService]);

	return layoutProps.selfLayout !== 'blank' ? (
		<>
			{/* begin::Main */}
			<HeaderMobile />
			<div className="d-flex flex-column flex-root">
				{/* begin::Page */}
				<div className="d-flex flex-row flex-column-fluid page">
					{layoutProps.asideDisplay && (<Aside />)}
					{/* begin::Wrapper */}
					<div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
						<Header />
						{/* begin::Content */}
						<div
							id="kt_content"
							className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
							style={{ backgroundColor: 'white' }}
						>

							{/* Credit alert */}
							{/* {showWarning && <div style={{backgroundColor: "red", textAlign: "center", color: "white", width: "100%", height: "60px"}}>
                            <br />
                            <span>{alertMessage}</span>
                        </div>} */}
							{showWarning && (
								<Alert style={{ borderRadius: 0, alignItems: 'center' }} severity={alertObject?.severity}>
									<span>
										{alertObject?.message}
										{' '}
										{' '}
									</span>
									{ alertObject?.cta && <Link to="/billing">{alertObject?.cta}</Link>}
								</Alert>
							)}

							{layoutProps.subheaderDisplay && <SubHeader />}
							{/* begin::Entry */}
							{!layoutProps.contentExtended && (
								<div className="d-flex flex-column-fluid">
									{/* begin::Container */}
									<div className={layoutProps.contentContainerClasses}>
										{children}
									</div>
									{/* end::Container */}
								</div>
							)}

							{layoutProps.contentExtended && { children }}
							{/* end::Entry */}
						</div>
						{/* end::Content */}
						<Footer />
					</div>
					{/* end::Wrapper */}
				</div>
				{/* end::Page */}
			</div>
			<QuickUser />
			<QuickPanel />
			<ScrollTop />
			{/* end::Main */}
			<LayoutInit />
		</>
	) : (
	// BLANK LAYOUT
		<div className="d-flex flex-column flex-root">{children}</div>
	);
}
