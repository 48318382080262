import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

// Constants
import { LAYOUT } from '../../../../constants/widgetBuilder';
import { getMessage } from '../../../../constants/messages';

// Components
import {
	Box, CardContent, Grid, InputLabel, Slider, Typography,
	LinearProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SelectInput from '../../../../components/_inputs/SelectInput';
import TextFieldInput from '../../../../components/_inputs/TextFieldInput';
import Axios from 'axios';

// Lib
import InputWithSwitch from '../../../../components/_inputs/InputWithSwitch';
import FileUpload from '../../../../components/_inputs/FileUpload';
import { postNotification } from '../../../../../redux/notifications/actions';
import SecondaryButton from '../../../../components/_actions/SecondaryButton';
import ColorPicker from '../../../../components/ColorPicker/ColorPicker';
import WidgetPreview from './GeneralComponents/WidgetPreview';
import { fetchWidgetScript, getUserFiles } from '../../../../../api';
import { stripeHtmlTagsExceptLinks } from '../../../../lib/htmlHelpers';

function DesignStep(props) {
	const {
		globalSetupFormikData, callSetupFormikData, scheduleSetupFormikData, widgetType, handleSubmit, handleImageUpdate,
	} = props;

	const { id } = useParams<Record<'id', string>>();

	const dispatch = useDispatch();

	const [widgetScript, setWidgetScript] = useState(null);
	const [userImages, setUserImages] = useState(null);
	const [image, setImage] = useState(null);
	const [progress, setProgress] = useState(0);
	const [imageErrors, setImageErrors] = useState('');

	const shouldRenderFloatingIcon = widgetType === 'Floating icon' || widgetType === 'All Widgets';

	// Get script
	useEffect(() => {
		if (!widgetScript) {
			fetchWidgetScript().then(data => setWidgetScript(data));
		}
	}, [widgetScript]);

	useEffect(() => {
		if (!userImages) {
			getUserFiles().then(data => setUserImages(data));
		}
	}, [userImages]);

	useEffect(() => {
		if (userImages && userImages.length > 0) {
			const widgetImage = userImages.find((image) => {
				const url = new URL(image.url);
				return `${url.origin}${url.pathname}` === callSetupFormikData.getFieldProps('picture.src').value;
			});
			setImage(widgetImage);
		}
	}, [userImages]);

	function handleSwitchOnClick(value, field, isCall = false) {
		if (!value) {
			if (isCall) {
				callSetupFormikData.setFieldValue(field, '', false);
			} else {
				scheduleSetupFormikData.setFieldValue(field, '', false);
			}
		}
	}

	async function fileUpload(file) {
		setImageErrors('');
		if (file) {
			if (file.size > 5000001) {
				// if file size i bigger than 5MB.
				setImageErrors('The image size limit is 5MB.');
				return;
			}
			try {
				setProgress(1);

				const formData = new FormData();
				formData.append(file.name, file);

				const options = {
					onUploadProgress: (progressEvent) => {
						const { loaded, total } = progressEvent;
						const percent = Math.floor((loaded * 100) / total);
						if (percent < 100) {
							setProgress(percent);
						}
					},
				};

				// @ts-ignore
				const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/user/files`, formData, options, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
				const imageToUpload = data.find(item => item.filename === file.name);
				setProgress(100);
				const url = new URL(imageToUpload?.url);
				callSetupFormikData.setFieldValue('picture.src', `${url.origin}${url.pathname}`);

				setImage(imageToUpload);
				setUserImages([...userImages, imageToUpload]);
			} catch (error) {
				dispatch(postNotification(getMessage('WIDGET_UPLOAD_IMAGE_ERROR'), 'error'));
			}
		} else {
			callSetupFormikData.setFieldValue('picture.src', '');
		}
		setProgress(0);
	}

	return (
		<CardContent>
			<br />
			<br />
			{widgetType !== 'Floating icon' && (
				<Grid
					container
					spacing={2}
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid item xs={12} sm="auto">
						<SelectInput
							id="layout"
							noAllOption
							label="Layout"
							options={LAYOUT}
							{...globalSetupFormikData.getFieldProps('layout')}
							error={globalSetupFormikData.touched.layout && Boolean(globalSetupFormikData.errors.layout)}
							helperText={(globalSetupFormikData.touched.layout && globalSetupFormikData.errors.layout) || ' '}
						/>
					</Grid>
				</Grid>
			)}
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h4">
						<Box fontWeight="fontWeightBold" marginBottom="13px" textAlign="center">
							Customize your &quot;Call now&quot; widget
						</Box>
					</Typography>
					<Box maxWidth="500px" margin="auto" textAlign="center">
						<p>
							Use the following options to design your widget. Remember to tell your customer they are
							going to get a call within 10 seconds.
						</p>
					</Box>
					<br />
				</Grid>
				<Grid item xs={12} sm="auto">
					<Grid item xs={12} sm="auto">
						<Box ml={8} mb={3}>
							{imageErrors !== '' &&
							<Alert severity="error">{imageErrors}</Alert>}
						</Box>
					</Grid>
					<InputWithSwitch
						switchProps={{
							checked: callSetupFormikData.values.picture.isActive,
							...callSetupFormikData.getFieldProps('picture.isActive'),
							onClick: (event) => {
								callSetupFormikData.setFieldValue('picture.isActive', event.target.checked);
							},
							color: 'primary',
							inputProps: { 'aria-label': 'switch' },
						}}
						element={(
							<FileUpload
								label="Upload image"
								disable={!callSetupFormikData.values.picture.isActive}
								{...callSetupFormikData.getFieldProps('picture.src')}
								value={image?.filename || ''}
								onChange={file => fileUpload(file)}
								error={callSetupFormikData.touched?.picture?.src && !!callSetupFormikData.errors.picture?.src}
								helperText={(callSetupFormikData.touched?.picture?.src && callSetupFormikData.errors.picture?.src) || ' '}
							/>
						)}
					/>
					{progress > 0 && (
						<Box mb={3} ml={8}>
							<span>
								{progress}
								%
							</span>
							<LinearProgress variant="determinate" value={progress} />
						</Box>
					)}
					<InputWithSwitch
						switchProps={{
							checked: callSetupFormikData.values.main.isActive,
							...callSetupFormikData.getFieldProps('main.isActive'),
							onClick: event => handleSwitchOnClick(event.target.checked, 'main.text', true),
							color: 'primary',
							inputProps: { 'aria-label': 'switch widget' },
						}}
						textFieldProps={{
							id: 'mainText',
							label: 'Main text',
							placeholder: 'Main text',
							type: 'text',
							large: true,
							disabled: !callSetupFormikData.values.main.isActive,
							...callSetupFormikData.getFieldProps('main.text'),
							error: callSetupFormikData.touched?.main?.text && !!callSetupFormikData.errors?.main?.text,
							helperText: (callSetupFormikData.touched?.main?.text && callSetupFormikData.errors?.main?.text) || ' ',
						}}
					/>
					<InputWithSwitch
						switchProps={{
							...callSetupFormikData.getFieldProps('disclaimer.isActive'),
							checked: callSetupFormikData.values.disclaimer.isActive,
							onClick: event => handleSwitchOnClick(event.target.checked, 'disclaimer.text', true),
							color: 'primary',
							inputProps: { 'aria-label': 'switch widget' },
						}}
						textFieldProps={{
							...callSetupFormikData.getFieldProps('disclaimer.text'),
							id: 'disclaimer',
							label: 'Disclaimer',
							placeholder: 'Disclaimer',
							type: 'text',
							large: true,
							onChange: (event) => {
								event.target.value = stripeHtmlTagsExceptLinks(event.target.value);
								callSetupFormikData.getFieldProps('disclaimer.text').onChange(event);
							},
							disabled: !callSetupFormikData.values.disclaimer.isActive,
							error: callSetupFormikData.touched?.disclaimer?.text && !!callSetupFormikData.errors?.disclaimer?.text,
							helperText: (callSetupFormikData.touched?.disclaimer?.text && callSetupFormikData.errors?.disclaimer?.text) || ' ',
						}}
					/>
					<Grid container>
						<Grid item xs={12}>
							<Box marginLeft="60px">
								<TextFieldInput
									id="buttonText"
									label="Button text"
									placeholder="Button text"
									type="text"
									large
									{...callSetupFormikData.getFieldProps('buttonText')}
									error={callSetupFormikData.touched?.buttonText && !!callSetupFormikData.errors?.buttonText}
									helperText={(callSetupFormikData.touched?.buttonText && callSetupFormikData.errors?.buttonText) || ' '}
								/>
							</Box>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Box marginLeft="60px">
								<TextFieldInput
									{...callSetupFormikData.getFieldProps('callBackText')}
									id="callBackText"
									label="Callback text"
									placeholder="Callback text"
									type="text"
									large
									error={callSetupFormikData.touched?.callBackText && !!callSetupFormikData.errors?.callBackText}
									helperText={(callSetupFormikData.touched?.callBackText && callSetupFormikData.errors?.callBackText) || ' '}
								/>
							</Box>

						</Grid>
					</Grid>
					<Typography variant="h5">
						<Box fontWeight="fontWeightBold" marginBottom="13px" marginTop="30px">
							Image options
						</Box>
					</Typography>
					<Grid container alignItems="center">
						<Box width="80px">
							<InputLabel>Size:</InputLabel>
						</Box>
						<Box width="280px" maxWidth="100%">
							<Slider
								defaultValue={callSetupFormikData.initialValues.picture.size}
								onChange={(event, value) => callSetupFormikData.setFieldValue('picture.size', value, false)}
								min={0}
								max={100}
								step={1}
							/>
						</Box>
					</Grid>
					<br />
					<Typography variant="h5">
						<Box fontWeight="fontWeightBold" marginBottom="13px">
							Text options
						</Box>
					</Typography>
					<Grid container alignItems="center">
						<Box width="80px">
							<InputLabel>Size:</InputLabel>
						</Box>
						<Box width="280px" maxWidth="100%">
							<Slider
								defaultValue={globalSetupFormikData.initialValues?.textOptions.size}
								onChange={(event, value) => globalSetupFormikData.setFieldValue('textOptions.size', value, false)}
								min={14}
								max={28}
								step={1}
							/>
						</Box>
					</Grid>
					<Grid container alignItems="center">
						<Box width="80px">
							<InputLabel htmlFor="text-color">Color:</InputLabel>
						</Box>
						<ColorPicker
							selectedColor={globalSetupFormikData.getFieldProps('textOptions.color').value}
							onSave={color => globalSetupFormikData.setFieldValue('textOptions.color', color, false)}
						/>
					</Grid>
					<br />
					<Typography variant="h5">
						<Box fontWeight="fontWeightBold" marginBottom="13px">
							Button options
						</Box>
					</Typography>
					<Grid container alignItems="center">
						<Box width="80px">
							<InputLabel>Size:</InputLabel>
						</Box>
						<Box width="280px" maxWidth="100%">
							<Slider
								defaultValue={globalSetupFormikData.initialValues.buttonOptions.size}
								onChange={(event, value) => globalSetupFormikData.setFieldValue('buttonOptions.size', value, false)}
								min={0}
								max={100}
								step={1}
							/>
						</Box>
					</Grid>
					<Grid container alignItems="center">
						<Box width="80px">
							<InputLabel>Font size:</InputLabel>
						</Box>
						<Box width="280px" maxWidth="100%">
							<Slider
								defaultValue={globalSetupFormikData.initialValues.buttonOptions.fontSize || globalSetupFormikData.initialValues.textOptions?.size}
								onChange={(event, value) => globalSetupFormikData.setFieldValue('buttonOptions.fontSize', value, false)}
								min={12}
								max={45}
								step={1}
							/>
						</Box>
					</Grid>
					<Grid container item alignItems="center">
						<Box width="80px">
							<InputLabel htmlFor="button-color">Background Color:</InputLabel>
						</Box>
						<ColorPicker
							selectedColor={globalSetupFormikData.getFieldProps('buttonOptions.color').value}
							onSave={(color) => {
								globalSetupFormikData.setFieldValue('buttonOptions.color', color, false);
							}}
						/>
					</Grid>
					<Grid container item alignItems="center">
						<Box width="80px">
							<InputLabel htmlFor="button-color">Font Color:</InputLabel>
						</Box>
						<ColorPicker
							selectedColor={globalSetupFormikData.getFieldProps('buttonOptions.fontColor').value}
							onSave={(color) => {
								globalSetupFormikData.setFieldValue('buttonOptions.fontColor', color, false);
							}}
						/>
					</Grid>
					<br />
					<Typography variant="h5">
						<Box fontWeight="fontWeightBold" marginBottom="13px">
							Background options
						</Box>
					</Typography>
					<Grid container item alignItems="center">
						<Box width="80px">
							<InputLabel htmlFor="button-color">Color:</InputLabel>
						</Box>
						<ColorPicker
							selectedColor={globalSetupFormikData.getFieldProps('background').value}
							onSave={color => globalSetupFormikData.setFieldValue('background', color, false)}
						/>
					</Grid>
					<br />
					<Typography variant="h5">
						<Box fontWeight="fontWeightBold" marginBottom="13px">
							Screen background options
						</Box>
					</Typography>
					<Grid container alignItems="center">
						<Box width="80px">
							<InputLabel htmlFor="button-color">Color:</InputLabel>
						</Box>
						<ToggleButtonGroup
							exclusive
							{...globalSetupFormikData.getFieldProps('screenBackground.color')}
							onChange={(event, value) => globalSetupFormikData.setFieldValue('screenBackground.color', value, false)}
						>
							<ToggleButton value="Light">Light</ToggleButton>
							<ToggleButton value="Dark">Dark</ToggleButton>
						</ToggleButtonGroup>
					</Grid>
					<Grid container alignItems="center">
						<Box width="80px">
							<InputLabel>Opacity:</InputLabel>
						</Box>
						<Box width="280px" maxWidth="100%">
							<Slider
								defaultValue={globalSetupFormikData.initialValues.screenBackground?.opacity}
								onChange={(event, value) => globalSetupFormikData.setFieldValue('screenBackground.opacity', value, false)}
								min={0}
								max={100}
								step={10}
							/>
						</Box>
					</Grid>
				</Grid>
				{widgetScript && (
					<WidgetPreview
						value={callSetupFormikData.values}
						valueType="callSetup"
						title="Call preview"
						iframeId="convertmore-script-call"
						globalSetup={globalSetupFormikData.values}
						widgetScript={widgetScript}
						isCall
					/>
				)}
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<br />
					<Typography variant="h4">
						<Box fontWeight="fontWeightBold" marginBottom="13px" marginTop="30px" textAlign="center">
							Customize your &quot;Request Callback&quot; widget
						</Box>
					</Typography>
					<Box maxWidth="360px" margin="auto" textAlign="center">
						<p>
							Setup following options to create the design for your widget that suits your brand the
							best.
						</p>
					</Box>
					<br />
				</Grid>
				<Grid item xs={12} sm="auto">
					<InputWithSwitch
						switchProps={{
							checked: scheduleSetupFormikData.values?.main?.isActive,
							...scheduleSetupFormikData.getFieldProps('main.isActive'),
							onClick: event => handleSwitchOnClick(event.target.checked, 'main.text'),
							color: 'primary',
							inputProps: { 'aria-label': 'switch widget' },
						}}
						textFieldProps={{
							id: 'scheduleSetup.main',
							label: 'Main text',
							placeholder: 'Main text',
							type: 'text',
							large: true,
							disabled: !scheduleSetupFormikData.values.main.isActive,
							...scheduleSetupFormikData.getFieldProps('main.text'),
							error: scheduleSetupFormikData.touched?.main?.text && !!scheduleSetupFormikData.errors?.main?.text,
							helperText: (scheduleSetupFormikData.touched?.main?.text && scheduleSetupFormikData.errors?.main?.text) || ' ',
						}}
					/>
					<InputWithSwitch
						switchProps={{
							checked: scheduleSetupFormikData.values?.customField1?.isActive,
							...scheduleSetupFormikData.getFieldProps('customField1.isActive'),
							onClick: event => handleSwitchOnClick(event.target.checked, 'customField1.text'),
							color: 'primary',
							inputProps: { 'aria-label': 'switch widget' },
						}}
						textFieldProps={{
							id: 'scheduleSetup.customField1',
							label: 'Custom field 1',
							placeholder: 'Custom field 1',
							type: 'text',
							large: true,
							disabled: !scheduleSetupFormikData.values.customField1.isActive,
							...scheduleSetupFormikData.getFieldProps('customField1.text'),
							error: scheduleSetupFormikData.touched?.customField1?.text && !!scheduleSetupFormikData.errors?.customField1?.text,
							helperText: (scheduleSetupFormikData.touched?.customField1?.text && scheduleSetupFormikData.errors?.customField1?.text) || ' ',
						}}
					/>
					<InputWithSwitch
						switchProps={{
							checked: scheduleSetupFormikData.values?.customField2?.isActive,
							...scheduleSetupFormikData.getFieldProps('customField2.isActive'),
							onClick: event => handleSwitchOnClick(event.target.checked, 'customField2.text'),
							color: 'primary',
							inputProps: { 'aria-label': 'switch widget' },
						}}
						textFieldProps={{
							id: 'scheduleSetup.customField2',
							label: 'Custom field 2',
							placeholder: 'Custom field 2',
							type: 'text',
							large: true,
							disabled: !scheduleSetupFormikData.values.customField2.isActive,
							...scheduleSetupFormikData.getFieldProps('customField2.text'),
							error: scheduleSetupFormikData.touched?.customField2?.text && !!scheduleSetupFormikData.errors?.customField2?.text,
							helperText: (scheduleSetupFormikData.touched?.customField2?.text && scheduleSetupFormikData.errors?.customField2?.text) || ' ',
						}}
					/>
					<InputWithSwitch
						switchProps={{
							checked: scheduleSetupFormikData.values?.customField3?.isActive,
							...scheduleSetupFormikData.getFieldProps('customField3.isActive'),
							onClick: event => handleSwitchOnClick(event.target.checked, 'customField3.text'),
							color: 'primary',
							inputProps: { 'aria-label': 'switch widget' },
						}}
						textFieldProps={{
							id: 'scheduleSetup.customField3',
							label: 'Custom field 3',
							placeholder: 'Custom field 3',
							type: 'text',
							large: true,
							disabled: !scheduleSetupFormikData.values.customField3.isActive,
							...scheduleSetupFormikData.getFieldProps('customField3.text'),
							error: scheduleSetupFormikData.touched?.customField3?.text && !!scheduleSetupFormikData.errors?.customField3?.text,
							helperText: (scheduleSetupFormikData.touched?.customField3?.text && scheduleSetupFormikData.errors?.customField3?.text) || ' ',
						}}
					/>
					<InputWithSwitch
						switchProps={{
							checked: scheduleSetupFormikData.values.disclaimer.isActive,
							...scheduleSetupFormikData.getFieldProps('disclaimer.isActive'),
							onClick: event => handleSwitchOnClick(event.target.checked, 'disclaimer.text'),
							color: 'primary',
							inputProps: { 'aria-label': 'switch widget' },
						}}
						textFieldProps={{
							id: 'scheduleSetup.disclaimer',
							label: 'Disclaimer',
							placeholder: 'Disclaimer',
							type: 'text',
							large: true,
							onChange: (event) => {
								event.target.value = stripeHtmlTagsExceptLinks(event.target.value);
								scheduleSetupFormikData.getFieldProps('disclaimer.text').onChange(event);
							},
							disabled: !scheduleSetupFormikData.values.disclaimer.isActive,
							...scheduleSetupFormikData.getFieldProps('disclaimer.text'),
							error: scheduleSetupFormikData.touched?.disclaimer?.text && !!scheduleSetupFormikData.errors?.disclaimer?.text,
							helperText: (scheduleSetupFormikData.touched?.disclaimer?.text && scheduleSetupFormikData.errors?.disclaimer?.text) || ' ',
						}}
					/>
					<Grid container>
						<Grid item xs={12}>
							<Box marginLeft="60px">
								<TextFieldInput
									id="scheduleSetup.timeToCallText"
									label="Time to call text"
									placeholder="Time to call text"
									type="text"
									large
									{...scheduleSetupFormikData.getFieldProps('timeToCallText')}
									error={scheduleSetupFormikData.touched?.timeToCallText && !!scheduleSetupFormikData.errors?.timeToCallText}
									helperText={(scheduleSetupFormikData.touched?.timeToCallText && scheduleSetupFormikData.errors?.timeToCallText) || ' '}
								/>
							</Box>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Box marginLeft="60px">
								<TextFieldInput
									id="scheduleSetup.buttonText"
									label="Button text"
									placeholder="Button text"
									type="text"
									large
									{...scheduleSetupFormikData.getFieldProps('buttonText')}
									error={scheduleSetupFormikData.touched?.buttonText && !!scheduleSetupFormikData.errors?.buttonText}
									helperText={(scheduleSetupFormikData.touched?.buttonText && scheduleSetupFormikData.errors?.buttonText) || ' '}
								/>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				{widgetScript && (
					<WidgetPreview
						value={scheduleSetupFormikData.values}
						valueType="scheduleSetup"
						title="Schedule preview"
						iframeId="convertmore-script-schedule"
						globalSetup={globalSetupFormikData.values}
						widgetScript={widgetScript}
					/>
				)}
			</Grid>
			{/* Floating Icon */}
			{shouldRenderFloatingIcon && (
				<>
					<Grid container>
						<Grid item xs={12}>
							<br />
							<Typography variant="h4">
								<Box fontWeight="fontWeightBold" marginBottom="13px" marginTop="30px" textAlign="center">
									Customize your Floating icon
								</Box>
							</Typography>
							<Box maxWidth="360px" margin="auto" textAlign="center">
								<p>
									Setup size, color and position of your floating icon
								</p>
							</Box>
							<br />
						</Grid>
						<Grid item xs={12} sm="auto">
							<Typography variant="h5">
								<Box fontWeight="fontWeightBold" marginBottom="13px">
									Icon options
								</Box>
							</Typography>
							<Grid container item alignItems="center">
								<Box width="80px">
									<InputLabel htmlFor="button-color">Color:</InputLabel>
								</Box>
								<ColorPicker
									selectedColor={globalSetupFormikData.getFieldProps('floatingIcon.bgColor').value || globalSetupFormikData.getFieldProps('buttonOptions.color').value}
									onSave={(color) => { globalSetupFormikData.setFieldValue('floatingIcon.bgColor', color, false); }}
								/>
							</Grid>
							<Grid container item alignItems="center">
								<Box width="80px">
									<InputLabel htmlFor="button-icon-color">Icon color:</InputLabel>
								</Box>
								<ColorPicker
									selectedColor={globalSetupFormikData.getFieldProps('floatingIcon.phoneIconColor').value || globalSetupFormikData.getFieldProps('buttonOptions.fontColor').value}
									onSave={(color) => { globalSetupFormikData.setFieldValue('floatingIcon.phoneIconColor', color, false); }}
								/>
							</Grid>
							<br />
							<Grid container alignItems="center">
								<Box width="80px">
									<InputLabel>Size:</InputLabel>
								</Box>
								<Box width="280px" maxWidth="100%">
									<Slider
										defaultValue={globalSetupFormikData.initialValues.floatingIcon?.size || 100}
										onChange={(event, value) => globalSetupFormikData.setFieldValue('floatingIcon.size', value, false)}
										min={50}
										max={200}
										step={5}
									/>
								</Box>
							</Grid>
							<br />
							<Grid container alignItems="center">
								<Box width="80px">
									<InputLabel>Position:</InputLabel>
								</Box>
								<ToggleButtonGroup
									exclusive
									{...globalSetupFormikData.getFieldProps('floatingIcon.position')}
									onChange={(event, value) => globalSetupFormikData.setFieldValue('floatingIcon.position', value, false)}
								>
									<ToggleButton value="left">Left</ToggleButton>
									<ToggleButton value="right">Right</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>
						{widgetScript && (
							<WidgetPreview
								value={scheduleSetupFormikData.values}
								valueType="scheduleSetup"
								title="Floating icon preview"
								isFloatingIcon
								iframeId="convertmore-script-floating-icon"
								globalSetup={globalSetupFormikData.values}
								widgetScript={widgetScript}
							/>
						)}
					</Grid>
					<br />
					<br />
					<br />
					<br />
				</>
			)}
			<Grid container justify="center" spacing={2}>
				<Grid item>
					<NavLink
						exact
						to={{
							pathname: `/widgets/manage/general/${id || ''}`,
						}}
					>
						<SecondaryButton
							variantColor="black"
						>
							Back
						</SecondaryButton>
					</NavLink>
				</Grid>
				<Grid item>
					<SecondaryButton
						onClick={handleSubmit}
						variantColor="orange"
					>
						{id ? 'Update Widget' : 'Create Widget'}
					</SecondaryButton>
				</Grid>
			</Grid>
		</CardContent>
	);
}

export default DesignStep;
